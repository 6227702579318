import { IndemityValuesProps } from '@project/components';
import indemnityFormUrls from './indemnity-form.urls';
import { getData } from '@project/services/utils';
import { networkService } from '..';

const sendIndemnityForm = (formData: IndemityValuesProps, id: number) => {
  const url = indemnityFormUrls.answerUrl(id);

  return networkService.post(url, formData).then(getData);
};

const getQuestions = (id: number) => {
  const url = indemnityFormUrls.questionUrl(id);

  return networkService.get(url).then(getData);
};

export default {
  sendIndemnityForm,
  getQuestions,
};
