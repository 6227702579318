import { useMemo } from 'react';
import { BaseOption } from '@project/types';
import { PaymentAmountProps } from './types';
import { Dropdown, TextField } from '@project/components';
import { allowedCurrencies } from '@project/components';

export const PaymentAmountField: React.FC<PaymentAmountProps> = ({
  amountFieldName,
  currencyFieldName,
  selectedCurrency,
}) => {
  const allCurrencies = useMemo(() => {
    return allowedCurrencies
      .filter((curr) => ['USD', 'GBP', 'EUR', 'ZAR', 'AUD', 'MWK'].includes(curr.code))
      .map((curr) => ({
        label: `${curr.code} (${curr.currency})`,
        value: curr.code,
      })) as BaseOption[];
  }, [allowedCurrencies]);

  const handleSelectedCurrency: BaseOption | undefined = useMemo(
    () => allCurrencies.find((option) => option.value === selectedCurrency),
    [allCurrencies, selectedCurrency],
  );

  if (!handleSelectedCurrency) {
    return null;
  }

  return (
    <div className="grid grid-cols-6 md:space-x-2">
      <div className="col-span-6 mb-6 md:col-span-2">
        <Dropdown
          fieldName={currencyFieldName}
          options={allCurrencies}
          value={handleSelectedCurrency}
          label="Select Currency"
        />
      </div>
      <div className="col-span-6 md:col-span-4">
        <TextField name={amountFieldName} type={'number'} label="Amount" />
      </div>
    </div>
  );
};
