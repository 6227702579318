import React from 'react';
import isNil from 'lodash/isNil';
import { MoonLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';

import { DonationDetailsReciept } from './donation-details-reciept';
import { DonationDetailsSummary } from './donation-details-summary';
import { TopImage, BankInfoDisplay } from '@project/components';
import { useGetManualPaymentQuery } from '@project/queries';
import { getIsLoggedIn } from '@project/helper';

export const DonationDetailsPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetManualPaymentQuery(id ?? '');
  const isLoggedIn = getIsLoggedIn();

  const summaryValues = [
    {
      heading: 'Donation Ref.',
      value: data?.referenceNumber ?? '',
    },
    {
      heading: 'Date',
      value: data?.createdAt ?? '',
    },
    {
      heading: 'Total',
      value: data?.total ?? '',
    },
    {
      heading: 'Payment Method',
      value: 'Direct Bank Transfer/ Manual Payment',
    },
  ];

  const goToPartnersPortal = () => navigate('/partner/home');

  return (
    <div>
      <TopImage />
      <div className="w-full min-w-fit px-3 py-10 lg:pl-36 lg:pr-[564px] xl:pr-[564px]">
        {isLoading ? (
          <div className="flex justify-center">
            <MoonLoader color="#B58B00" />
          </div>
        ) : isNil(data) ? (
          <p>Could Not Fetch Donation Details</p>
        ) : (
          <div>
            <div className="mb-8 flex w-full flex-row justify-between">
              <div>
                <h1 className="text-3xl font-semibold">Donation Details</h1>
                <div className="w-1/3 rounded border-2 border-gold" />
              </div>
              {isLoggedIn && (
                <button className="rounded-lg bg-gold p-2 text-white" onClick={goToPartnersPortal}>
                  Back to Partners Portal
                </button>
              )}
            </div>
            <p className="mb-4 text-gold">Your Donation Details Have Been Sent</p>
            <DonationDetailsSummary items={summaryValues} />
            <h2 className="mt-8 mb-2 text-lg font-semibold">Donation Details</h2>
            <DonationDetailsReciept total={data.total} donationType={data.donationType} />
            <BankInfoDisplay />
          </div>
        )}
      </div>
    </div>
  );
};
