import React from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import { FormikProps } from 'formik';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FlatList, Form, FormError } from '@codehesion-za/headless';
import { Button, Dropdown, TextField } from '@project/components/atoms';
import { IndemityValuesProps, QuestionType } from './types';
import { RadioGroup } from '@project/components/molecules';
import { indemnityFormService } from '@project/services';
import { getIsLoggedIn } from '@project/helper';
import { BaseOption } from '@project/types';
import { indemitySchema } from './schema';
import './indemity-form.css';
import * as Sentry from '@sentry/react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { getCode, getNames } from 'country-list';

const FORM_SPACING = 'mt-6';
const FORM_TEXT_COLOUR = 'text-[#262626]';
const CHECK_BOX_STYLE = 'my-auto rounded text-gold outline-none';

const TITLE = [
  { label: 'Mr', value: 'Mr.' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Mrs', value: 'Mrs.' },
];

const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

const countryOptions: BaseOption[] = getNames()
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);

const QuestionModel = (
  text: string,
  questionIdentifier: string,
  category: string,
): QuestionType => ({
  text: text,
  questionIdentifier: questionIdentifier,
  category: category,
});

const IndemnityQuestions = ({
  text,
  questionIdentifier,
}: {
  text: string;
  questionIdentifier: string;
}) => (
  <div className={FORM_SPACING}>
    <p className={`${FORM_TEXT_COLOUR} mb-2`}>{text}</p>
    <RadioGroup name={questionIdentifier} id="yes" label="Yes" value="Yes" options={[]} />
    <RadioGroup name={questionIdentifier} id="no" label="No" value="No" options={[]} />
    <span className="text-red-600">
      <FormError name={questionIdentifier} />
    </span>
  </div>
);

const SymptomsQuestions = ({
  text,
  questionIdentifier,
}: {
  text: string;
  questionIdentifier: string;
}) => (
  <div className={FORM_SPACING}>
    <p className={`${FORM_TEXT_COLOUR} mb-2`}>{text}</p>
    <RadioGroup name={questionIdentifier} id="yes" label="Yes" value="Yes" options={[]} />
    <RadioGroup name={questionIdentifier} id="no" label="No" value="No" options={[]} />
    <span className="text-red-600">
      <FormError name={questionIdentifier} />
    </span>
  </div>
);

const MedicalQuestions = ({
  text,
  questionIdentifier,
}: {
  text: string;
  questionIdentifier: string;
}) => (
  <div className={FORM_SPACING}>
    <p className={`${FORM_TEXT_COLOUR} mb-2`}>{text}</p>
    <RadioGroup name={questionIdentifier} id="yes" label="Yes" value="Yes" options={[]} />
    <RadioGroup name={questionIdentifier} id="no" label="No" value="No" options={[]} />
    <span className="text-red-600">
      <FormError name={questionIdentifier} />
    </span>
  </div>
);

const renderMCItem = ({ item, index }: { item: QuestionType; index: number }) => (
  <MedicalQuestions key={index} text={item.text} questionIdentifier={item.questionIdentifier} />
);

const renderIFItem = ({ item, index }: { item: QuestionType; index: number }) => (
  <IndemnityQuestions key={index} text={item.text} questionIdentifier={item.questionIdentifier} />
);

const renderSYItem = ({ item, index }: { item: QuestionType; index: number }) => (
  <SymptomsQuestions key={index} text={item.text} questionIdentifier={item.questionIdentifier} />
);

const QuestionModelFiltered = (
  text: string,
  questionIdentifier: string,
): { text: string; questionIdentifier: string } => ({
  text: text,
  questionIdentifier: questionIdentifier,
});

export const IndemityForm = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const isLoggedIn = getIsLoggedIn();
  const toastDuration = { duration: 3000 };
  let name = '';

  const { data: questions } = useQuery(
    ['getQuestions'],
    () => indemnityFormService.getQuestions(id),
    {
      select: (data) =>
        map(data, (question) =>
          QuestionModel(question.content, question.questionIdentifier, question.category),
        ),
    },
  );

  const medicalQuestionsUnfiltered =
    questions?.filter((item) => item.category === 'medical_questions') ?? [];
  const medicalQuestions = map(medicalQuestionsUnfiltered, (question) =>
    QuestionModelFiltered(question.text, question.questionIdentifier),
  );

  const symptomQuestionsUnfiltered =
    questions?.filter((item) => item.category === 'symptom_questions') ?? [];
  const symptomQuestions = map(symptomQuestionsUnfiltered, (question) =>
    QuestionModelFiltered(question.text, question.questionIdentifier),
  );

  const indemnityQuestionsUnfiltered =
    questions?.filter((item) => item.category === 'indemnity_questions') ?? [];
  const indemnityQuestions = map(indemnityQuestionsUnfiltered, (question) =>
    QuestionModelFiltered(question.text, question.questionIdentifier),
  );

  const { mutateAsync: saveIndemnityForm } = useMutation(
    (formData: IndemityValuesProps) => indemnityFormService.sendIndemnityForm(formData, id),
    {
      onSuccess: () => {
        toast.success('Form submitted successfully', toastDuration);
        isLoggedIn
          ? navigate(`/indemnity-form-completion?source=logged_in&&name=${name}`)
          : navigate(`/indemnity-form-completion?name=${name}`);
      },
      onError: (error) => {
        Sentry.captureException(error);
        toast.error('Could not submit form', toastDuration);
      },
    },
  );

  const handleSubmit = (formData: IndemityValuesProps) => {
    name = `${formData.firstName} ${formData.lastName}`;
    return saveIndemnityForm(formData);
  };

  const FormComponent = ({
    isSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
    submitForm,
  }: FormikProps<IndemityValuesProps>) => {
    const handleSelectedTitle: BaseOption = find(
      TITLE,
      (option) => option.value === values.title,
    ) ?? {
      label: '',
      value: '',
    };

    const handleSelectedGender: BaseOption = find(
      GENDER,
      (option) => option.value === values.gender,
    ) ?? {
      label: '',
      value: '',
    };

    const consentHandler = (e: React.FormEvent<HTMLInputElement>) =>
      setFieldValue('consent', e.currentTarget.checked);

    const handlePhoneBlur = () => setFieldTouched('contactNo', true);

    const handlePhoneInputChange = (value: string | undefined) => {
      if (value && isValidPhoneNumber(value)) {
        setFieldValue('contactNo', value);
      }
    };

    return (
      <div className="mx-4 mt-24 xs:mx-6 md:mx-14">
        <p className="mb-1 text-2xl font-semibold">Indemity Form</p>
        <div className="w-16 rounded border-2 border-gold" />
        <div className="mt-14 mb-20 py-6 px-4 shadow-md shadow-slate-300 md:px-20 xl:mr-[30%] 2xl:mr-[50%]">
          <p className="border-b-2 border-slate-200 pb-4 text-[#262626]">
            &quot;*&quot; Indicates Required Fields
          </p>
          <p className={`my-5 ${FORM_TEXT_COLOUR} mb-2 font-bold`}>Personal Information</p>
          <div className={FORM_SPACING}>
            <Dropdown
              fieldName="title"
              options={TITLE}
              value={handleSelectedTitle}
              label="Title*"
            />
            <span className="text-red-600">
              <FormError name="title" />
            </span>
          </div>
          <div className={`${FORM_SPACING} grid grid-cols-2 gap-2 sm:gap-8`}>
            <div>
              <p className={FORM_TEXT_COLOUR}>First Name*</p>
              <TextField name="firstName" label="" type="text" placeholder="First Name" />
            </div>
            <div>
              <p className={FORM_TEXT_COLOUR}>Last Name*</p>
              <TextField name="lastName" label="" type="text" placeholder="Last Name" />
            </div>
          </div>
          <div className={FORM_SPACING}>
            <Dropdown
              fieldName="gender"
              options={GENDER}
              value={handleSelectedGender}
              label="Gender*"
            />
            <span className="text-red-600">
              <FormError name="gender" />
            </span>
          </div>
          <div className={FORM_SPACING}>
            <p className={FORM_TEXT_COLOUR}>Date of Birth*</p>
            <TextField name="dateOfBirth" type="date" placeholder="Date of Birth" />
          </div>
          <div className={FORM_SPACING}>
            <p className={FORM_TEXT_COLOUR}>Passport Number*</p>
            <TextField name="passportNo" type="text" placeholder="Passport Number" />
          </div>
          <div className={FORM_SPACING}>
            <p className={FORM_TEXT_COLOUR}>Contact Number*</p>
            <PhoneInput
              placeholder="Phone Number"
              value={values.contactNo}
              onBlur={handlePhoneBlur}
              onChange={handlePhoneInputChange}
              name="contactNo"
            />
            <span className="text-red-600">
              <FormError name="contactNo" />
            </span>
          </div>
          <div className={FORM_SPACING}>
            <p className={FORM_TEXT_COLOUR}>Email*</p>
            <TextField name="email" type="email" placeholder="email@email.com" />
          </div>
          <div className={FORM_SPACING}>
            <p className={FORM_TEXT_COLOUR}>Next Of Kin*</p>
            <TextField name="nextOfKin" type="text" placeholder="Next of Kin" />
          </div>
          <div className={`${FORM_SPACING} mb-8`}>
            <Dropdown
              fieldName="countryOfResidence"
              options={countryOptions}
              value={{
                label:
                  countryOptions.find((c) => c.value === values.countryOfResidence)?.label ?? '',
                value: values.countryOfResidence ?? '',
              }}
              label="Country Of Residence*"
            />
            <span className="text-red-600">
              <FormError name="countryOfResidence" />
            </span>
          </div>
          <div className="my-5 border-b-2 border-slate-200" />
          <p className={`${FORM_TEXT_COLOUR} mb-2 font-bold`}>Travel Information</p>
          <div className={FORM_SPACING}>
            <Dropdown
              fieldName="countryOfTravel"
              options={countryOptions}
              value={{
                label: countryOptions.find((c) => c.value === values.countryOfTravel)?.label ?? '',
                value: values.countryOfTravel ?? '',
              }}
              label="Country Of Travel*"
            />
            <span className="text-red-600">
              <FormError name="countryOfTravel" />
            </span>
          </div>
          <div className={FORM_SPACING}>
            <p className={`${FORM_SPACING} mb-2`}>Mode Of Transportation*</p>
            <RadioGroup name="modeOfTransport" id="bus" label="Bus" value="Bus" options={[]} />
            <RadioGroup
              name="modeOfTransport"
              id="flight"
              label="Flight"
              value="Flight"
              options={[]}
            />
            <RadioGroup
              name="modeOfTransport"
              id="privateCar"
              label="Private Car"
              value="Private Car"
              options={[]}
            />
            <span className="text-red-600">
              <FormError name="modeOfTransport" />
            </span>
          </div>
          <div className={`${FORM_SPACING} grid grid-cols-2 gap-2 sm:gap-8`}>
            <div>
              <p className={FORM_TEXT_COLOUR}>Arrival Date*</p>
              <TextField name="arrivalDate" type="date" placeholder="mm/dd/yy" />
            </div>
            <div>
              <p className={FORM_TEXT_COLOUR}>Arrival Time*</p>
              <TextField name="arrivalTime" type="time" placeholder="14:30" />
            </div>
          </div>
          <div className={`${FORM_SPACING} mb-8 grid grid-cols-2 gap-2 sm:gap-8`}>
            <div>
              <p className={FORM_TEXT_COLOUR}>Date of Departure*</p>
              <TextField name="departureDate" type="date" placeholder="mm/dd/yy" />
            </div>
            <div>
              <p className={FORM_TEXT_COLOUR}>Departure Time*</p>
              <TextField name="departureTime" type="time" placeholder="14:30" />
            </div>
          </div>
          <div className="my-5 border-b-2 border-slate-200" />
          <p className={`${FORM_TEXT_COLOUR} mb-4 font-bold`}>Medical Questionnaire:</p>
          <p className={FORM_TEXT_COLOUR}>
            Disclaimer: Please Note That Advice To Travel Is Given Based On The Details You Give On
            This Form. It Is Your Responsibility To Give All Relevant Information.
          </p>
          {medicalQuestions && medicalQuestions.length > 0 && (
            <FlatList items={medicalQuestions} renderItem={renderMCItem} />
          )}
          <div className="my-5 border-b-2 border-slate-200" />
          <p className={`${FORM_TEXT_COLOUR} mb-4 font-bold`}>
            Have You Experienced The Following Recently:
          </p>
          <p className={FORM_TEXT_COLOUR}>Please Check The Applicable One.</p>
          {symptomQuestions && symptomQuestions.length > 0 && (
            <FlatList items={symptomQuestions} renderItem={renderSYItem} />
          )}
          <div className="my-5 border-b-2 border-slate-200" />
          <p className={`${FORM_TEXT_COLOUR} mb-4 font-bold`}>Indemnity</p>
          <p className={FORM_TEXT_COLOUR}>Please Check The Applicable One.</p>
          {indemnityQuestions && indemnityQuestions.length > 0 && (
            <FlatList items={indemnityQuestions} renderItem={renderIFItem} />
          )}
          <div className="my-5 border-b-2 border-slate-200" />
          <p className={`${FORM_TEXT_COLOUR} mb-2 font-bold`}>Consent</p>
          <div>
            <input
              id="consent-radio"
              type="checkbox"
              name="consent"
              value={1}
              className={CHECK_BOX_STYLE}
              onChange={consentHandler}
              checked={values.consent}
            />
            <label htmlFor="consent-radio" className="pr-4">
              &nbsp;By Writing My Name Below, I Hereby Sign To Acknowledge That The Information
              Provided Above Is True And Correct And To Accept All Responsibility And Liability
            </label>
          </div>
          <div className={FORM_SPACING}>
            <div className="my-5 border-b-2 border-slate-200" />
            <p className={FORM_TEXT_COLOUR}>Name*</p>
            <div className="mt-2 grid grid-cols-2 gap-2 md:gap-8">
              <TextField name="firstName" label="" type="text" placeholder="First Name" />
              <TextField name="lastName" label="" type="text" placeholder="Last Name" />
            </div>
            <Button
              style="bg-gold text-white py-2 sm:px-14 mt-6 rounded xs:px-11 px-8"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              onClick={submitForm}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Form
      initialValues={{
        email: '',
        blurredVision: '',
        title: '',
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        passportNo: '',
        contactNo: '',
        nextOfKin: '',
        countryOfResidence: '',
        countryOfTravel: '',
        modeOfTransport: '',
        arrivalDate: '',
        arrivalTime: '',
        departureDate: '',
        departureTime: '',
        criticallyIll: '',
        pregnant: '',
        blind: '',
        headingProblems: '',
        inpatientCare: '',
        respiratorySupport: '',
        wheelchairBound: '',
        walkingAid: '',
        covidPositive: '',
        vaccinated: '',
        vaccineCertificate: '',
        vaccineCertificateForFlight: '',
        cough: '',
        fever: '',
        diarrhoea: '',
        vomiting: '',
        lossOfConscious: '',
        shortnessOfBreath: '',
        voluntarilyAttendingRetreat: '',
        accommodation: '',
        transportCosts: '',
        personalSaftey: '',
        doctorsCertificate: '',
        releaseKfmiFromAnyAccidents: '',
        KfmiNotLiableForArrangementsOrTransport: '',
        bookingSecurity: '',
        durationOfStay: '',
        personalGoods: '',
        religiousTour: '',
        cancellationOfTrip: '',
        travingWithAMinor: '',
        consent: true,
      }}
      onSubmitForm={handleSubmit}
      validationSchema={indemitySchema}
      render={FormComponent}
      validateOnMount
      validateOnChange
      validateOnBlur
    />
  );
};
