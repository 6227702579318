import dayjs from 'dayjs';

import { SingleArticleApi } from '@project/services';
import { SingleArticleType } from './types';

export const SingleArticleModel = (data: SingleArticleApi): SingleArticleType => ({
  id: data?.id ?? -1,
  author: data?.author ?? '',
  heading: data?.heading ?? '',
  content: data?.content ?? '',
  location: data?.location ?? '',
  createdAt: dayjs(data?.created_at ?? '').format('DD/MM/YYYY'),
  featureImage: data?.feature_image ?? '',
  supportImages: data?.support_images ?? [],
});
