import { DonationDetailsRecieptProps } from './types';

const BORDER_STYLE = 'border-b border-b-gray-400';
const ITEM_STYLE = 'text-sm font-light';
const VALUE_STYLE = 'text-right text-sm font-light';

export const DonationDetailsReciept: React.FC<DonationDetailsRecieptProps> = ({
  total,
  donationType,
}) => (
  <div className="rounded bg-white p-5 shadow-xl">
    <div className="grid py-1 lg:grid-cols-2">
      <div className={ITEM_STYLE}>{donationType}</div>
      <div className={VALUE_STYLE}>{total}</div>
    </div>
    <div className={BORDER_STYLE} />
    <div className="grid space-y-2 py-3 lg:grid-cols-2">
      <div className={ITEM_STYLE}>Sub Total</div>
      <div className={VALUE_STYLE}>{total}</div>
      <div className={ITEM_STYLE}>Payment Method</div>
      <div className={VALUE_STYLE}>Direct Bank Transfer/ Manual Payment</div>
    </div>
    <div className={BORDER_STYLE} />
    <div className="grid py-1 lg:grid-cols-2">
      <div className={ITEM_STYLE}>TOTAL</div>
      <div className={VALUE_STYLE}>{total}</div>
    </div>
  </div>
);
