import { Button, TopImage } from '@project/components/atoms';
import React, { useEffect } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';
import map from 'lodash/map';

import { CharityData } from '@project/helper';

export const IndividualCountryCharities: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const data = CharityData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => navigate(-1);

  const CharityBlocks = ({
    src,
    title,
    charityId,
  }: {
    src: string;
    title: string;
    charityId: string;
  }) => {
    const handleBlockClick = () => {
      navigate(`/individual-charity-view/${charityId}/${id}`);
    };

    return (
      <div className="mx-4 cursor-pointer sm:mx-auto lg:mx-0 lg:ml-10">
        <div className="mb-10 flex max-w-[400px] flex-col" onClick={handleBlockClick}>
          <div>
            <img className="sm:w-[400px]" src={src} />
          </div>
          <div>
            <p className={`bg-white text-center text-lg text-black shadow-md shadow-gray-400`}>
              {title}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const filteredItem = data?.find((item) => item.countryId === id);

  return (
    <div className="mb-20">
      <TopImage />
      <div className="flex flex-col">
        <div className="mt-20 mb-14 md:mx-10 lg:my-16">
          <div className="flex flex-row items-center">
            <Button style="bg-gray-200 rounded md:ml-2 ml-4" onClick={goBack}>
              <ChevronLeftIcon className="w-8" />
            </Button>
            <div className="ml-6 text-4xl font-medium underline decoration-gold decoration-2 underline-offset-4">
              {filteredItem?.countryName}
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap lg:ml-10 xl:ml-16">
          {map(filteredItem?.charities, (item) => (
            <CharityBlocks
              src={item?.featureImage}
              title={item?.charityName}
              charityId={item?.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
