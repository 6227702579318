import { TeamMemberProfileProps } from './types';

export const TeamMemberProfile: React.FC<TeamMemberProfileProps> = ({ teamMember }) => (
  <div className="mx-4 mb-4 w-[170px] 2xl:w-[220px]">
    <img
      src={teamMember.teamProfilePicture}
      alt="Team member profile picture"
      className="h-36 rounded-md object-cover xl:h-[160px]"
    />
    <div className="py-2">
      <span className="pr-1 text-xl font-semibold">{teamMember.firstName}</span>
      <span className="text-xl font-semibold">{teamMember.lastName}</span>
    </div>
    <p className="text-[15px] font-medium">{teamMember.title}</p>
    <p className="text-[15px] font-medium">{teamMember.country}</p>
    <p className="mt-4 text-center text-sm">{teamMember.description}</p>
  </div>
);
