import capitalize from 'lodash/capitalize';

import { MemberCardProps } from './types';

export const MemberCard: React.FC<MemberCardProps> = ({ memberStatus }) => (
  <div
    className={`${
      memberStatus === 'white' ? 'border shadow-md shadow-gray-300' : ''
    } min-w-fit rounded ${memberStatus}-card  py-5 px-2 text-center font-light`}
  >
    <span className={`${memberStatus === 'white' ? 'text-black' : 'text-white'}`}>
      {memberStatus === 'white' ? 'Standard' : capitalize(memberStatus)}
    </span>
    <br />
    <span className={`${memberStatus === 'white' ? 'text-black' : 'text-white'}`}>Member</span>
  </div>
);
