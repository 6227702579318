import ReactGA from 'react-ga4';

export const enum GAEvent {
  LoginAttempt,
  LoginSuccess,
  LoginFailed,
  RegistrationAttempt,
  RegistrationSuccess,
  RegistrationFailed,
  RegistrationPaymentAttempt,
  RegistrationPaymentSuccess,
  RegistrationPaymentFailed,
  PasswordResetAttempt,
  PasswordResetSuccess,
  PasswordResetFailed,
  AccountUnlockAttempt,
  AccountUnlockSuccess,
  AccountUnlockFailed,
  PopUploadAttempt,
  PopUploadSuccess,
  PopUploadFailed,
}

const eventMappings = {
  // Login
  [GAEvent.LoginAttempt]: { category: 'Login', action: 'login_attempt', label: 'Login Attempt' },
  [GAEvent.LoginSuccess]: { category: 'Login', action: 'login_success', label: 'Login Success' },
  [GAEvent.LoginFailed]: { category: 'Login', action: 'login_failed', label: 'Login Failed' },
  // Registration
  [GAEvent.RegistrationAttempt]: {
    category: 'Registration',
    action: 'registration_attempt',
    label: 'Registration Attempt',
  },
  [GAEvent.RegistrationSuccess]: {
    category: 'Registration',
    action: 'registration_success',
    label: 'Registration Success',
  },
  [GAEvent.RegistrationFailed]: {
    category: 'Registration',
    action: 'registration_failed',
    label: 'Registration Failed',
  },
  // Registration Payment
  [GAEvent.RegistrationPaymentAttempt]: {
    category: 'Registration',
    action: 'registration_payment_attempt',
    label: 'Registration Payment Attempt',
  },
  [GAEvent.RegistrationPaymentSuccess]: {
    category: 'Registration',
    action: 'registration_payment_success',
    label: 'Registration Success',
  },
  [GAEvent.RegistrationPaymentFailed]: {
    category: 'Registration',
    action: 'registration_payment_failed',
    label: 'Registration Payment Failed',
  },
  // Password Reset
  [GAEvent.PasswordResetAttempt]: {
    category: 'Password Reset',
    action: 'password_reset_attempt',
    label: 'Password Reset Attempt',
  },
  [GAEvent.PasswordResetSuccess]: {
    category: 'Password Reset',
    action: 'password_reset_success',
    label: 'Password Reset Success',
  },
  [GAEvent.PasswordResetFailed]: {
    category: 'Password Reset',
    action: 'password_reset_failed',
    label: 'Password Reset Failed',
  },
  // Account Unlock
  [GAEvent.AccountUnlockAttempt]: {
    category: 'Account Attempt',
    action: 'account_unlock_attempt',
    label: 'Account Unlock Attempt',
  },
  [GAEvent.AccountUnlockSuccess]: {
    category: 'Account Unlock',
    action: 'account_unlock_success',
    label: 'Account Unlock Success',
  },
  [GAEvent.AccountUnlockFailed]: {
    category: 'Account Failed',
    action: 'account_unlock_failed',
    label: 'Account Unlock Failed',
  },
  // Proof of payment
  [GAEvent.PopUploadAttempt]: {
    category: 'POP',
    action: 'pop_upload_attempt',
    label: 'POP Upload Attempt',
  },
  [GAEvent.PopUploadSuccess]: {
    category: 'POP',
    action: 'pop_upload_success',
    label: 'POP Upload Success',
  },
  [GAEvent.PopUploadFailed]: {
    category: 'POP',
    action: 'pop_upload_failed',
    label: 'POP Upload Failed',
  },
};

export const trackEvent = (event: GAEvent) => {
  const {
    category = 'Unknown',
    action = 'unknown',
    label = 'Unknown',
  } = eventMappings[event] || {};

  ReactGA.event({ category, action, label });
};

export const trackDonationEvent = (
  paymentType: 'event' | 'donation' | 'global_donation' | 'card_holder' | 'membership_renewal',
  eventState: 'attempt' | 'success' | 'failed',
  value: number,
) => {
  const paymentTypeMapping: Record<string, string> = {
    event: 'Event',
    donation: 'Special Donation',
    global_donation: 'Global Donation',
    card_holder: 'Card Holder',
    membership_renewal: 'Membership Renewal',
  };

  const eventStateMapping: Record<string, string> = {
    attempt: 'Payment Attempt',
    success: 'Payment Success',
    failed: 'Payment Failed',
  };

  const capitalizeWords = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  const category = paymentTypeMapping[paymentType];
  const action = `${paymentTypeMapping[paymentType]
    .toLowerCase()
    .replace(' ', '_')}_${eventStateMapping[eventState].toLowerCase().replace(' ', '_')}`;
  const label = capitalizeWords(`${category} ${eventStateMapping[eventState]}`);

  ReactGA.event({ category, action, label, value });
};
