import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/20/solid';

import { userService } from '@project/services';
import { LoadingContainer, Button } from '@project/components';

export const IndividualDonationTypeInfo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const { data: individualDonationTypeInfo, isLoading } = useQuery(['donationType', id], () =>
    userService.getIndividualDonationType(id ?? ''),
  );

  const goToDonationForm = () => navigate(`/partner/charities/donation-form/${id}`);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="grid h-screen grid-cols-3">
        <div className="col-span-3 lg:col-span-2">
          <div className="mb-14 mt-10 flex flex-col md:mt-0 md:p-10 xl:p-20">
            <div className="md:md-0 ml-4 flex flex-row">
              <Button style="bg-gray-200 rounded" onClick={goBack}>
                <ChevronLeftIcon className="w-10" />
              </Button>
              <div className="ml-4 md:ml-14">
                <p className="border-b-2 border-gold text-2xl font-semibold">
                  {individualDonationTypeInfo?.name}
                </p>
              </div>
            </div>
            <div className="flex-1 flex-wrap overflow-x-hidden">
              <p className="px-6 pt-10 leading-loose xl:px-24">
                {individualDonationTypeInfo?.content}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col bg-partners-gray p-6 md:p-24 lg:col-span-1 lg:p-10 xl:p-20">
          <img className="rounded" src={individualDonationTypeInfo?.logo} />
          <div className="mt-10 flex justify-end">
            <Button
              style="bg-gold text-white right-0 w-32 py-2 rounded hover:bg-amber-800"
              onClick={goToDonationForm}
            >
              <div className="flex flex-row">
                <HeartIcon className="mr-2 w-6" />
                <p className="text-lg">Donate</p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};
