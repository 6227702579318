import map from 'lodash/map';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import parse from 'html-react-parser';
import { Dialog } from '@headlessui/react';
import { FlatList } from '@codehesion-za/headless';
import { Carousel } from 'react-responsive-carousel';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/outline';

import { Button, LoadingContainer } from '@project/components';
import { useGetArticleQuery } from '@project/queries';
import { isVideoUrl } from '@project/helper';

const injectLinks = (text: string) => {
  const urlRegex = /https?:\/\/[^\s<]+/g;
  const replacedText = text.replace(urlRegex, (match) => {
    return `<a href="${match}" target="_blank">${match}</a>`;
  });

  return replacedText;
};

const convertToHtml = (value?: string) => {
  if (isNil(value) || isEmpty(value)) return <span></span>;

  return parse(value);
};

export const ViewArticlePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const { data, isLoading } = useGetArticleQuery(id ?? '-1');
  const content = useMemo(
    () => convertToHtml(injectLinks(data?.content ?? '')),
    [data?.content, isLoading],
  );
  const imageArray = useMemo(
    () => concat(data?.featureImage ?? '', data?.supportImages ?? []),
    [isLoading],
  );

  const handleIsOpen = (index: number) => {
    setActiveImage(index);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const goBack = () => navigate(-1);

  const ImageModal = useCallback(
    () => (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="fixed inset-10 my-auto mx-auto hidden items-center justify-center md:flex"
      >
        <div className="fixed inset-0 flex overflow-y-auto bg-black/80">
          <div className="m-auto flex items-center justify-center">
            <Dialog.Panel className="h-[700px] w-[800px] overflow-y-auto">
              <Carousel
                autoPlay={false}
                infiniteLoop={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows
                selectedItem={activeImage}
              >
                {map(imageArray, (src, index) => {
                  const isVideo = isVideoUrl(src);
                  return isVideo ? (
                    <video key={index} className="h-full w-full" controls>
                      <source src={src} type={`video/${src.split('.').pop()}`} />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img src={src} alt={`Expanded image ${index}`} key={index} />
                  );
                })}
              </Carousel>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    ),
    [isOpen, activeImage, imageArray],
  );

  const RenderItem = useCallback(
    ({ item, index }: { item: string; index: number }) => {
      const isVideo = isVideoUrl(item);
      return (
        <div key={index} className="mb-4 md:col-span-1 md:px-5 md:py-2">
          {isVideo ? (
            <video className="h-full w-full" controls onClick={() => handleIsOpen(index + 1)}>
              <source src={item} type={`video/${item.split('.').pop()}`} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={item}
              alt={`Support Image ${index}`}
              className="h-full w-full"
              onClick={() => handleIsOpen(index + 1)}
            />
          )}
        </div>
      );
    },
    [handleIsOpen],
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="mt-6 md:grid md:grid-cols-4">
        <div className="col-span-4 bg-white p-5 xs:mx-6 md:py-20 lg:col-span-2">
          <div className="w-fit">
            <h1 className="text-xl font-semibold">{data?.heading}</h1>
            <div className="mt-2 w-1/4 border-2 border-gold" />
          </div>
          <div className="my-8 flex">
            <div className="mr-4 flex">
              <UserIcon className="w-4" />
              <p className="pl-2">{data?.author}</p>
            </div>
            <div className="flex">
              <CalendarIcon className="w-4" />
              <p className="pl-2">{data?.createdAt}</p>
            </div>
          </div>
          <div className="border-b-2 border-t-2 border-t-gray-400 border-b-gray-400 py-4">
            {content}
          </div>
          <div className="my-4">
            <Button type="button" variant="text" onClick={goBack}>
              <span className="text-base normal-case text-gray-700 underline hover:text-gray-800">
                Back
              </span>
            </Button>
          </div>
        </div>
        <div className="col-span-4 mx-6 md:grid md:grid-cols-2 md:py-20 lg:col-span-2">
          <div className="mb-4 md:col-span-2 md:px-5 md:py-2">
            {data && data.featureImage && isVideoUrl(data.featureImage) ? (
              <video
                className="h-60 w-full rounded object-cover md:h-80"
                controls
                onClick={() => handleIsOpen(0)}
              >
                <source src={data.featureImage} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={data?.featureImage ?? ''}
                alt="Feature Image"
                onClick={() => handleIsOpen(0)}
              />
            )}
          </div>
          <FlatList items={data?.supportImages ?? []} renderItem={RenderItem} />
        </div>
      </div>
      <ImageModal />
    </LoadingContainer>
  );
};
