import config from '@project/config';

import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';

import { FileInputEvent, ImageUploaderProps } from './types';
import { Button } from '@project/components';

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  fieldName,
  selectedFile,
  imageStyle = 'h-36 w-36 rounded-full object-cover',
  useFormData = false,
  isEditable = true,
}) => {
  const { setFieldValue } = useFormikContext<unknown>();
  const displayValue = useMemo(
    () =>
      useFormData && selectedFile instanceof File && selectedFile !== null
        ? URL.createObjectURL(selectedFile as File)
        : (selectedFile as string),
    [selectedFile],
  );

  const handleReaderOnload = (event: ProgressEvent<FileReader>) =>
    setFieldValue(fieldName, event.target?.result ?? '');

  const handleFileChange = useCallback((event: FileInputEvent) => {
    const file = event.target.files[0];

    if (useFormData) return setFieldValue(fieldName, file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = handleReaderOnload;
  }, []);

  const handleRemoveFile = useCallback(() => setFieldValue(fieldName, useFormData ? null : ''), []);

  const handleUpdateFile = () => {
    const fileInput = document.getElementById(`file-input-${fieldName}`) as HTMLInputElement | null;
    if (fileInput && isEditable) {
      fileInput.click();
    }
  };

  return (
    <>
      {selectedFile ? (
        <>
          <input
            name={fieldName}
            id={`file-input-${fieldName}`}
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleFileChange}
            className="hidden"
          />
          <div
            className={isEditable ? 'col-span-5 flex justify-end' : 'col-span-8 flex justify-end'}
          >
            <img
              className={`mb-1 ${imageStyle}`}
              src={displayValue}
              alt="Selected file"
              onClick={handleUpdateFile}
            />
          </div>
          {isEditable && (
            <div className="col-span-1 mt-8 ml-3">
              <Button
                onClick={handleUpdateFile}
                style="w-[100px] mb-2 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                Upload
              </Button>
              <Button
                onClick={handleRemoveFile}
                style="w-[100px] inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                Remove
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <label
            className={isEditable ? 'col-span-5 flex justify-end' : 'col-span-8 flex justify-end'}
            htmlFor={`file-input-${fieldName}`}
          >
            <img
              className={`mb-1 ${imageStyle}`}
              src={`${config.hostUrl}/api/public_assets/public_assets/profile_logo`}
              alt="Selected file"
            />
            {isEditable && (
              <input
                name={fieldName}
                id={`file-input-${fieldName}`}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileChange}
                className="hidden"
              />
            )}
          </label>
          {isEditable && (
            <div className="col-span-3 mt-12 ml-5">
              <Button
                onClick={handleUpdateFile}
                style="w-[100px] mb-2 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                Upload
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
