import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Navbar } from '@project/components';
import { OptionalChildrenProps } from '@project/types';

export const WebLayout: React.FC<OptionalChildrenProps> = ({ children }) => {
  const location = useLocation();
  const hideNavbar =
    location.pathname === '/register-partner' ||
    location.pathname === '/terms-and-conditions-mobile';

  return (
    <>
      {!hideNavbar && <Navbar />}
      <div className="relative">{children || <Outlet />}</div>
    </>
  );
};
