import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

import { ForgotPasswordForm, Button } from '@project/components';
import { Logo } from '@project/assets';

export const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const onBack = () => navigate('/partner/auth/login');

  return (
    <div className="auth-page">
      <div className="auth-form-container">
        <div className="absolute left-4">
          <Button variant="text" onClick={onBack} style="auth-back-button">
            <ChevronLeftIcon className="h-6" />
          </Button>
        </div>
        <div className="auth-form-logo">
          <img src={Logo} alt="logo" className="auth-image" />
          <h1 className="auth-form-heading">Forgot Password</h1>
        </div>
        <ForgotPasswordForm />
      </div>
    </div>
  );
};
