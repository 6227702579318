import find from 'lodash/find';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';
import { FormikProps } from 'formik/dist/types';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Card,
  PaymentAmountField,
  PrayerRequestCheckboxes,
  TextField,
} from '@project/components';
import { MeetingContributionValues, MeetingContributionProps } from './types';
import { meetingContributionSchema } from './schemas';
import { useGetEventQuery, useGetPublicEventQuery } from '@project/queries';

export const MeetingContributionForm: React.FC<MeetingContributionProps> = ({
  id,
  indemnityFormId,
  eventType,
  isPublic = false,
}) => {
  const navigate = useNavigate();
  const { data: event } = isPublic ? useGetPublicEventQuery(id) : useGetEventQuery(id);

  const defaultMinDonation = event?.minDonations.find(
    (option) => option && option.currency === 'USD',
  )?.minDonation;

  const initiaValues: MeetingContributionValues = {
    contributionCurrency: event?.minDonationCurrency ?? 'USD',
    contributionAmount: defaultMinDonation ? Number(defaultMinDonation) : 0.0,
    prayerRequests: [],
    tip: 0.0,
  };

  const currentMinDonation = (currency?: string) =>
    find(event?.minDonations, (minDonation) => minDonation.currency === currency) ?? {
      symbol: 'R',
      currency: 'ZAR',
      minDonation: '0.00',
    };

  const currentMinDonationAmount = (currency?: string) =>
    Number(currentMinDonation(currency)?.minDonation);

  const [selectedMinDonation, setSelectedMinDonation] = useState(
    currentMinDonation(event?.minDonationCurrency ?? ''),
  );

  const schema = useMemo(
    () => meetingContributionSchema(currentMinDonationAmount(selectedMinDonation?.currency)),
    [selectedMinDonation],
  );

  const onSubmitForm = (formData: MeetingContributionValues) => {
    navigate(
      `/payment?type=${event?.name}&event_id=${event?.id}&amount=${
        formData.contributionAmount + (formData.tip ? formData.tip : 0)
      }&currency=${formData.contributionCurrency}&payment_type=event&prayer_requests=${
        formData.prayerRequests
      }&indemnity_form_id=${indemnityFormId}&event_type=${eventType}`,
    );
    return new Promise(() => null);
  };

  const FormComponents = ({
    values,
    isSubmitting,
    handleSubmit,
  }: FormikProps<MeetingContributionValues>) => {
    useEffect(() => {
      setSelectedMinDonation(currentMinDonation(values.contributionCurrency));
    }, [values.contributionCurrency]);

    return (
      <Card className="space-y-4 py-10 px-8 xxs:w-[300px] xs:w-[350px] sm:w-[380px] md:w-[700px] lg:w-[870px]">
        <p className="text-base font-semibold">Register For Meeting</p>
        <div className="grid grid-cols-2 gap-4">
          <PaymentAmountField
            amountFieldName="contributionAmount"
            currencyFieldName="contributionCurrency"
            selectedCurrency={values.contributionCurrency}
          />
          <TextField name="tip" label="Tip(optional)" type="number" required={false}></TextField>
        </div>
        <div className="flex w-full justify-end space-x-1 text-xs">
          <span>Total Amount:</span>
          <span className="underline decoration-double">{`${selectedMinDonation?.symbol} ${
            values.contributionAmount + (values.tip ? values.tip : 0)
          }`}</span>
        </div>
        <div className="border-b-2 border-b-gray-300 pt-4" />

        <div className="my-2">
          <PrayerRequestCheckboxes fieldName="prayerRequests" />
        </div>
        <Button
          style="bg-gold text-white font-bold text-sm py-3 rounded px-3"
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Contribute
        </Button>
      </Card>
    );
  };

  return (
    <Form
      initialValues={initiaValues}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={schema}
    />
  );
};
