import { getData, rejectPromise, formDataHeaders } from '@project/services/utils';
import networkService from '../network-service/network.service';
import { paymentDto } from '../payment-service/payment.dto';
import { ProofOfPaymentDto } from './proof-of-payment.dto';
import manualPaymentUrls from './manual-payment.urls';
import { BillingValues } from '@project/components';
import { UploadProofOfPaymentType } from './types';

const createManualPayment = (intentId: string, formData: BillingValues) => {
  const url = manualPaymentUrls.createManualPaymentUrl();
  const dto = paymentDto(intentId, formData);

  return networkService.post(url, dto).then(getData).catch(rejectPromise);
};

const getManualPayment = (id: string) => {
  const url = manualPaymentUrls.getManualPaymentUrl(id);

  return networkService.get(url).then(getData).catch(rejectPromise);
};

const uploadProofOfPayment = (formData: UploadProofOfPaymentType) => {
  const url = manualPaymentUrls.uploadManualPaymentUrl();
  const dto = ProofOfPaymentDto(formData);

  return networkService.post(url, dto, formDataHeaders).then(getData).catch(rejectPromise);
};

const getOutstandingBalance = (referenceNumber: string, currency: string) => {
  const url = manualPaymentUrls.outstandingBalanceUrl(referenceNumber, currency);

  return networkService.get(url).then(getData).catch(rejectPromise);
};

export default {
  getManualPayment,
  createManualPayment,
  uploadProofOfPayment,
  getOutstandingBalance,
};
