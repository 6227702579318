import React from 'react';
import { FlatList } from '@codehesion-za/headless';

import { DonationDetailsSummaryItemProps, DonationDetailsSummaryProps } from './types';

const renderItem = ({ item, index }: { item: DonationDetailsSummaryItemProps; index: number }) => (
  <div key={`${item.heading}${index}${item.value}`} className="w-fit">
    <div className="pb-4 text-sm font-light text-gray-400">{item.heading}</div>
    {item.heading === 'Donation Ref.' ? (
      <div className="relative text-sm font-light">
        <div className="tooltip-wrapper">
          <span>{item.value.slice(0, 6)}</span>
          <span className="tooltip-content">{item.value}</span>
        </div>
      </div>
    ) : (
      <div className="min-w-fit text-sm font-light">{item.value}</div>
    )}
  </div>
);

export const DonationDetailsSummary: React.FC<DonationDetailsSummaryProps> = ({ items }) => (
  <div className="grid rounded bg-white p-5 shadow-xl xl:grid-cols-4">
    <FlatList items={items} renderItem={renderItem} />
  </div>
);
