import React, { useState } from 'react';
import { FlatList } from '@codehesion-za/headless';

import { ArticlesCard, LoadingContainer, SearchField } from '@project/components';
import { useGetPartnerArticlesQuery } from '@project/queries';
import { PartnerArticlesRenderComponent } from './types';
import { stripHtmlTags } from '@project/helper';

const RenderItem = ({ item, index }: PartnerArticlesRenderComponent) => {
  const content = stripHtmlTags(item.content);

  return (
    <ArticlesCard
      key={`${index}-${item.createdAt}`}
      featureImage={item.featureImage}
      id={item.id}
      heading={item.heading}
      content={content}
      author={item.author}
      createdAt={item.createdAt}
    />
  );
};

const ListEmptyComponent = <span className="text-gray-600">No articles to show</span>;

export const PartnersArticlesTab: React.FC = () => {
  const [search, setSearch] = useState('');
  const { data: articles, isLoading } = useGetPartnerArticlesQuery(search);

  return (
    <div className="grid lg:grid-cols-6">
      <div className="col-span-2 p-6 xl:p-14">
        <p>Articles</p>
        <div className="w-16 rounded border-b-2 border-gold" />
        <p className="mt-2 text-sm text-gray-400">Read about the work we do in our community.</p>
        <SearchField
          className="mt-4 rounded-full border-soft-grey bg-articles-gray xl:w-[400px]"
          placeholder="Search"
          onChange={setSearch}
        />
      </div>
      <div className="col-span-4 p-6 md:p-14">
        <LoadingContainer isLoading={isLoading}>
          <FlatList
            items={articles?.articles ?? []}
            renderItem={RenderItem}
            ListEmptyComponent={ListEmptyComponent}
          />
        </LoadingContainer>
      </div>
    </div>
  );
};
