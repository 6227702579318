export const posisionOptions: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

type GeocodingCurrencyData = {
  code: string;
  symbol: string;
};

export type GeocodingDataType = {
  street: string;
  state: string;
  stateCode: string;
  suburb: string;
  town: string;
  postalCode: string;
  country: string;
  countryCode: string;
  currency: GeocodingCurrencyData;
};
