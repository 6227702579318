import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import { DonationHistoryCardProps } from './types';
import { Button } from '@project/components';

export const DonationHistoryCard: React.FC<DonationHistoryCardProps> = ({
  header,
  id,
  amount,
  logo,
}) => {
  const navigate = useNavigate();

  const handleViewIndividualDonationHistory = () => navigate(`/partner/donations/${id}`);

  return (
    <div className="mb-6 flex-row items-center rounded-lg bg-white p-4 shadow-md shadow-slate-200 ">
      <div className="flex flex-row">
        <img className="h-[100px] w-24" src={logo} />
        <div className="ml-4 flex w-full flex-col">
          <div className="flex w-full flex-row justify-between">
            <p className="text-sm tracking-wide text-black md:text-lg">{header}</p>
            <Button variant="text" style="text-black" onClick={handleViewIndividualDonationHistory}>
              <ArrowRightIcon className="mr-4 w-5 md:w-7" />
            </Button>
          </div>
          <div className="my-2 w-full border border-[#C6C6C6]" />
          <p className="mt-4 text-sm font-light text-black md:text-lg">
            Total Amount: <span className="ml-4 text-sm font-semibold md:text-2xl">{amount}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
