export default {
  appEnv: import.meta.env.VITE_APP_ENV,
  hostUrl: import.meta.env.VITE_HOST_URL,
  apiUrl: `${import.meta.env.VITE_HOST_URL}${import.meta.env.VITE_API_LOCATION}/v${
    import.meta.env.VITE_API_VERSION
  }`,
  clientId: import.meta.env.VITE_CLIENT_ID,
  clientSecret: import.meta.env.VITE_CLIENT_SECRET,
  scope: import.meta.env.VITE_CLIENT_SCOPE,
  accessTokenKey: 'access_token',
  refreshTokenKey: 'refresh_token',
  rememberMeKey: 'remember_me',
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? '',
  returnUrl: import.meta.env.VITE_RETURN_URL ?? '',
  recaptchaSiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  bugsnagKey: import.meta.env.VITE_BUGSNAG_KEY,
  gaMeasurementId: import.meta.env.VITE_GA_MEASUREMENT_ID,
  sentryDns: import.meta.env.VITE_SENTRY_DNS,
};
