import React from 'react';
import { useParams } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/outline';
import { MapPinIcon } from '@heroicons/react/24/outline';

import { ContactForm } from '@project/components/organisms';
import { TopImage } from '@project/components/atoms';
import { Mail, Phone } from '@project/assets';

export const ContactSection: React.FC = () => {
  const { type } = useParams();

  const getCoordinator = () => {
    switch (type) {
      case 'usa-office':
        return 'Abianah Mensa';
      case 'sa-office':
        return 'Nathalie Dibe';
      case 'uk-office':
        return 'Mbalienhle Bazo';
      case 'malawi-office':
        return 'Angela Scott';
      default:
        return 'sa-offices';
    }
  };

  const getNumber = () => {
    switch (type) {
      case 'usa-office':
        return '+1 866 223 8839 ';
      case 'sa-office':
        return '+27 66 137 1932';
      case 'uk-office':
        return ' +44 7894 857 405';
      case 'malawi-office':
        return '+265 985 36 6366';
      default:
        return 'sa-offices';
    }
  };

  const getEmail = () => {
    switch (type) {
      case 'usa-office':
        return 'usa@kfminternational.org';
      case 'sa-office':
        return (
          <div>
            <p>info@kfminternational.org</p>
            <p>southafrica@kfminternational.org</p>
          </div>
        );
      case 'uk-office':
        return 'uk@kfminternational.org';
      case 'malawi-office':
        return 'malawi@kfminternational.org';
      default:
        return 'sa-offices';
    }
  };

  const getRecipientEmails = () => {
    switch (type) {
      case 'usa-office':
        return ['usa@kfminternational.org'];
      case 'sa-office':
        return ['info@kfminternational.org', 'southafrica@kfminternational.org'];
      case 'uk-office':
        return ['uk@kfminternational.org'];
      case 'malawi-office':
        return ['malawi@kfminternational.org'];
      default:
        return ['info@kfminternational.org', 'southafrica@kfminternational.org'];
    }
  };

  const getLocation = () => {
    switch (type) {
      case 'usa-office':
        return '1 Rockefeller Plaza, 11th Floor, New York, NY 10020';
      case 'sa-office':
        return 'Block D, Cullinan Office Park, 35 Cullinan Close, Morningside, Sandton 2057';
      case 'uk-office':
        return 'Orchard Court 4, Harry Weston Road, Coventry CV3 2TQ';
      case 'malawi-office':
        return 'Golden Peacock Offices 1st Floor KFMI Office, Lilongwe, Malawi';
      default:
        return 'sa-offices';
    }
  };

  const number = getNumber();
  const email = getEmail();
  const recipientEmails = getRecipientEmails();
  const address = getLocation();
  const coordinator = getCoordinator();

  const headingStyle = 'mb-2 md:text-2xl font-medium';
  const imageStyle = 'h-6 pr-6 text-white';
  const divStyle = 'flex pt-10 text-white';
  const textStyle = 'mb-2 text-[13px] md:text-base';

  return (
    <div className="flex flex-col pb-32">
      <TopImage />
      <div className="my-12 ml-6 xl:ml-36">
        <p className="mb-2 text-3xl font-semibold tracking-wide">Contact Us</p>
        <div className="w-24 rounded border-2 border-gold" />
      </div>
      <div className="flex flex-col gap-y-8 lg:grid lg:grid-cols-2 lg:gap-y-0 xl:gap-20 xl:pl-32">
        <div className="mx-4 xs:mx-6">
          <ContactForm recipients={recipientEmails} />
        </div>
        <div className="bg-[#262626] pl-6 sm:pl-10 md:pl-36">
          <div className="flex flex-col">
            <div className={divStyle}>
              <img className={imageStyle} src={Mail} />
              <div>
                <p className={headingStyle}>Email:</p>
                <p className={textStyle}>{email}</p>
              </div>
            </div>
            <div className={divStyle}>
              <img className={imageStyle} src={Phone} />
              <div className="mb-2 flex flex-col">
                <p className={headingStyle}>Tel:</p>
                <p className={textStyle}>{number}</p>
              </div>
            </div>
            <div className={`${divStyle}`}>
              <UserIcon className={imageStyle} />
              <div className="mb-2 flex flex-col">
                <p className={headingStyle}>National Coordinator:</p>
                <p className={textStyle}>{coordinator}</p>
              </div>
            </div>
            <div className={`${divStyle} pb-16`}>
              <MapPinIcon className={imageStyle} />
              <div className="mb-2 flex flex-col">
                <p className={headingStyle}>Address:</p>
                <p className={textStyle}>{address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
