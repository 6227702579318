import { BillingValues } from '@project/components';
import { PartnerRegisterationValues } from '@project/schemas';

export const BillingFormModel = (data: PartnerRegisterationValues): BillingValues => ({
  number: data?.phoneNumber ?? '',
  email: data?.email ?? '',
  country: data?.country ?? '',
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  phoneNumber: data?.phoneNumber ?? '',
  countryCode: data?.countryCode ?? '',
  street1: data?.street1 ?? '',
  street2: data?.street2 ?? '',
  region: data?.region ?? '',
  townCity: data?.townCity ?? '',
  postcodeZip: data?.postcodeZip ?? '',
  intentId: data?.intentId ?? '',
  paymentMethod: 'debit_credit',
  termsAndConditions: true,
});
