import React from 'react';
import { toast } from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { useGetIsPartner } from '@project/queries';
import { IndustryField } from '@project/components/molecules';
import { Button, Dropdown, TextField } from '@project/components/atoms';
import { PartnerRegisterationValues } from '@project/schemas';
import { CountryCode } from 'libphonenumber-js/core';
import { EMAIL_ALREADY_EXIST, FILL_REQUIRED_INPUTS, TOAST_DURATION } from '@project/helper';
import { getCode, getNames } from 'country-list';
import { BaseOption } from '@project/types';

const countries = getNames();

const countryOptions: BaseOption[] = countries
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);

export const OrganisationForm: React.FC = () => {
  const { setValues, values, errors, setFieldValue, setErrors, touched, setTouched } =
    useFormikContext<PartnerRegisterationValues>();
  const { data, isLoading } = useGetIsPartner(values.email);

  const isFormFilled = () =>
    !isEmpty(values.name) &&
    !isEmpty(values.email) &&
    !isEmpty(values.phoneNumber) &&
    !isEmpty(values.country) &&
    !isEmpty(values.industry);

  const goBack = () =>
    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: true,
      currentStep: '1/5',
    }));

  const handlePhoneInputChange = (value: string | undefined) => {
    if (value && isValidPhoneNumber(value)) {
      const parsedNumber = parsePhoneNumber(value?.toString() ?? '');
      setFieldValue('number', value);
      setFieldValue('phoneNumber', parsedNumber?.nationalNumber);
      setFieldValue('countryCode', parsedNumber?.country);
    }
  };

  const handlePhoneInputBlur = () => {
    setTouched({ ...touched, phoneNumber: true });
  };

  const isSubmitting = () => {
    if (data?.isPartner) {
      setErrors({ email: EMAIL_ALREADY_EXIST });
      return;
    }

    if (!isFormFilled()) {
      toast.error(FILL_REQUIRED_INPUTS, TOAST_DURATION);
      return;
    }

    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: false,
      showPasswordForm: true,
      currentStep: '3/5',
    }));
  };

  const errorStyling = (error: string | undefined, isTouched: boolean | undefined) => {
    return error && isTouched ? 'border-red-600' : '';
  };

  return (
    <div className="relative mt-10 border-t-2 border-slate-300">
      <Button
        style="top-[-190px] absolute rounded bg-lightGrey p-1"
        onClick={goBack}
        variant="text"
      >
        <ArrowLeftIcon className="w-5 text-black" />
      </Button>
      <div className="mt-6 mb-4 flex flex-col gap-1">
        <TextField
          className="mb-2"
          name="name"
          type={'text'}
          placeholder="Name"
          label="Organisation*"
        />
        <Dropdown
          fieldName="country"
          options={countryOptions}
          value={{
            label: countryOptions.find((c) => c.value === values.country)?.label ?? '',
            value: values.country ?? '',
          }}
          label="Select Country*"
        />
        <label style={{ marginTop: '5px' }} htmlFor="country">
          Phone Number *
        </label>
        <div className="mb-2 ">
          {' '}
          <PhoneInput
            className={`${errorStyling(
              errors?.phoneNumber,
              touched?.phoneNumber ?? false,
            )} rounded-lg`}
            placeholder="Phone Number"
            defaultCountry={values.countryCode ? (values.countryCode as CountryCode) : 'US'}
            value={values.number}
            onChange={handlePhoneInputChange}
            onBlur={handlePhoneInputBlur}
            name="phoneNumber"
          />
          {errors?.phoneNumber && touched?.phoneNumber && (
            <span className="text-sm text-red-600">{errors?.phoneNumber}</span>
          )}
        </div>
        <TextField
          className="mb-0"
          name="email"
          type={'text'}
          placeholder="email@gmail.com"
          label="Email*"
        />
        <div className="mb-0">
          <IndustryField industryFieldName="industry" selectedIndustry={values.industry ?? ''} />
        </div>
        <TextField
          className="mb-2"
          name="nearestBranch"
          type="text"
          placeholder="Nearest Branch"
          label="Nearest Branch"
        />
        <TextField
          className="mb-2"
          name="assistantName"
          type="text"
          placeholder="Assistant Name"
          label="Assistant Name"
        />
      </div>
      <Button
        isDisabled={isLoading || !isFormFilled()}
        style={`bg-${isFormFilled() ? 'gold' : 'gray-300'} text-white w-full rounded-md py-2.5`}
        onClick={isSubmitting}
      >
        NEXT
      </Button>
    </div>
  );
};
