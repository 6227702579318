import { AuthLayout, LoginPage, ForgotPasswordPage, ResetPasswordPage } from '@project/components';
export const AuthRouter = [
  {
    path: 'partner/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
    ],
  },
];
