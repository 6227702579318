import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { DonationTypeListApi } from '@project/services';
import { paginationModel } from '@project/models';
import { DonationTypeListType } from './types';

export const DonationTypeListModel = (data: DonationTypeListApi): DonationTypeListType => ({
  donationTypes: isNil(data) || isEmpty(data.donation_types) ? [] : data.donation_types,
  meta: paginationModel(data.meta),
});
