import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

import { Button, LoginForm } from '@project/components';
import { Logo } from '@project/assets';

export const LoginPage = () => {
  const navigate = useNavigate();

  const onBack = () => navigate('/');

  const goToRegisterPage = () => navigate('/register-partner');

  return (
    <div className="auth-page">
      <div className="auth-form-container">
        <div className="absolute left-2 top-2">
          <Button variant="text" onClick={onBack} style="auth-back-button">
            <ChevronLeftIcon className="h-6" />
          </Button>
        </div>
        <div className="auth-form-logo">
          <img src={Logo} alt="logo" className="auth-image" />
          <h1 className="auth-form-heading">Login</h1>
        </div>
        <LoginForm />
        <div className="p-2 md:p-5">
          <p className="mx-auto text-center text-sm font-light md:w-3/4">
            By continuing, you agree to accept our Privacy Policy & Terms of Service
          </p>
        </div>
        <div className="mt-4 sm:flex sm:justify-center md:mt-0">
          <span className="pr-1 font-light">Don&apos;t have an account?</span>
          <Button
            variant="text"
            onClick={goToRegisterPage}
            style="text-base text-gold underline font-semibold"
          >
            REGISTER
          </Button>
        </div>
      </div>
    </div>
  );
};
