import React, { useEffect } from 'react';

import { DonationProps } from './types';
import { DonationForm, TopImage } from '@project/components';

export const DonationPage: React.FC<DonationProps> = ({ formType }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mb-32 flex flex-col">
      <TopImage />
      <div className="mx-auto mb-5 mt-12 md:ml-10 xl:ml-20">
        <h1 className="text-2xl font-semibold">{`${
          formType === 'special' ? 'Special' : 'Global'
        } Donations`}</h1>
        <div className="mt-1 h-0 w-16 rounded-2xl border-2 border-gold" />
      </div>

      <div className="mx-auto mt-4 w-1/3 min-w-fit md:ml-10 xl:ml-20">
        <DonationForm formType={formType} />
      </div>
    </div>
  );
};
