import { BankInfoDisplay, ProofofPaymentForm, TopImage } from '@project/components';

export const ProofofPaymentPage: React.FC = () => (
  <div className="mb-32 flex flex-col">
    <TopImage />
    <div className="grid grid-cols-5">
      <div className="col-span-5 lg:col-span-2">
        <div className="w-fit pb-5 pl-6 pt-20 lg:pl-16">
          <p className="text-2xl font-semibold">Upload Proof Of Payment</p>
          <div className="w-1/4 border-b-2 border-b-gold pt-2" />
        </div>
        <div className="pl-6 pr-6 lg:pl-16">
          <ProofofPaymentForm />
        </div>
      </div>
      <div className="col-span-5 mx-2 md:mx-0 md:pt-20 lg:col-span-3  lg:pr-[304px] xl:pr-[304px]">
        <BankInfoDisplay />
      </div>
    </div>
  </div>
);
