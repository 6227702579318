import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useGetPartnerBillingDetails, PartnersBillingDetailsModel } from '@project/queries';
import { usePartnerExpiredStore, useBillingDetailsStore } from '@project/stores';
import { Button, PartnerNav } from '@project/components';
import { OptionalChildrenProps } from '@project/types';

export const AppLayout: React.FC<OptionalChildrenProps> = ({ children }) => {
  const navigate = useNavigate();
  const { setPartnersBillingDetails } = useBillingDetailsStore();
  const { isExpired } = usePartnerExpiredStore();
  const { data, isLoading } = useGetPartnerBillingDetails();

  useEffect(() => {
    setPartnersBillingDetails(data ?? PartnersBillingDetailsModel());
  }, [data?.email, isLoading]);

  const goToMembershipRenewal = () => navigate('/membership-renewal');

  return (
    <div className="h-screen w-screen">
      <PartnerNav />
      {isExpired && (
        <div className="my-2 mx-5 flex justify-center rounded py-1 shadow-sm">
          <span className="pr-2">Your membership is expired. To renew your membership</span>
          <Button onClick={goToMembershipRenewal} variant="text" type="button" style="underline">
            click here
          </Button>
        </div>
      )}
      <div>{children || <Outlet />}</div>
    </div>
  );
};
