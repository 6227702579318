import { ChangePartnersPasswordValues, PartnersProfileValues } from '@project/components';
import { PartnerRegisterationValues } from '@project/schemas';
import { ChangePartnersPasswordApi, PartnerApi, UpdatePartnersProfileApi } from './types';

export const CreatePartnerDto = (data: PartnerRegisterationValues): PartnerApi => ({
  partner_information: {
    name: data.name ?? '',
    birthday: data?.type === 'individual' ? data.dateOfBirth ?? '' : '',
    email: data.email ?? '',
    phone_number: data.phoneNumber ?? '',
    country: data.country ?? '',
    donor_category_id: data?.type === 'individual' ? data.donorCategoryId ?? '' : '',
    title: data?.type === 'individual' ? data.title ?? '' : '',
    occupation: data?.type !== 'organisation' ? data.occupation ?? '' : '',
    industry: data.industry ?? '',
    membership_type: data.type ?? '',
    password: data.confirmPassword ?? '',
    nearest_branch: data.nearestBranch ?? '',
    assistant_name: data.assistantName ?? '',
    user: {
      first_name: data.firstName || data.name || '',
      last_name: data.lastName || data.name || '',
      email: data.email ?? '',
      country_code: data.country ?? '',
      password: data.newPassword ?? '',
      confirm_password: data.confirmPassword ?? '',
      mobile: data.number ?? '',
      street1: data.street1 ?? '',
      street2: data.street2 ?? '',
      zip: data.postcodeZip ?? '',
      city: data.townCity ?? '',
      region: data.country ?? '',
    },
  },
});

export const UpdatePartnerDto = (data: PartnersProfileValues): UpdatePartnersProfileApi => ({
  partner_information: {
    id: data?.id ?? 3,
    name: data.name ?? '',
    birthday: data.birthday ?? '',
    email: data.email ?? '',
    phone_number: data.phoneNumber ?? '',
    country: data.country ?? '',
    donor_category_id: data.donorCategoryId ?? '',
    title: data.title ?? '',
    occupation: data.occupation ?? '',
    industry: data.industry ?? '',
    membership_type: data.membershipType ?? '',
    profile_image: data.profileImage ?? '',
    nearest_branch: data.nearestBranch ?? '',
  },
});

export const ChangePartnersPasswordDto = (
  data: ChangePartnersPasswordValues,
): ChangePartnersPasswordApi => ({
  partner_information: {
    password: data?.password ?? '',
  },
});
