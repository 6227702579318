import { BillingFormIconProps } from './types';
import {
  CardHolderIcon,
  MeetingIcon,
  MembershipIcon,
  RetreatIcon,
  ChurchBuildingIcon,
} from '@project/assets';

export const BillingFormIcon: React.FC<BillingFormIconProps> = ({ icon }) => {
  const determineIcon = () => {
    switch (icon) {
      case 'card':
        return CardHolderIcon;
      case 'meeting':
        return MeetingIcon;
      case 'membership':
        return MembershipIcon;
      case 'donation':
        return ChurchBuildingIcon;
      case 'retreat':
        return RetreatIcon;
      default:
        return ChurchBuildingIcon;
    }
  };

  const iconSrc = determineIcon();

  return (
    <div className="h-8 w-8 rounded-lg border border-gray-200 p-1">
      <img src={iconSrc} alt="An icon representing the payment" className="mx-auto h-full" />
    </div>
  );
};
