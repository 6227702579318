import { ContactBlock } from './contact-block';

const ZA_Numbers = ['+27 (79) 492-0785', '+27 661789426', '+27 (63) 779-7599'];
const UK_NUMBERS = ['+44 7725 045226'];
const US_NUMBERS = ['+1 (914) 343-8026'];

export const RetreatConditions: React.FC = () => (
  <div className="mt-4 w-full min-w-fit space-y-4 font-light md:mt-9 md:space-y-9">
    <div className="pl-5 text-sm">
      <ul className="list-disc">
        <li>
          Please note, your booking can only be secured with a minimum of 30% required as
          downpayment.
        </li>
        <li>Space is limited!</li>
        <li>Please see poster for all booking information</li>
      </ul>
    </div>
    <p className="text-lg">Contact Our Booking Agents Directly</p>
    <ContactBlock title="South Africa" contactNumbers={ZA_Numbers} />
    <ContactBlock title="United Kingdom" contactNumbers={UK_NUMBERS} />
    <ContactBlock title="United States of America" contactNumbers={US_NUMBERS} />
  </div>
);
