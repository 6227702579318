import React from 'react';
import { FormikProps } from 'formik';
import includes from 'lodash/includes';
import { toast } from 'react-hot-toast';
import 'react-phone-number-input/style.css';
import { Transition } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { Checkbox, Form } from '@codehesion-za/headless';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { RegionDropdown } from 'react-country-region-selector';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { BillingFormIcon, RadioGroup, TextField, Button, Dropdown } from '@project/components';
import { useBillingDetailsStore } from '@project/stores';
import { ConfirmParamsModel } from './confirm-params.model';
import {
  PAYMENT_INTENT_SUCCESS,
  PAYMENT_PROCESSING_ERROR,
  REFERENCE_NUMBER_SENT,
  TOAST_DURATION,
  getIsLoggedIn,
} from '@project/helper';
import { billingSchema } from './schema';
import { BillingValues, PaymentType } from './types';
import './billing-form.css';
import {
  CreateManualPaymentApi,
  manualPaymentService,
  trackDonationEvent,
} from '@project/services';
import { StripeError } from '@stripe/stripe-js';
import { CountryCode } from 'libphonenumber-js/core';
import { getCode, getNames } from 'country-list';
import { BaseOption } from '@project/types';
import * as Sentry from '@sentry/react';

const countries = getNames();

// Transform country data into a suitable format for Dropdown
const countryOptions: BaseOption[] = countries
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);

interface BillingFormProps {
  stripeIntentId: string;
  donationAmount: string;
  donationCurrency: string;
  donationType: string;
  paymentType: PaymentType;
  eventType: string;
  indemnityFormId: number | null;
}

export const BillingForm: React.FC<BillingFormProps> = ({
  stripeIntentId,
  donationAmount,
  donationCurrency,
  donationType,
  paymentType,
  eventType,
  indemnityFormId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = getIsLoggedIn();
  const billingDetailsStore = useBillingDetailsStore();
  const { values: billingDetails } = billingDetailsStore;

  const getIcon = () => {
    switch (paymentType) {
      case 'card_holder':
        return 'card';
      case 'membership_renewal':
        return 'membership';
      case 'event':
        return includes(donationType, 'retreat') ? 'retreat' : 'meeting';
      default:
        return 'donation';
    }
  };

  const getContributionDescription = () => {
    switch (paymentType) {
      case 'donation':
        return `${donationType} - Donations`;
      case 'event':
        return includes(donationType, 'retreat')
          ? `${donationType}`
          : `${donationType} - Meeting Contribution`;
      default:
        return donationType;
    }
  };

  const goToUploadPop = () => navigate('/upload-proof-of-payment');

  const completePayment = () => {
    const source = isLoggedIn ? 'logged_in' : 'not_logged_in';
    if (paymentType === 'event' && eventType === 'other' && indemnityFormId) {
      navigate(`/thank-you?indemnity_form_id=${indemnityFormId}&event_type=other&source=${source}`);
    } else {
      navigate(`/thank-you-modal?source=${source}`, {
        state: {
          backgroundLocation: location,
        },
      });
    }
  };

  const { mutateAsync: createManualPayment, isLoading: isManualPaymentLoading } = useMutation(
    (user: BillingValues) => manualPaymentService.createManualPayment(stripeIntentId, user),
    {
      onSuccess: (data: CreateManualPaymentApi) => {
        toast.success(REFERENCE_NUMBER_SENT, TOAST_DURATION);
        navigate(`/donation-details/${data.id}`);
      },
      onError: (error: unknown) => {
        Sentry.captureException(error);
        toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
      },
    },
  );

  const onSubmitForm = async (formData: BillingValues) => {
    if (!stripe || !elements) {
      trackDonationEvent(paymentType, 'failed', Number(donationAmount));
      toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
      return;
    }

    if (formData.paymentMethod === 'manual_payment') {
      await createManualPayment(formData);
      billingDetailsStore.set({ values: formData });
      return;
    }

    try {
      trackDonationEvent(paymentType, 'attempt', Number(donationAmount));

      const result = await elements.submit();
      if (result.error) {
        const errorMessage = result.error.message || PAYMENT_PROCESSING_ERROR;
        toast.error(errorMessage, TOAST_DURATION);
        return;
      }

      elements.getElement('payment')?.update({
        fields: {
          billingDetails: 'never',
        },
      });

      const { paymentIntent, error: paymentError } = await stripe.confirmPayment({
        elements,
        confirmParams: ConfirmParamsModel(formData),
        redirect: 'if_required',
      });

      if (paymentIntent && paymentIntent.status === PAYMENT_INTENT_SUCCESS) {
        trackDonationEvent(paymentType, 'success', Number(donationAmount));
        completePayment();
      } else {
        Sentry.captureException(paymentError);
        toast.error(paymentError?.message || PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
        trackDonationEvent(paymentType, 'failed', Number(donationAmount));
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
      billingDetailsStore.set({ values: formData }); // Retain form data even after error
      trackDonationEvent(paymentType, 'failed', Number(donationAmount));
    }
  };

  const errorStyling = (field: string | number | undefined) => `${field ? 'border-red-600' : ''}`;

  const onPaymentElementLoadError = (stripeEvent: {
    elementType: 'payment';
    error: StripeError;
  }) => {
    Sentry.captureException(stripeEvent.error);
    toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
    navigate(-1);
    trackDonationEvent(paymentType, 'failed', Number(donationAmount));
  };

  const FormComponents = ({
    values,
    errors,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
  }: FormikProps<BillingValues>) => {
    return (
      <div className="mb-24 ml-24 mr-24 flex justify-center">
        <div className="m:grid-cols-2 grid rounded-xl pb-10 shadow-lg lg:grid-cols-2 xl:grid-cols-2">
          <div className="flex rounded-xl">
            <div className="my-4 space-y-4 p-2 py-4 xs:p-8 md:w-full md:px-20">
              <h1 className="mt-5 mb-4 text-base font-semibold">Billing Details</h1>
              <div className="flex w-full flex-wrap space-x-0 md:flex-nowrap md:space-x-2">
                <TextField
                  className={`${errorStyling(errors?.firstName)}`}
                  name="firstName"
                  label="First Name"
                  type="text"
                  placeholder="Name"
                  required
                />
                <TextField
                  className={`${errorStyling(errors?.lastName)}`}
                  name="lastName"
                  label="Last Name"
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div>
                <TextField
                  className={errorStyling(errors?.email)}
                  name="email"
                  label="Email"
                  type="text"
                  placeholder="email@address.com"
                  required
                />
              </div>
              <div>
                <label htmlFor="phoneNumber">Phone Number *</label>
                <PhoneInput
                  className={`mt-1 ${errors?.phoneNumber ? 'rounded-lg border' : ''} ${errorStyling(
                    errors?.phoneNumber,
                  )}`}
                  placeholder="Phone Number"
                  defaultCountry={values.countryCode ? (values.countryCode as CountryCode) : 'US'}
                  value={values.number}
                  onChange={(value) => {
                    const parsedNumber = parsePhoneNumber(value?.toString() ?? '');
                    setFieldValue('number', parsedNumber?.number);
                    setFieldValue('phoneNumber', parsedNumber?.number);
                    setFieldValue('countryCode', parsedNumber?.country);
                  }}
                  name="phoneNumber"
                />
                <span className="text-sm text-red-600">{errors.number}</span>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <TextField
                  className={errorStyling(errors?.street1)}
                  name="street1"
                  label="Street Address"
                  type="text"
                  placeholder="Address"
                  required
                />
                <TextField
                  name="street2"
                  label=""
                  type="text"
                  placeholder="Apartment, suite, unit, etc. (Optional)"
                />
              </div>
              <div className="grid grid-cols-1 gap-4">
                <label htmlFor="country" className="-mb-2.5">
                  Country / Region
                </label>
                <div
                  className={`${errors?.country ? 'rounded-lg border' : ''} ${errorStyling(
                    errors?.country,
                  )}`}
                >
                  <Dropdown
                    fieldName="country"
                    options={countryOptions}
                    value={{
                      label: countryOptions.find((c) => c.value === values.country)?.label ?? '',
                      value: values.country ?? '',
                    }}
                    label=""
                  />
                </div>
                {errors?.country && <span className="text-red-600">{errors?.country}</span>}
                <label htmlFor="region" className="-mb-2.5">
                  Province / State
                </label>
                <div
                  className={`${errors?.region ? 'rounded-lg border' : ''} ${errorStyling(
                    errors?.region,
                  )}`}
                >
                  <RegionDropdown
                    id="region"
                    name="region"
                    countryValueType="short"
                    country={values.country ?? ''}
                    value={values.region ?? ''}
                    onChange={(value) => setFieldValue('region', value)}
                  />
                </div>
                {errors?.region && <span className="text-red-600">{errors?.region}</span>}
              </div>
              <div>
                <TextField
                  className={errorStyling(errors?.townCity)}
                  name="townCity"
                  label="Town / City"
                  type="text"
                  placeholder="City"
                  required
                />
              </div>

              <div>
                <TextField
                  className={errorStyling(errors?.postcodeZip)}
                  name="postcodeZip"
                  label="Postcode / Zip"
                  type="text"
                  placeholder="Zip"
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex border-lightGrey md:ml-0 lg:border-l-2 xl:justify-center xl:border-l-2">
            <div className="my-6 space-y-4 p-8 px-8 xl:ml-10">
              <h1 className="mb-4 text-base font-semibold">Your Donation</h1>
              <div className="flex space-x-5">
                <BillingFormIcon icon={getIcon()} />
                <p className="my-auto text-xs">{getContributionDescription()}</p>
              </div>
              <div className="h-0.5 bg-gray-200"></div>
              <div className="flex justify-between font-semibold">
                <p>Total</p>
                <p>
                  {donationCurrency} {donationAmount}
                </p>
              </div>
              <div>
                {location.state !== 'manual' && (
                  <>
                    <RadioGroup
                      name="paymentMethod"
                      id="stripe_payment"
                      label="Debit / Credit Card Payment"
                      value="debit_credit"
                      options={[]}
                    />
                    <Transition
                      show={values.paymentMethod === 'debit_credit'}
                      enter="transition-opacity duration-75"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="mb-4">
                        <p className="text-xs">Pay with your card via Stripe payments.</p>
                        <PaymentElement onLoadError={onPaymentElementLoadError} />
                      </div>
                    </Transition>
                  </>
                )}
                <>
                  <RadioGroup
                    name="paymentMethod"
                    id="manual_payment"
                    label="Manual Payment"
                    value="manual_payment"
                  />
                  <Transition
                    show={values.paymentMethod === 'manual_payment'}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="mb-4">
                      <p className="text-xs">
                        Once you select “complete payment” you will be directed to a page where all
                        KFMI banking details are listed. Once you have made the payment, you can
                        upload your proof of payment{' '}
                        <span
                          className="cursor-pointer text-blue-600 underline"
                          onClick={goToUploadPop}
                        >
                          here
                        </span>
                        .
                      </p>
                    </div>
                  </Transition>
                </>
                <div className="h-0.5 bg-gray-200"></div>
                <p className="w-full py-5 text-left text-xs">
                  Your personal data will be used to process your order, support your experience
                  throughout this website, and for other purposes described in our terms and
                  conditions.
                </p>
                <div className="mb-4">
                  <Checkbox
                    id="termsAndConditions"
                    name="termsAndConditions"
                    className="text-gold"
                  />
                  <label htmlFor="termsAndConditions" className="ml-2 text-end text-xs">
                    I have read and agree to the
                    <a className="ml-1 hover:text-gold" href="/terms-and-conditions">
                      Terms and Conditions
                    </a>
                  </label>
                  {errors?.termsAndConditions && (
                    <p className="text-xs text-red-600">{errors?.termsAndConditions}</p>
                  )}
                </div>
                <Button
                  isDisabled={isSubmitting || isManualPaymentLoading || !isValid}
                  style={`bg-${
                    !isValid ? 'gray-200' : 'gold'
                  } text-white font-bold text-sm rounded-md py-3 w-full mt-5`}
                  onClick={handleSubmit}
                >
                  {isSubmitting || isManualPaymentLoading ? 'Processing...' : 'Complete Payment'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Form
      initialValues={{
        paymentMethod: 'debit_credit',
        firstName: billingDetails.firstName ?? '',
        lastName: billingDetails.lastName ?? '',
        email: billingDetails.email ?? '',
        number: billingDetails.number ?? '',
        phoneNumber: billingDetails.phoneNumber ?? '',
        countryCode: billingDetails.countryCode ?? '',
        street1: billingDetails.street1 ?? '',
        street2: billingDetails.street2 ?? '',
        country: billingDetails.country ?? '',
        region: billingDetails.region ?? '',
        townCity: billingDetails.townCity ?? '',
        postcodeZip: billingDetails.postcodeZip ?? '',
        intentId: stripeIntentId,
        termsAndConditions: billingDetails.termsAndConditions ?? false,
      }}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={billingSchema}
      validateOnMount
      validateOnBlur
      validateOnChange
    />
  );
};
