import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useFormikContext } from 'formik';

import { SecureFieldprops } from './types';

export const SecureTextField: React.FC<SecureFieldprops> = ({
  label,
  placeholder,
  name,
  error,
}: {
  label: string;
  placeholder: string;
  name: string;
  error?: string | boolean;
}) => {
  const { setFieldValue } = useFormikContext();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, data?.target?.value ?? '');
  };

  return (
    <div className="relative">
      <label>{label}</label>
      <input
        name={name}
        type={passwordVisible ? 'text' : 'password'}
        placeholder={placeholder}
        className="w-full rounded border py-2 px-4 focus:border-gold"
        onChange={handleChange}
      />
      <button type="button" className="absolute right-3 z-10" onClick={togglePasswordVisibility}>
        {passwordVisible ? (
          <EyeIcon className="mt-2 w-6 bg-white text-black" />
        ) : (
          <EyeSlashIcon className="mt-2 w-6 bg-white text-black" />
        )}
      </button>
      <div className="mb-2 h-5">
        <label className=" text-red-500 ">{error ? error : ''}</label>
      </div>
    </div>
  );
};
