import React from 'react';

import { CardholderForm, TopImage } from '@project/components';

export const CardholderPage: React.FC = () => {
  return (
    <div className="mb-32 flex flex-col">
      <TopImage />
      <div className="ml-4 mt-12 md:ml-16">
        <p className="text-2xl font-semibold">Card Holder</p>
        <div className="w-20 border-2 border-gold" />
        <div className="pt-4">
          <CardholderForm />
        </div>
      </div>
    </div>
  );
};
