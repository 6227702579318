import z from 'zod';

import { paginationApiSchema, paginationSchema } from '@project/schemas';

export const SingleEventMinDonationsApiSchema = z.array(
  z.object({
    currency: z.string(),
    symbol: z.string(),
    min_donation: z.string(),
  }),
);

export const SingleEventMinDonationsSchema = z.array(
  z.object({
    currency: z.string(),
    symbol: z.string(),
    minDonation: z.string(),
  }),
);

export const SingleEventApiSchema = z.object({
  id: z.number(),
  name: z.string(),
  charity: z.string(),
  link: z.string(),
  start_at: z.string(),
  end_at: z.string().or(z.null()),
  gmt_timezone: z.number(),
  min_donation_currency: z.string(),
  min_donations: SingleEventMinDonationsApiSchema,
  event_image: z.string().or(z.null()),
  event_type: z.string(),
  has_registered: z.boolean().optional(),
  indemnity_form_id: z.number().optional(),
});

export const SingleEventSchema = z.object({
  id: z.number(),
  name: z.string(),
  charity: z.string(),
  link: z.string(),
  startAt: z.string(),
  endAt: z.string().or(z.null()),
  gmtTimezone: z.number(),
  minDonationCurrency: z.string(),
  minDonations: SingleEventMinDonationsSchema,
  eventImage: z.string().or(z.null()),
  eventType: z.string(),
  hasRegistered: z.boolean().optional(),
  indemnityFormId: z.number().optional(),
});

export const EventApiSchema = z.object({
  id: z.number(),
  name: z.string(),
  charity: z.string(),
  link: z.string(),
  start_at: z.string(),
  end_at: z.string().or(z.null()),
  gmt_timezone: z.number(),
  event_type: z.string(),
  has_registered: z.boolean().optional(),
  indemnity_form_id: z.number().optional(),
});

export const EventSchema = z.object({
  id: z.number(),
  name: z.string(),
  charity: z.string(),
  link: z.string(),
  startAt: z.string(),
  endAt: z.string().or(z.null()),
  eventType: z.string(),
  hasRegistered: z.boolean().optional(),
  indemnityFormId: z.number().optional(),
});

export const AllEventsApiSchema = z.object({
  events: z.array(EventApiSchema),
  meta: paginationApiSchema,
});

export const AllEventsSchema = z.object({
  events: z.array(EventSchema),
  meta: paginationSchema,
});

export const CurrencyApiSchema = z.object({
  code: z.string(),
  name: z.string(),
});

export const CurrenciesApiSchema = z.array(CurrencyApiSchema);
