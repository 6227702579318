import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { SingleTeamMemberModel } from './team-member.model';
import { TeamMembersModel } from './team-members.model';
import { meetTheTeamService } from '@project/services';

const toastDuration = { duration: 3000 };

export const useGetAllTeamMembersQuery = () =>
  useQuery(['getAllTeamMembers'], () => meetTheTeamService.getAllTeamMembers(), {
    select: TeamMembersModel,
    onError: () => toast.error('Could not fetch all team members', toastDuration),
  });

export const useGetTeamMemberQuery = (id: string) =>
  useQuery(['getTeamMember', id], () => meetTheTeamService.getSingleTeamMember(id), {
    select: SingleTeamMemberModel,
    onError: () => toast.error('Could not fetch team member data', toastDuration),
  });
