import { Form } from '@codehesion-za/headless';
import { FormikProps } from 'formik';
import find from 'lodash/find';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, donationSchema, Dropdown, PaymentAmountField } from '@project/components';
import { BaseOption } from '@project/types';
import { DonationFormValues, DonationProps } from './types';

const COUNTRIES = [
  { label: 'Asia and Oceana', value: 'Asia and Oceana' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Europe', value: 'Europe' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'North America', value: 'North America' },
  { label: 'USA', value: 'USA' },
  { label: 'South America', value: 'South America' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'East Africa', value: 'East Africa' },
  { label: 'South Africa - Eastern Cape', value: 'South Africa - Eastern Cape' },
  { label: 'South Africa - Free State', value: 'South Africa - Free State' },
  { label: 'South Africa - Guateng', value: 'South Africa - Eastern Cape' },
  { label: 'South Africa - Kwazulu-Natal', value: 'South Africa - Kwazulu-Natal' },
  { label: 'South Africa - Limpopo', value: 'South Africa - Limpopo' },
  { label: 'South Africa - Mpumalanga', value: 'South Africa - Mpumalanga' },
  { label: 'South Africa - Northern Cape', value: 'South Africa - Northern Cape' },
  { label: 'South Africa - North West', value: 'South Africa - North West' },
  { label: 'South Africa - Western Cape', value: 'South Africa - Western Cape' },
  { label: 'Southern Africa', value: 'Southern Africa' },
  { label: 'West Africa', value: 'West Africa' },
  { label: 'Other', value: 'Other' },
];

export const PartnersIndividualGlobalDonationForm: React.FC<DonationProps> = ({
  donationTypeName,
}) => {
  const navigate = useNavigate();

  const onSubmitForm = (formData: DonationFormValues) => {
    navigate(
      `/payment?type=${formData.donationType}&amount=${formData.donationAmount}&currency=${formData.donationCurrency}&payment_type=global_donation`,
    );
    return new Promise(() => null);
  };

  const FormComponents = ({
    isSubmitting,
    handleSubmit,
    values,
  }: FormikProps<DonationFormValues>) => {
    const getSelectedCountry: BaseOption = find(
      COUNTRIES,
      (country) => country.value === values.donationType,
    ) ?? { label: 'Botswana', value: 'Botswana' };

    return (
      <div className="rounded-md bg-white px-10 py-20 shadow shadow-slate-300">
        <p className="text-xl">Select Donation Amount</p>
        <div className="border border-b" />
        <div className="mt-6 space-y-2">
          <span className="text-xs font-light">
            Which country or province would you like to donate towards?
          </span>
          <Dropdown options={COUNTRIES} fieldName={'donationType'} value={getSelectedCountry} />
        </div>
        <div className="py-20">
          <PaymentAmountField
            amountFieldName="donationAmount"
            currencyFieldName="donationCurrency"
            selectedCurrency={values.donationCurrency ?? ''}
          />
        </div>
        <div className="border border-b" />

        <Button
          type="submit"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          style="bg-gold text-white py-3 px-16 rounded mt-10"
        >
          Continue
        </Button>
      </div>
    );
  };

  return (
    <Form
      initialValues={{ donationAmount: 1, donationCurrency: 'USD', donationType: donationTypeName }}
      onSubmitForm={onSubmitForm}
      onFailure={() => null}
      render={FormComponents}
      validationSchema={donationSchema}
    />
  );
};
