import React from 'react';
import { RadioButton } from '@codehesion-za/headless';

import { RadioGroupProps } from './types';

export const RadioGroup: React.FC<RadioGroupProps> = ({ id, name, label, value }) => {
  return (
    <div className="mb-2 flex items-center">
      <RadioButton id={id} name={name} value={value} className="text-gold" />
      <label htmlFor={id} className="ml-2 text-[#262626]">
        {label}
      </label>
    </div>
  );
};
