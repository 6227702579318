import { useQuery } from '@tanstack/react-query';

import { eventService } from '@project/services';

export const useGetEventsQuery = () => useQuery(['getEvents'], () => eventService.getAllEvents());

export const useGetEventQuery = (id: string) =>
  useQuery(['getEvent', id], () => eventService.getEvent(id));

export const useGetPublicEventQuery = (id: string) =>
  useQuery(['getEvent', id], () => eventService.getPublicEvent(id));

export const useGetLatestEventQuery = () =>
  useQuery(['getLatestEvent'], () => eventService.getLatestEvent());

export const useGetCurrenciesQuery = () =>
  useQuery(['getCurrencies'], () => eventService.getCurrencies());
