import React from 'react';
import toast from 'react-hot-toast';
import { FormikProps } from 'formik';
import { Form } from '@codehesion-za/headless';
import { useMutation } from '@tanstack/react-query';

import { ContactUsValues, ContactUsFormProps } from './types';
import { Button, TextField } from '@project/components';
import { contactUsSchema } from './contact-form.schema';
import { contactUsService } from '@project/services';

const textInputStyle = 'rounded w-full mt-1';
const textAreaStyle = 'rounded xl:pb-20 pl-4 pt-2 w-[100%] pb-8 md:mb-12 mt-1 mb-6';

export const ContactForm: React.FC<ContactUsFormProps> = ({ recipients }) => {
  const toastDuration = { duration: 3000 };

  const { mutateAsync } = useMutation(
    (formData: ContactUsValues) => contactUsService.postContactUs(formData),
    {
      onSuccess: () => toast.success('Email sent.', toastDuration),
      onError: () => toast.error('Could not send email.', toastDuration),
    },
  );

  const onSubmitForm = (formData: ContactUsValues) => mutateAsync(formData);

  const FormComponents = ({
    isSubmitting,
    isValid,
    handleSubmit,
  }: FormikProps<ContactUsValues>) => (
    <div>
      <div className="grid md:grid-cols-2 md:space-x-1">
        <TextField
          name="fullName"
          placeholder="Name and Surname"
          type="text"
          label="Full Name"
          className="mb-6 mt-1 rounded"
        />
        <TextField
          name="email"
          placeholder="email@address.com"
          type="text"
          label="Email"
          className="mb-6 rounded"
        />
      </div>
      <div className="mb-6">
        <TextField
          name="topic"
          placeholder="Topic"
          type="text"
          label="Enter the Topic"
          className={textInputStyle}
        />
      </div>
      <TextField
        name="message"
        placeholder="Type message here..."
        type="text"
        className={textAreaStyle}
        label="Enter Message"
      />
      <div className="w-80">
        <Button
          style="bg-gold text-white px-8 py-3 rounded"
          isDisabled={isSubmitting || !isValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Send Message
        </Button>
      </div>
    </div>
  );

  return (
    <Form
      initialValues={{
        fullName: '',
        email: '',
        topic: '',
        message: '',
        recipientEmails: recipients,
      }}
      onSubmitForm={onSubmitForm}
      validationSchema={contactUsSchema}
      render={FormComponents}
    />
  );
};
