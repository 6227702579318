import { isEmpty, isNil, map } from 'lodash';

export const stripHtmlTags = (value?: string) => {
  if (isNil(value) || isEmpty(value)) return '';

  try {
    const dom = new DOMParser().parseFromString(value, 'text/html');

    const textContent = map(dom.body.children, (children) => children?.textContent ?? '').join(' ');

    return textContent;
  } catch {
    return value;
  }
};
