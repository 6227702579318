import { AxiosResponse } from 'axios';

import eventUrls from './event.urls';
import { eventModel } from './event.model';
import { EventsModel } from './events.model';
import { CurrenciesModel } from './currencies.model';
import networkService from '../network-service/network.service';
import { SingleEventApiSchema, AllEventsApiSchema, CurrenciesApiSchema } from './schemas';
import { authNetworkService } from '@project/services';

const getLatestEvent = () => {
  const url = eventUrls.getLatestEventUrl();

  return networkService
    .get(url)
    .then((apiResponse: AxiosResponse) => eventModel(SingleEventApiSchema.parse(apiResponse.data)));
};

const getAllEvents = () => {
  const url = eventUrls.getAllEventsUrl();

  return networkService
    .get(url)
    .then((apiResponse: AxiosResponse) => EventsModel(AllEventsApiSchema.parse(apiResponse.data)));
};

const getEvent = (id: string) => {
  const url = eventUrls.getEventUrl(id);

  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse) => eventModel(SingleEventApiSchema.parse(apiResponse.data)));
};

const getPublicEvent = (id: string) => {
  const url = eventUrls.getPublicEventUrl(id);

  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse) => eventModel(SingleEventApiSchema.parse(apiResponse.data)));
};

const getCurrencies = () => {
  const url = eventUrls.getCurrenciesUrl();

  return networkService
    .get(url)
    .then((apiResponse: AxiosResponse) =>
      CurrenciesModel(CurrenciesApiSchema.parse(apiResponse.data)),
    );
};

export default { getLatestEvent, getEvent, getAllEvents, getCurrencies, getPublicEvent };
