import { useFormikContext } from 'formik';
import { GrDocumentUpload } from 'react-icons/gr';
import React, { useCallback, useMemo } from 'react';

import { FileInputEvent, FileUploaderProps } from './types';

const INPUT_STYLE = { display: 'none' };

export const FileUploader: React.FC<FileUploaderProps> = ({
  labelFor,
  fieldName,
  selectedFile,
  acceptedFileTypes,
}) => {
  const { setFieldValue } = useFormikContext<unknown>();

  const displayValue = useMemo(
    () =>
      selectedFile instanceof File
        ? URL.createObjectURL(selectedFile as File)
        : (selectedFile as string),
    [selectedFile],
  );

  const handleFileChange = useCallback(
    (event: FileInputEvent) => setFieldValue(fieldName, event.target.files[0]),
    [],
  );

  const handleRemoveFile = useCallback(() => setFieldValue(fieldName, ''), []);

  return (
    <div className="rounded border border-dashed">
      <div className="flex justify-center p-4">
        {selectedFile ? (
          <div className="flex flex-col items-center">
            <img className="mb-1 w-36" src={displayValue} alt="Selected file" />
            <button className="rounded bg-gold p-1 text-white" onClick={handleRemoveFile}>
              Remove file
            </button>
          </div>
        ) : (
          <div>
            <label htmlFor={labelFor}>
              <div className="flex-col">
                <GrDocumentUpload size={25} className="ml-[45%] mb-3" />
                Please select a file from your device
              </div>
            </label>
            <input
              name={fieldName}
              id={labelFor}
              type="file"
              accept={acceptedFileTypes}
              onChange={handleFileChange}
              style={INPUT_STYLE}
            />
          </div>
        )}
      </div>
    </div>
  );
};
