import config from '../../../config';

const partnerBaseUrl = `${config.apiUrl}/partner_information`;
const hostUrl = `${config.apiUrl}`;

export default {
  createPartnerUrl: () => partnerBaseUrl,
  getPartnersStats: () => `${partnerBaseUrl}/stats`,
  getCurrentPartner: () => `${partnerBaseUrl}/current_partner`,
  getIsPartnerUrl: (email: string) => `${partnerBaseUrl}/is_partner?email=${email}`,
  editCurrentPartner: () => `${partnerBaseUrl}/update_current_partner`,
  changePartnersPassword: () => `${partnerBaseUrl}/change_password`,
  getPartnerDonationSummary: (search: string) =>
    `${hostUrl}/donation_types/all_partner_donations?search=${search}`,
  getArticles: (search?: string, page?: number, per?: number) =>
    `${hostUrl}/articles?search=${search}&page=${page}&per=${per}`,
  getSignalPartnersDonationInfo: (id: string, search: string, from: string, to: string) =>
    `${hostUrl}/donation_types/${id}/single_partner_donations?search=${search}&from=${from}&to=${to}`,
  getPartnersEvents: (search: string) => `${partnerBaseUrl}/events?search=${search}`,
  deletePartnerUrl: () => `${partnerBaseUrl}/1`,
  billingDetailsUrl: () => `${partnerBaseUrl}/billing_details`,
  donorCategoriesUrl: () => `${partnerBaseUrl}/donor_categories`,
};
