import map from 'lodash/map';

import { HomeContentsContentApi, HomeContentsApi } from '@project/services';
import { HomeContentsContentModelType, HomeContentsModelType } from './types';

const HomeContentsContentModel = (data?: HomeContentsContentApi): HomeContentsContentModelType => ({
  id: data?.id ?? -1,
  link: data?.link ?? '',
  heading: data?.heading ?? '',
  subHeading: data?.sub_heading ?? '',
  buttonType: data?.button_type ?? '',
  featureImage: data?.feature_image ?? '',
  entireWebsite: data?.entire_website ?? true,
});

export const HomeContentsModel = (data?: HomeContentsApi): HomeContentsModelType =>
  map(data ?? [], (content) => HomeContentsContentModel(content));
