import React from 'react';

import { OptionalChildrenProps } from '@project/types';

export const PartnerLayout: React.FC<OptionalChildrenProps> = ({ children }) => {
  return (
    <div className="h-screen w-screen overflow-y-auto bg-none bg-cover md:bg-partner-background">
      <div className="py-4 md:py-6 2xl:py-12">{children}</div>
    </div>
  );
};
