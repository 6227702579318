import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BotswanaMain, SouthAfricaMain, MalawiImage1 } from '@project/assets';
import { Button } from '@project/components/atoms';

export const CharityProjectsSection: React.FC = () => {
  const navigate = useNavigate();

  const handleMalawiPress = () => navigate(`/individual-country-charities/3`);
  const handleBotswanaPress = () => navigate(`/individual-country-charities/4`);
  const handleSAPress = () => navigate(`/individual-country-charities/0`);

  const CharityBlocks = ({
    src,
    title,
    onClick,
  }: {
    src: string;
    title: string;
    onClick: () => void;
  }) => {
    return (
      <button onClick={onClick}>
        <div className="flex cursor-pointer flex-col p-4 sm:p-5">
          <div>
            <img className="w-[500px]" src={src} />
          </div>
          <div>
            <p
              className={`z-10 bg-white p-1 text-center font-semibold text-black shadow-md shadow-gray-400`}
            >
              {title}
            </p>
          </div>
        </div>
      </button>
    );
  };

  return (
    <div className="mt-14 flex flex-col items-center justify-center">
      <p className="text-xl font-semibold text-black md:text-3xl">OUR CHARITY PROJECTS</p>
      <div className="w-[250px] border-2 border-gold md:w-[400px]" />
      <div className="mx-2 mt-10 grid gap-2 md:grid-cols-2 lg:grid-cols-3 xl:mx-16">
        <CharityBlocks src={MalawiImage1} title="Malawi Outreach" onClick={handleMalawiPress} />
        <CharityBlocks src={BotswanaMain} title="Botswana Outreach" onClick={handleBotswanaPress} />
        <CharityBlocks
          src={SouthAfricaMain}
          title="South Africa Outreach"
          onClick={handleSAPress}
        />
      </div>
      <Button
        style="bg-gold text-white mt-8 py-2.5 xxs:px-7 xs:px-12 rounded"
        onClick={() => navigate('/charity-outreach')}
      >
        VIEW ALL CHARITY PROJECTS
      </Button>
    </div>
  );
};
