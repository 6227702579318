import map from 'lodash/map';

import { paginationModel } from '@project/models';
import { DonationType, DonationTypeApi, DonationTypeData, DonationTypeDataApi } from './types';

export const donationModel = (data: DonationTypeApi): DonationType => ({
  id: data?.id ?? '',
  name: data?.name ?? '',
  logo: data?.logo ?? '',
});

export const donationDataModel = (data?: DonationTypeDataApi): DonationTypeData => ({
  donationType: map(data?.donation_types ?? [], donationModel),
  pagination: paginationModel(data?.meta),
});
