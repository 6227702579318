import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast'; // Ensure this import is present

import { DonationTypeListModel } from './donation-types.model';
import { donationTypesService } from '@project/services';

export const useGetDonationTypesQuery = () =>
  useQuery(['getDonationTypes'], () => donationTypesService.getDonationTypes(), {
    select: DonationTypeListModel,
  });

export const useGetDonationSumsQuery = (fromDate: string, toDate: string) =>
  useQuery(
    ['getDonationSums', fromDate, toDate],
    () => donationTypesService.getDonationSums(fromDate, toDate),
    {
      onError: () => toast.error('Could not fetch donation sums'),
    },
  );
