import React from 'react';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';

import { DonationValues, DonationProps } from './types';
import { FormComponent } from '@project/components';
import { donationSchema } from './schema';

export const DonationForm: React.FC<DonationProps> = ({ formType }) => {
  const navigate = useNavigate();

  const initialValues = {
    donationAmount: 1,
    donationCurrency: 'USD',
    donationType: formType === 'special' ? '' : 'North America',
    prayerRequests: [],
  };

  const onSubmitForm = (formData: DonationValues) => {
    navigate(
      `/payment?type=${formData.donationType}&amount=${formData.donationAmount}&currency=${
        formData.donationCurrency
      }&payment_type=${formType === 'special' ? 'donation' : 'global_donation'}&prayer_requests=${
        formData.prayerRequests
      }`,
    );
    return new Promise(() => null);
  };

  const FormComponents = ({}: FormikProps<DonationValues>) => <FormComponent formType={formType} />;

  return (
    <Form
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={donationSchema}
    />
  );
};
