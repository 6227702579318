import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { MoonLoader } from 'react-spinners';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  BillingForm,
  FormScreenContainer,
  TopImage,
  PaymentType,
} from '@project/components';
import { useGetPaymentIntentQuery } from '@project/queries';
import config from '@project/config';
import { toast } from 'react-hot-toast';
import { PAYMENT_PROCESSING_ERROR, TOAST_DURATION } from '@project/helper';

const stripe = loadStripe(config.stripePublishableKey);

export const BillingPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const donationAmount = queryParams.get('amount') ?? '';
  const donationCurrency = queryParams.get('currency') ?? '';
  const donationType = queryParams.get('type') ?? '';
  const paymentType: PaymentType = (queryParams.get('payment_type') as PaymentType) ?? '';
  const eventId = queryParams.get('event_id') ?? '';
  const eventType = queryParams.get('event_type') ?? '';
  const prayerRquests = queryParams.get('prayer_requests') ?? '';
  const indemnityFormId = queryParams.get('indemnity_form_id') ?? '';

  const isRequiredParamsInvalid = () =>
    isEmpty(donationAmount) ||
    isEmpty(donationCurrency) ||
    isEmpty(donationType) ||
    isEmpty(paymentType);

  useEffect(() => {
    if (isRequiredParamsInvalid()) {
      toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
      navigate(-1);
    }
  }, []);

  const { data: paymentIntent, isLoading } = useGetPaymentIntentQuery(
    isRequiredParamsInvalid() ? false : true,
    donationAmount,
    donationCurrency,
    donationType,
    paymentType,
    eventId ? Number(eventId) : undefined,
    prayerRquests ?? '',
  );

  if (isEmpty(paymentIntent) || !paymentIntent?.id || !paymentIntent?.clientSecret || isLoading) {
    return (
      <div className="mt-40 flex justify-center">
        <MoonLoader color="#B58B00" />
      </div>
    );
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret: paymentIntent?.clientSecret }}>
      <TopImage />
      <div className="mt-20 flex flex-col">
        <div className="flex flex-row space-x-8">
          <div className="mt-0.5 xxs:ml-2 sm:ml-4 md:ml-6 xl:ml-16">
            <Button onClick={() => navigate(-1)} variant="text">
              <ArrowLeftIcon className="w-5 text-black" />
            </Button>
          </div>
          <div>
            <p className="mb-5 text-2xl font-semibold">Payment</p>
          </div>
        </div>
      </div>
      <FormScreenContainer>
        <div>
          <BillingForm
            stripeIntentId={paymentIntent.id}
            donationAmount={donationAmount}
            donationCurrency={donationCurrency}
            donationType={donationType}
            paymentType={paymentType}
            eventType={eventType}
            indemnityFormId={indemnityFormId ? Number(indemnityFormId) : null}
          />
        </div>
      </FormScreenContainer>
    </Elements>
  );
};
