import React, { useState, useCallback, ChangeEvent } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import debounce from 'lodash/debounce';

import { SearchFieldProps } from './types';

export const SearchField: React.FC<SearchFieldProps> = ({ placeholder, className, onChange }) => {
  const [search, setSearch] = useState('');

  const validateInputDebounce = useCallback(
    debounce((value) => onChange(value), 500),
    [],
  );

  const onSearchChange = (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
    validateInputDebounce(e.target.value);
  };

  return (
    <div className={`flex border py-2.5 text-black ${className}`}>
      <MagnifyingGlassIcon className="mx-2 w-6 text-gray-400" />
      <input
        value={search}
        placeholder={placeholder}
        onChange={onSearchChange}
        className="flex bg-[#EAEAEA]"
      />
    </div>
  );
};
