import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@codehesion-za/headless';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

import { LoadingContainer } from '@project/components';
import { DonationTypeInfoCardProps } from './types';

export const DonationTypeInfoCard: React.FC<DonationTypeInfoCardProps> = ({
  header,
  content,
  src,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const linesToShow = expanded ? content.length : 350;
  const truncatedContent = content.slice(0, linesToShow);

  const goBack = () => navigate(-1);

  const toggleContent = () => {
    setExpanded(!expanded);
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <div>
        <div className="mb-14 flex flex-row">
          <Button style="bg-gray-200 rounded" onClick={goBack}>
            <ChevronLeftIcon className="w-10" />
          </Button>
          <div className="ml-6">
            <p className="text-xl font-semibold">{header}</p>
            <div className="w-20 rounded border-2 border-b border-gold" />
          </div>
        </div>
        <img src={src} />
        <p className="mt-10 font-light text-black">
          {truncatedContent}
          {!expanded && content.length > 5 && '...'}
        </p>
        {content.length > 5 && (
          <Button style="mt-20 font-semibold underline" onClick={toggleContent}>
            {expanded ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </div>
    </LoadingContainer>
  );
};
