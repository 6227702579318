import React from 'react';
import { PartnerDonationCardProps } from './types';

export const PartnerDonationsCard: React.FC<PartnerDonationCardProps> = ({ totalDonations }) => {
  return (
    <div className="flex justify-end">
      <div className="w-46 min-w-fit rounded border bg-white p-3 text-center font-light shadow-md shadow-gray-300">
        <span className="text-xl">{totalDonations}</span>
        <br />
        <span className="text-sm">Total Donations</span>
      </div>
    </div>
  );
};
