import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { PartnersBillingDetailsModel } from './partners-billing-details.model';
import { IsPartnerModel } from './is-partner.model';
import {
  partnersIndividualDonationsModel,
  partnersDonationSummaryDataModel,
  partnersStatsModel,
  donationDataModel,
  partnerService,
  userService,
  EventsModel,
} from '@project/services';

const duration = { duration: 3000 };

export const useGetPartnerStatsQuery = () =>
  useQuery(['getPartnerStats'], () => partnerService.getPartnersStats(), {
    select: partnersStatsModel,
    onError: () => toast.error('Could not fetch partner stats', duration),
  });

export const useGetPartnerDonationSummary = (search: string) =>
  useQuery(['getDonationSummary', search], () => partnerService.getPartnerDonationSummary(search), {
    select: partnersDonationSummaryDataModel,
    onError: () => toast.error('Could not fetch partner donation summaries', duration),
  });

export const useGetPartnerSingleDonationInfo = (
  id: string,
  search: string,
  fromDate: string,
  toDate: string,
) =>
  useQuery(
    ['getSignalPartnersDonationInfo', search, fromDate, toDate],
    () => partnerService.getSignalPartnersDonationInfo(id, search, fromDate, toDate),
    {
      select: partnersIndividualDonationsModel,
      onError: () => toast.error('Could not fetch partner donation information', duration),
    },
  );

export const useGetIsPartner = (email: string) =>
  useQuery(['getIsPartner', email], () => partnerService.getIsPartner(email), {
    select: IsPartnerModel,
    onError: () => toast.error('Could not check if email is in use', duration),
  });

export const useGetPartnerDonationType = (search: string) =>
  useQuery(['donationType', search], () => userService.getAllDonationTypes(search), {
    select: donationDataModel,
    onError: () => toast.error('Could not fetch partner donation type', duration),
  });

export const useGetPartnerEventsQuery = (search: string) =>
  useQuery(['getPartnersEvents', search], () => partnerService.getPartnersEvents(search), {
    select: EventsModel,
  });

export const useGetPartnerBillingDetails = () =>
  useQuery(['getPartnerBillingDetails'], () => partnerService.getBillingDetails(), {
    select: PartnersBillingDetailsModel,
  });
