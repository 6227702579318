import upperCase from 'lodash/upperCase';

import { MembershipRenewalApi } from '@project/services';
import { MembershipRenewalModelType } from './types';

export const MembershipRenewalModel = (data: MembershipRenewalApi): MembershipRenewalModelType => ({
  fee: data?.fee ?? 0,
  currency: upperCase(data?.currency ?? 'USD'),
  expiryDate: data?.expiry_date ?? '',
});
