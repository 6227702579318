import { ParterTotalDonationCardProps } from './types';

export const PartnerTotalDonationsCard: React.FC<ParterTotalDonationCardProps> = ({
  totalDonations,
  progressToNextLevel,
}) => {
  const progressPercentage = progressToNextLevel ? parseInt(progressToNextLevel) : 0;

  return (
    <div className="w-full min-w-fit rounded border bg-white p-3 text-center font-light shadow-md shadow-gray-300">
      <span className="text-xl">{totalDonations}</span>
      <br />
      <span className="text-sm">Total Donations</span>
      <div className="w-full rounded-2xl bg-gray-300 p-1">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`rounded border-b-2 border-b-gold`}
        />
      </div>
    </div>
  );
};
