import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/20/solid';

import { useGetPartnerStatsQuery } from '@project/queries';
import { usePartnerExpiredStore } from '@project/stores';
import {
  Button,
  MemberCard,
  LoadingContainer,
  PartnersUpcomingMeetings,
  PartnerTotalDonationsCard,
} from '@project/components';

export const PartnersSidePannel: React.FC = () => {
  const navigate = useNavigate();
  const donateButtonAction = () => navigate('/partner/charities');
  const { set } = usePartnerExpiredStore();
  const { data, isLoading } = useGetPartnerStatsQuery();
  const buttonStyle = 'my-auto z-10 md:text-base sm:text-sm text-xs rounded px-2';

  useEffect(() => {
    set({ isExpired: data?.isExpired ?? false });
  }, [isLoading]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="min-w-fit">
          <span>Hi {data?.firstName ?? 'user'}</span>
          <div className="rounded border-b-2 border-b-gold" />
        </div>
        <div className="min-w-fit">
          <Button
            onClick={donateButtonAction}
            style={`${buttonStyle} bg-gold text-white py-1.5 md:px-6`}
          >
            <div className="flex">
              <HeartIcon className="mt-1 h-4" />
              <span className="pl-2">Donate</span>
            </div>
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-x-2 gap-y-2 pt-9 lg:gap-y-0">
        <LoadingContainer isLoading={isLoading}>
          <div className="col-span-5 lg:col-span-3 lg:pr-2">
            <MemberCard memberStatus={data?.membershipStatus ?? 'white'} />
          </div>
          <div className="col-span-5 w-full lg:col-span-2">
            <PartnerTotalDonationsCard
              totalDonations={data?.donations ?? '$0.00'}
              progressToNextLevel={data?.progress ?? '0'}
            />
          </div>
        </LoadingContainer>
      </div>
      <div className="border-b-2 border-gray-300 py-3" />
      <div className="mt-6 mb-6 flex flex-row justify-between">
        <p className="font-medium">Upcoming Events</p>
        <Button
          style="bg-white text-black underline font-medium"
          onClick={() => navigate('/partner/meetings')}
        >
          View All
        </Button>
      </div>
      <PartnersUpcomingMeetings />
    </div>
  );
};
