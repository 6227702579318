import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';
import { FormikProps } from 'formik';

import { useGetMembershipRenewalQuery, useGetExchangeCurrencyQuery } from '@project/queries';
import { Button, RadioGroup, LoadingContainer, PaymentAmountField } from '@project/components';
import { membershipRenewalSchema } from './schemas';
import { MembershipRenewalValues } from './types';

export const MembershipRenewalForm: React.FC = () => {
  const navigate = useNavigate();
  const { data: renewalData, isLoading: renewalLoading } = useGetMembershipRenewalQuery();

  const onSubmitForm = () => new Promise(() => null);

  const FormComponents = ({
    isSubmitting,
    isValid,
    values,
  }: FormikProps<MembershipRenewalValues>) => {
    const { data: exchangeData, isLoading: exchangeLoading } = useGetExchangeCurrencyQuery(
      renewalData?.fee ?? 200,
      renewalData?.currency ?? 'usd',
      values.donationCurrency,
    );

    const isDonationAmountInvalid = useCallback(
      () => (Number(values.donationAmount) < (exchangeData?.convertedAmount ?? 200) ? true : false),
      [
        values.donationAmount,
        exchangeData?.convertedAmount,
        exchangeLoading,
        values.donationCurrency,
      ],
    );

    const validateSubmit = () => {
      const amount =
        values.donationType === 'Membership_Renewal'
          ? values.minDonationAmount
          : values.donationAmount;

      const currency =
        values.donationType === 'Membership_Renewal'
          ? renewalData?.currency
          : values?.donationCurrency;

      navigate(
        `/payment?type=Membership Renewal&amount=${amount}&currency=${currency}&payment_type=membership_renewal `,
      );
    };

    return (
      <LoadingContainer isLoading={exchangeLoading}>
        <div className="form-styling">
          <h1 className="mt-5 mb-4 ml-5 text-base">Membership Renewal</h1>
          <div className="mt-5 ml-5 flex flex-col text-sm">
            <RadioGroup
              name="donationType"
              options={[]}
              id="min_contribution"
              label={`${renewalData?.currency} ${values.minDonationAmount}`}
              value="Membership_Renewal"
            />
            <RadioGroup
              name="donationType"
              options={[]}
              id="own_contribution"
              label="My Own Amount"
              value="Membership_Renewal_own_Amount"
            />
          </div>
          {values.donationType === 'Membership_Renewal_own_Amount' && (
            <>
              <div className="w-full">
                <PaymentAmountField
                  amountFieldName="donationAmount"
                  currencyFieldName="donationCurrency"
                  selectedCurrency={values.donationCurrency}
                />
                {isDonationAmountInvalid() && (
                  <p className="mt-2 text-right text-red-600">
                    Minimum donation amount of {values?.donationCurrency}
                    {exchangeData?.convertedAmount} is Required
                  </p>
                )}
              </div>
            </>
          )}
          <Button
            style="bg-gold text-white font-bold text-sm rounded-md py-3 w-full"
            isLoading={isSubmitting}
            onClick={validateSubmit}
            isDisabled={!isValid}
          >
            Contribute
          </Button>
        </div>
      </LoadingContainer>
    );
  };

  return (
    <LoadingContainer isLoading={renewalLoading}>
      <Form
        initialValues={{
          donationAmount: 1,
          donationType: 'Membership_Renewal',
          minDonationAmount: renewalData?.fee ?? 0,
          donationCurrency: renewalData?.currency ?? 'USD',
        }}
        onSubmitForm={onSubmitForm}
        render={FormComponents}
        validationSchema={membershipRenewalSchema}
        validateOnMount
        validateOnBlur
        validateOnChange
      />
    </LoadingContainer>
  );
};
