import React from 'react';
import { FlatList } from '@codehesion-za/headless';

import { ContactBlockProps } from './types';

export const ContactBlock: React.FC<ContactBlockProps> = ({ title, contactNumbers }) => {
  const renderItemComponent = ({ item, index }: { item: string; index: number }) => (
    <li key={index}>{item}</li>
  );

  return (
    <div className="pl-4">
      <p className="mb-1">{title}:</p>
      <div className="pl-5 text-sm">
        <ul className="list-disc">
          <FlatList items={contactNumbers} renderItem={renderItemComponent} />
        </ul>
      </div>
    </div>
  );
};
