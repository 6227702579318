import dayjs from 'dayjs';

import { MeetingCardProps } from './types';
import { Card } from '@project/components/atoms';

export const MeetingCard: React.FC<MeetingCardProps> = ({ name, date, navFunction, type }) => {
  const dateStyling = 'font-bask text-white font-semibold text-2xl px-2';
  const detailsStyling = 'mb-2 text-base font-light leading-loose md:text-xl';

  const eventType = type.charAt(0).toUpperCase() + type.slice(1);
  const truncatedName = name.slice(0, 20);

  return (
    <Card className="h-32 xl:w-1/2">
      <div className="flex h-full w-full flex-row">
        <div className="flex h-full w-16 flex-col rounded-tl rounded-bl bg-gold pt-8 text-center md:pt-6">
          <span className={dateStyling}>{dayjs(date).format('DD')}</span>
          <span className={dateStyling}>{dayjs(date).format('MMM')}</span>
        </div>
        <div className="flex w-full flex-col md:flex-row">
          <div className="flex w-full flex-col pl-8 pt-2 md:pt-6">
            {window.innerWidth >= 800 ? (
              <span className={detailsStyling}>{name}</span>
            ) : (
              <span className={detailsStyling}>{truncatedName}</span>
            )}
            <span className={detailsStyling}>{eventType}</span>
          </div>
          <div className="flex w-full min-w-fit items-end justify-end pb-2">
            <button
              className="mr-2 h-fit rounded bg-gold py-2 px-4 text-[10px] text-white xs:text-xs sm:px-4 md:mr-2 md:w-fit"
              onClick={navFunction}
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};
