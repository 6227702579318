import config from '../../../config';

const manualPaymentBaseUrl = `${config.apiUrl}/manual_payments`;

export default {
  createManualPaymentUrl: () => `${manualPaymentBaseUrl}`,
  getManualPaymentUrl: (id: string) => `${manualPaymentBaseUrl}/${id}`,
  uploadManualPaymentUrl: () => `${manualPaymentBaseUrl}/upload_proof_of_payment`,
  outstandingBalanceUrl: (referenceNumber: string, currency: string) =>
    `${manualPaymentBaseUrl}/outstanding_balance?reference_number=${referenceNumber}&currency=${currency}`,
};
