export function isVideoUrl(url: string): boolean {
  const videoExtensions = [
    '.mp4',
    '.avi',
    '.mov',
    '.wmv',
    '.flv',
    '.mkv',
    '.webm',
    '.mpeg',
    '.mpg',
  ];
  const lowercaseUrl = url.toLowerCase();
  return videoExtensions.some((ext) => lowercaseUrl.endsWith(ext));
}

export function toError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  } else if (typeof error === 'string') {
    return new Error(error);
  } else if (typeof error === 'object' && error !== null) {
    try {
      return new Error(JSON.stringify(error));
    } catch (jsonError) {
      return new Error('Unknown error');
    }
  } else {
    return new Error('Unknown error');
  }
}
