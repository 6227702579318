import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { PartnersArticles, PartnersSidePannel } from '@project/components';

export const PartnerHomePage: React.FC = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['getPaymentIntent']);
  }, []);

  return (
    <div className="grid flex-1 grid-cols-7 gap-y-10">
      <div className="col-span-7 mx-4 bg-white pt-10 lg:col-span-3 lg:pb-0 2xl:mx-8">
        <PartnersSidePannel />
      </div>
      <div className="col-span-7 bg-[#F5F5F5] pb-4 md:pl-4 lg:col-span-4">
        <PartnersArticles />
      </div>
    </div>
  );
};
