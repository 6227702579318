import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Form, ErrorObject } from '@codehesion-za/headless';

import { Button, TextField } from '@project/components/atoms';
import { userAuthService } from '@project/services';
import { ResetPasswordValuesProps } from './types';
import { resetPasswordSchema } from './schemas';
import { GAEvent, trackEvent } from '@project/services';

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    password: '',
    confirmPassword: '',
    token: new URLSearchParams(location.search).get('reset_password_token'),
  };

  const { mutateAsync } = useMutation(
    (formData: ResetPasswordValuesProps) => userAuthService.resetPassword(formData),
    {
      onSuccess: () => {
        trackEvent(GAEvent.PasswordResetSuccess);
        navigate({
          pathname: '/partner/auth/login',
          search: 'reset=successful',
        });
      },
      onError: (error: ErrorObject<typeof initialValues>) => {
        toast.error(error.message, { duration: 5000 });
        trackEvent(GAEvent.PasswordResetFailed);
      },
    },
  );

  const onSubmit = (formData: ResetPasswordValuesProps) => mutateAsync(formData);

  const FormComponents = ({
    isSubmitting,
    handleSubmit,
  }: FormikProps<ResetPasswordValuesProps>) => (
    <div className="auth-form-body">
      <TextField name="password" label="Password" type="password" />
      <TextField name="confirmPassword" label="Confirm Password" type="password" />
      <div className="pt-16 pb-32">
        <Button
          type="submit"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          style="w-full bg-gold rounded py-2 px-3 text-white font-semibold"
        >
          SAVE
        </Button>
      </div>
    </div>
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmitForm={onSubmit}
      validationSchema={resetPasswordSchema}
      render={FormComponents}
    />
  );
};
