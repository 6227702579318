import React, { useEffect, useMemo } from 'react';
import find from 'lodash/find';
import { toast } from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { FormError } from '@codehesion-za/headless';
import { useQuery } from '@tanstack/react-query';
import { BaseOption } from '@project/types';
import { useGetIsPartner } from '@project/queries';
import { IndustryField } from '@project/components/molecules';
import { PartnerRegisterationValues } from '@project/schemas';
import { Button, Dropdown, TextField } from '@project/components/atoms';
import { partnerService, donorCategoriesModel } from '@project/services';
import { CountryCode } from 'libphonenumber-js/core';
import { EMAIL_ALREADY_EXIST, FILL_REQUIRED_INPUTS, TOAST_DURATION } from '@project/helper';
import { getCode, getNames } from 'country-list';

const countries = getNames();

const countryOptions: BaseOption[] = countries
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);

const TITLE: BaseOption[] = [
  { label: 'Mr', value: 'mr' },
  { label: 'Miss', value: 'miss' },
  { label: 'Mrs', value: 'mrs' },
];

export const IndividualForm: React.FC = () => {
  const { setValues, touched, values, errors, setFieldValue, setErrors, setFieldTouched } =
    useFormikContext<PartnerRegisterationValues & { nameFirst: string; nameLast: string }>();

  const { data, isLoading } = useGetIsPartner(values.email);
  const { dateOfBirth = '' } = useMemo(() => ('dateOfBirth' in errors ? errors : {}), [errors]);

  const { data: donorCategories } = useQuery(
    ['donorCategories'],
    () => partnerService.getDonorCategories(),
    {
      select: (categoryData) => donorCategoriesModel(categoryData.donor_categories),
    },
  );

  const CATEGORY = donorCategories?.categories;

  const handleSelectedTitle: BaseOption = find(
    TITLE,
    (option) => values.type === 'individual' && option.value === values.title,
  ) ?? {
    label: '',
    value: '',
  };

  const handleSelectedCategory: BaseOption = find(CATEGORY, (option) => {
    return values.type === 'individual' && option.value === values.donorCategoryId;
  }) ?? {
    label: '',
    value: '',
  };

  const isFormFilled = () => {
    return (
      values.type === 'individual' &&
      !isEmpty(values.donorCategoryId) &&
      !isEmpty(values.title) &&
      !isEmpty(values.phoneNumber) &&
      !isEmpty(values.email) &&
      !isEmpty(values.country) &&
      !isEmpty(values.industry) &&
      !isEmpty(values.name) &&
      !isEmpty(values.dateOfBirth) &&
      !isEmpty(values.occupation)
    );
  };

  const goBack = () =>
    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: true,
      currentStep: '1/5',
    }));

  const isSubmitting = () => {
    if (data?.isPartner) {
      setErrors({ email: EMAIL_ALREADY_EXIST });
      return;
    }

    if (!isFormFilled()) {
      toast.error(FILL_REQUIRED_INPUTS, TOAST_DURATION);
      return;
    }

    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: false,
      showPasswordForm: true,
      currentStep: '3/5',
    }));
  };

  const handlePhoneInputChange = (value: string | undefined) => {
    if (value && isValidPhoneNumber(value)) {
      const parsedNumber = parsePhoneNumber(value?.toString() ?? '');
      setFieldValue('number', value);
      setFieldValue('phoneNumber', parsedNumber?.nationalNumber);
      setFieldValue('countryCode', parsedNumber?.country);
    }
  };

  const handlePhoneBlur = () => setFieldTouched('number', true);

  useEffect(() => {
    const name = `${values?.firstName ?? ''}${values?.lastName ?? ''}`;

    if (touched?.firstName || touched?.lastName) setFieldTouched('name', true);

    setFieldValue('name', name);
  }, [touched?.firstName, touched?.lastName, values?.firstName, values?.lastName]);

  return (
    <div className="relative mt-10 border-t-2 border-slate-300">
      <Button
        style="top-[-180px] absolute rounded bg-lightGrey p-1"
        onClick={goBack}
        variant="text"
      >
        <ArrowLeftIcon className="w-5 text-black" />
      </Button>
      <div className="mt-6">
        {CATEGORY && (
          <div className="mb-2">
            <Dropdown
              fieldName="donorCategoryId"
              options={CATEGORY}
              value={handleSelectedCategory}
              label="Select Category*"
            />
          </div>
        )}
        <Dropdown
          fieldName="title"
          options={TITLE}
          value={handleSelectedTitle}
          label="Select Title*"
        />
        <div className="mt-2" />
        <div className="mt-2 mb-2 flex w-full flex-row flex-wrap space-x-0 lg:flex-nowrap lg:space-x-2">
          <TextField
            className="w-full"
            name="firstName"
            type="text"
            placeholder="First Name"
            label="First Name"
            required
          />
          <TextField
            name="lastName"
            type="text"
            placeholder="Last Name"
            label="Last Name"
            required
          />
        </div>
        <span className="text-red-600">
          <FormError name={'name'} errorText={errors?.name} />
        </span>
        <TextField
          className="mt-0"
          name="dateOfBirth"
          type="date"
          placeholder="Date of Birth"
          label="Date of Birth"
          required
          errorText={!isEmpty(dateOfBirth) ? 'Please select date of birth' : ''}
        />
        <div className="mt-2 mb-2">
          <label htmlFor="phone">Phone Number*</label>
          <PhoneInput
            placeholder="Phone Number"
            defaultCountry={values.countryCode ? (values.countryCode as CountryCode) : 'US'}
            value={values.number}
            onBlur={handlePhoneBlur}
            onChange={handlePhoneInputChange}
            name="phoneNumber"
          />
          <span className="text-red-600">
            <FormError name={'number'} errorText={errors?.number} />
          </span>
        </div>
        <TextField
          className="mt-0 mb-2"
          name="email"
          type={'text'}
          placeholder="email@gmail.com"
          label="Email*"
        />
        <TextField
          className="mt-0 mb-2"
          name="occupation"
          type={'text'}
          placeholder="Add Occupation"
          label="Occupation*"
        />
        <Dropdown
          fieldName="country"
          options={countryOptions}
          value={{
            label: countryOptions.find((c) => c.value === values.country)?.label ?? '',
            value: values.country ?? '',
          }}
          label="Select Country*"
        />
        <IndustryField industryFieldName="industry" selectedIndustry={values.industry ?? ''} />
        <TextField
          className="mt-0 mb-2"
          name="nearestBranch"
          type="text"
          placeholder="Nearest Branch"
          label="Nearest Branch"
        />
        <TextField
          className="mt-0 mb-2"
          name="assistantName"
          type="text"
          placeholder="Assistant Name"
          label="Assistant Name"
        />
      </div>
      <div className="mt-5">
        <Button
          isDisabled={isLoading || !isFormFilled()}
          style={`${
            isFormFilled() ? 'bg-gold' : 'bg-gray-300'
          } text-white py-2.5 mx-auto w-full rounded-md`}
          onClick={isSubmitting}
        >
          NEXT
        </Button>
      </div>
    </div>
  );
};
