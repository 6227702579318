import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useCentreModal } from '@project/hooks';
import { ThankYou } from '@project/assets';

interface ThankYouMessagePageProps {
  handleOnClosed?: () => void;
}

export const ThankYouMessagePage: React.FC<ThankYouMessagePageProps> = ({ handleOnClosed }) => {
  const [CentreModal, isOpen, closeModal] = useCentreModal(true);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source') || null;

  const goBack = () => {
    if (handleOnClosed) {
      handleOnClosed();
      return;
    }
    if (source === 'logged_in') {
      navigate('/partner/home');
    }
    {
      navigate('/thank-you');
    }
  };

  return (
    <div>
      <CentreModal isOpen={isOpen} onClose={() => closeModal()} afterClose={() => goBack()}>
        <div className="flex flex-col items-center justify-center bg-gray-900 p-8 text-center text-gold">
          <h2 className="mb-4 text-2xl">Thank You for Donating.</h2>
          <h2 className="mb-4 text-2xl">God Bless!</h2>
          <img src={ThankYou} className="w-64 text-black" />
        </div>
      </CentreModal>
    </div>
  );
};
