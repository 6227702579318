import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  Button,
  LoadingContainer,
  PartnersIndividualGlobalDonationForm,
  PartnersIndividualSpecialDonationForm,
} from '@project/components';
import { userService } from '@project/services';

export const IndividualDonationTypeDonateForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const { data: individualDonationTypeInfo, isLoading } = useQuery(['donationType', id], () =>
    userService.getIndividualDonationType(id ?? ''),
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="grid h-screen lg:grid-cols-3">
        <div className="xl:p-10">
          <div className="mb-14 mt-16 flex flex-col xl:mt-8">
            <div className="ml-4 flex flex-row md:ml-10">
              <Button style="bg-gray-200 rounded" onClick={goBack}>
                <ChevronLeftIcon className="w-10" />
              </Button>
              <div className="ml-6 lg:ml-4 xl:ml-14">
                <p className="border-b-2 border-gold text-xl font-semibold md:text-2xl">
                  {individualDonationTypeInfo?.name}
                </p>
              </div>
            </div>
            <div className="ml-10 md:ml-32 lg:ml-0 lg:p-10">
              <img src={individualDonationTypeInfo?.logo} />
            </div>
          </div>
        </div>
        <div className="col-span-2 flex justify-center bg-partners-gray px-4 pb-20 pt-16 md:px-20 xl:px-44">
          {individualDonationTypeInfo?.name.toLowerCase().includes('global') ? (
            <PartnersIndividualGlobalDonationForm
              donationTypeName={individualDonationTypeInfo?.name ?? ''}
              formType={'global'}
            />
          ) : (
            <PartnersIndividualSpecialDonationForm
              donationTypeName={individualDonationTypeInfo?.name ?? ''}
              formType={'special'}
            />
          )}
        </div>
      </div>
    </LoadingContainer>
  );
};
