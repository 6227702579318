import React from 'react';
import { FormikProps } from 'formik';
import find from 'lodash/find';
import { Form } from '@codehesion-za/headless';
import { useMutation, useQuery } from '@tanstack/react-query';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { IndustryField, Button, Dropdown, TextField, ImageUploader } from '@project/components';
import { PartnersProfileValues } from './type';
import { partnersProfileSchema } from './schema';
import { partnerService, partnersProfileModel, donorCategoriesModel } from '@project/services';
import { BaseOption } from '@project/types';
import { getCode, getNames } from 'country-list';

const countries = getNames();

// Transform country data into a suitable format for Dropdown
const countryOptions: BaseOption[] = countries
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);
const TITLE = [
  { label: 'Mr', value: 'mr' },
  { label: 'Miss', value: 'miss' },
  { label: 'Mrs', value: 'mrs' },
];

export const ProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const { data: partnersProfileInfo } = useQuery(
    ['partnersProfileInfo'],
    () => partnerService.getCurrentPartner(),
    {
      select: (data) => {
        return partnersProfileModel(data);
      },
    },
  );

  const { mutateAsync } = useMutation(
    (data: PartnersProfileValues) => partnerService.editCurrentPartner(data),
    {
      onSuccess: () => {
        toast.success('You have successfully updated your profile information');
        navigate(0);
      },
      onError: () => {
        toast.error('An error has occured while trying to update your profile');
      },
    },
  );

  const { data: donorCategories } = useQuery(
    ['donorCategories'],
    () => {
      return partnerService.getDonorCategories();
    },
    {
      select: (data) => {
        return donorCategoriesModel(data.donor_categories);
      },
    },
  );

  const CATEGORY = donorCategories?.categories ?? [];

  const handleOnSubmit = (formData: PartnersProfileValues) => {
    return mutateAsync(formData);
  };

  const getAccountType = (membershipType: string | undefined) => {
    switch (membershipType) {
      case 'individual':
        return 'Individual Account';
      case 'family':
        return 'Family Account';
      case 'organisation':
        return 'Organisation Account';
      default:
        return '';
    }
  };

  const FormComponents = ({
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values,
  }: FormikProps<PartnersProfileValues>) => {
    const handleSelectedTitle: BaseOption = find(
      TITLE,
      (option) => option.value === values.title,
    ) ?? {
      label: '',
      value: '',
    };

    const handleSelectedCategory: BaseOption = find(CATEGORY, (option) => {
      return option.value === values.donorCategoryId;
    }) ?? {
      label: '',
      value: '',
    };

    const handleEditProfile = () => {
      setFieldValue('editMode', true);
    };

    const handleSaveChanges = () => {
      setFieldValue('editMode', false);
      handleSubmit();
    };

    return (
      <div className="my-14">
        <div className="grid grid-cols-12">
          <div className="col-span-4">
            <p>Person Details</p>
            <div className="w-28 border border-gray-300" />
            <p className="mt-2 text-sm text-gray-600">
              {getAccountType(partnersProfileInfo?.membershipType)}
            </p>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-12">
          <ImageUploader
            fieldName="profileImage"
            selectedFile={values.profileImage}
            promptText="Please select an image from your device."
            isEditable={values?.editMode}
          />
        </div>
        <div className="mb-4 mt-8 xl:mb-0 2xl:mb-32">
          {partnersProfileInfo?.membershipType === 'individual' && values?.editMode && (
            <div className="pb-6">
              <Dropdown
                fieldName="donorCategoryId"
                options={CATEGORY}
                value={handleSelectedCategory}
                label={'Select Category'}
              />
            </div>
          )}
          {partnersProfileInfo?.membershipType === 'individual' && !values?.editMode && (
            <div className="pb-6">
              <p>Category</p>
              <p className="rounded border border-gray-300 bg-white py-2 pl-2">
                {partnersProfileInfo?.donorCategoryName}
              </p>
            </div>
          )}
          {partnersProfileInfo?.membershipType === 'individual' && values?.editMode && (
            <div className="pb-6">
              <Dropdown
                fieldName="title"
                options={TITLE}
                value={handleSelectedTitle}
                label={'Select Title'}
              />
            </div>
          )}
          {partnersProfileInfo?.membershipType === 'individual' && !values?.editMode && (
            <div className="pb-6">
              <p>Title</p>
              <p className="rounded border border-gray-300 bg-white py-2 pl-2">
                {partnersProfileInfo?.title}
              </p>
            </div>
          )}
          {partnersProfileInfo?.membershipType === 'individual' && (
            <TextField
              className="mt-2 mb-6"
              name="name"
              type={'text'}
              placeholder="Name Surname"
              label="Full Name"
            />
          )}
          {partnersProfileInfo?.membershipType === 'individual' && values?.editMode && (
            <TextField
              className="mt-2 mb-6"
              name="birthday"
              type="date"
              placeholder="Date of Birth"
              label="Date of Birth"
            />
          )}
          {partnersProfileInfo?.membershipType === 'individual' && !values?.editMode && (
            <div className="pb-6">
              <p>Date of Birth</p>
              <p className="rounded border border-gray-300 bg-white py-2 pl-2">
                {dayjs(partnersProfileInfo?.birthday).format('DD MMMM YYYY')}
              </p>
            </div>
          )}
          {partnersProfileInfo?.membershipType === 'family' && (
            <TextField
              className="mt-2 mb-6"
              name="name"
              type={'text'}
              placeholder="Name"
              label="Family Name"
            />
          )}
          {partnersProfileInfo?.membershipType === 'organisation' && (
            <TextField
              className="mt-2 mb-6"
              name="name"
              type={'text'}
              placeholder="Name"
              label="Organisation*"
            />
          )}
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <PhoneInput
              placeholder="Phone Number"
              // TODO: Add country code in values  to set defaultCountry
              defaultCountry="IN"
              value={values.phoneNumber}
              onChange={(value) => {
                const parsedNumber = parsePhoneNumber(value?.toString() ?? '');
                setFieldValue('number', parsedNumber?.number);
                setFieldValue('phoneNumber', parsedNumber?.number);
                setFieldValue('countryCode', parsedNumber?.country);
              }}
              name="phoneNumber"
            />
          </div>
          <TextField
            className="mt-2 mb-6"
            name="email"
            type={'text'}
            placeholder="email@gmail.com"
            label="Email"
          />
          <TextField
            className="mt-2 mb-6"
            name="occupation"
            type={'text'}
            placeholder="Add Occupation"
            label="Occupation"
          />
          <TextField
            className="mt-2 mb-3"
            name="nearestBranch"
            type="text"
            placeholder="Nearest Branch"
            label="Nearest Branch"
          />
          {!values?.editMode && <p className="mt-2 mb-3">Country</p>}
          <Dropdown
            fieldName="country"
            options={countryOptions}
            value={{
              label: countryOptions.find((c) => c.value === values.country)?.label ?? '',
              value: values.country ?? '',
            }}
            label={values?.editMode ? 'Select Country' : ''}
          />
          {values?.editMode && (
            <IndustryField industryFieldName="industry" selectedIndustry={values.industry ?? ''} />
          )}
          {partnersProfileInfo?.membershipType === 'individual' && !values?.editMode && (
            <div className="pt-6">
              <p>Industry</p>
              <p className="rounded border border-gray-300 bg-white py-2 pl-2">
                {partnersProfileInfo?.industry}
              </p>
            </div>
          )}
        </div>
        {values?.editMode ? (
          <Button
            style={`bg-gold text-white py-2.5 mx-auto w-full rounded-md my-8`}
            onClick={handleSaveChanges}
            isLoading={isSubmitting}
          >
            SAVE CHANGES
          </Button>
        ) : (
          <Button
            style={`bg-gold text-white py-2.5 mx-auto w-full rounded-md my-8`}
            onClick={handleEditProfile}
          >
            EDIT PROFILE
          </Button>
        )}
      </div>
    );
  };

  return (
    <Form
      initialValues={{
        id: partnersProfileInfo?.id,
        name: partnersProfileInfo?.name ?? '',
        birthday: partnersProfileInfo?.birthday ?? '',
        phoneNumber: partnersProfileInfo?.phoneNumber ?? '',
        country: partnersProfileInfo?.country ?? '',
        donorCategoryId: partnersProfileInfo?.donorCategoryId ?? '',
        title: partnersProfileInfo?.title ?? '',
        occupation: partnersProfileInfo?.occupation ?? '',
        industry: partnersProfileInfo?.industry ?? '',
        membershipType: partnersProfileInfo?.membershipType ?? '',
        email: partnersProfileInfo?.email ?? '',
        editMode: false,
        profileImage: partnersProfileInfo?.profileImage ?? '',
        nearestBranch: partnersProfileInfo?.nearestBranch ?? '',
      }}
      onSubmitForm={handleOnSubmit}
      onFailure={() => null}
      render={FormComponents}
      validationSchema={partnersProfileSchema}
    />
  );
};
