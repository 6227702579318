import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { RegisterToBecomePatnerFirstSceen } from '@project/components';
import { partnerRegisterationSchema } from '@project/schemas';
import { ProgressBar } from '@project/components/atoms';
import { PartnerLayout } from '@project/components';
import { Logo } from '@project/assets';
import {
  FamilyForm,
  IndividualForm,
  OrganisationForm,
  PartnersBillingForm,
  PartnersCardholderForm,
  PartnersCreatePasswordForm,
} from '@project/components/organisms';
import config from '@project/config';
import { GeocodingDataType, geocodingService } from '@project/services';
import { ActivityLoader } from '@project/components';

const stripe = loadStripe(config.stripePublishableKey);

const formInitialValues = {
  type: 'organisation',
  showProfileType: true,
  showNextForm: false,
  currentStep: '1/5',
  showCardHolderForm: false,
  showBillingForm: false,
  showPasswordForm: false,
  stripeClientSecret: '',
  stripeId: '',
  intentId: '',
  donationType: 'partner_registration',
  donationCurrency: 'USD',
  donationAmount: 1,
  name: '',
  email: '',
  number: '',
  countryCode: '',
  phoneNumber: '',
  country: '',
  street1: '',
  street2: '',
  region: '',
  townCity: '',
  postcodeZip: '',
  industry: '',
  newPassword: '',
  confirmPassword: '',
  title: '',
  occupation: '',
  dateOfBirth: '',
  firstName: '',
  lastName: '',
  termsAndConditions: false,
  recaptcha: false,
};

export const PartnersRegisterationPage: React.FC = () => {
  const onSubmitHandler = () => undefined;

  const trackSteps = ['1/5', '2/5', '3/5', '4/5', '5/5'];
  const billingStep = '4/5';
  const passwordStep = '5/5';

  const [loadingGeoData, setLoadingGeoData] = useState<boolean>(false);

  return (
    <PartnerLayout>
      <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmitHandler}
        validationSchema={toFormikValidationSchema(partnerRegisterationSchema)}
      >
        {({ values, setValues }) => {
          useEffect(() => {
            setLoadingGeoData(true);
            geocodingService
              .getUserGeocodingData()
              .then((data: GeocodingDataType | null) => {
                if (data) {
                  setValues((previous) => ({
                    ...previous,
                    street1: data.street,
                    countryCode: data.countryCode.toUpperCase(),
                    country: data.country,
                    region: data.state,
                    townCity: data.town,
                    postcodeZip: data.postalCode,
                    donationCurrency: data.currency.code.toUpperCase(),
                  }));
                }
                setLoadingGeoData(false);
              })
              .catch(() => {
                setLoadingGeoData(false);
              });
          }, []);

          return (
            <div
              className={`mx-auto flex flex-col rounded-md bg-white px-10 py-5 ${
                billingStep === values.currentStep && !values.showCardHolderForm
                  ? 'md:w-4/5 lg:w-[85%] xl:w-[90%] 2xl:w-[80%]'
                  : 'md:w-4/5 xl:w-2/4'
              }`}
            >
              <div className="flex justify-center">
                <img className="w-[200px]" src={Logo} />
              </div>
              <p className="mb-10 text-center text-xl font-semibold">
                {(() => {
                  if (values.showCardHolderForm) {
                    return 'Register To Become A Partner';
                  } else if (values.showBillingForm) {
                    return 'Payments';
                  } else if (billingStep === values.currentStep) {
                    return 'Payments';
                  } else if (passwordStep === values.currentStep) {
                    return 'Create A Password';
                  } else {
                    return 'Register To Become A Partner';
                  }
                })()}
              </p>

              <ProgressBar completed={values.currentStep} steps={trackSteps} />
              <ActivityLoader centered isLoading={loadingGeoData} />
              {!loadingGeoData && (
                <>
                  {values.showProfileType && <RegisterToBecomePatnerFirstSceen />}
                  {values.showNextForm && (
                    <div>{values?.type === 'organisation' && <OrganisationForm />}</div>
                  )}
                  {values.showNextForm && <div>{values.type === 'family' && <FamilyForm />}</div>}
                  {values.showNextForm && (
                    <div>{values.type === 'individual' && <IndividualForm />}</div>
                  )}
                  {values.showPasswordForm && <PartnersCreatePasswordForm />}
                  {values.showCardHolderForm && <PartnersCardholderForm />}
                  {values.showBillingForm &&
                    !isEmpty(values.stripeClientSecret) &&
                    !isEmpty(values.stripeId) && (
                      <Elements
                        stripe={stripe}
                        options={{ clientSecret: values.stripeClientSecret }}
                      >
                        <PartnersBillingForm />
                      </Elements>
                    )}
                </>
              )}
            </div>
          );
        }}
      </Formik>
    </PartnerLayout>
  );
};
