import { UploadProofOfPaymentType } from './types';

export const ProofOfPaymentDto = (data: UploadProofOfPaymentType): FormData => {
  const formData = new FormData();

  formData.append('manual_payment[reference_number]', data.referenceNumber);
  formData.append('manual_payment[user_bank]', data.userBank);
  formData.append('manual_payment[amount_to_pay]', data.amountToPay.toString());
  formData.append('manual_payment[amount_to_pay_currency]', data.amountToPayCurrency);
  formData.append('manual_payment[proof_of_payment]', data.proofOfPayment);

  return formData;
};
