import config from '../../../config';

const indemnityFormBaseUrl = `${config.apiUrl}/events/submit_indemnity_form`;
const questionUrl = (id: number) => `${config.apiUrl}/indemnity_forms/${id}/questions`;
const answerUrl = (id: number) => `${config.apiUrl}/indemnity_forms/${id}/answers`;

export default {
  submitIndemnityFormUrl: () => indemnityFormBaseUrl,
  questionUrl: (id: number) => questionUrl(id),
  answerUrl: (id: number) => answerUrl(id),
};
