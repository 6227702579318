import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface EventsStore {
  eventType: string;
  indemnityFormId: number;
  set: (data: Partial<EventsStore>) => void;
  setEventType: (eventType: string) => void;
  setindemnityFormId: (indemnityFormId: number) => void;
}

export const useEventsStore = create<EventsStore>()(
  persist(
    (set) => ({
      eventType: '',
      indemnityFormId: 0,
      set,
      setEventType: (eventType) => set({ eventType }),
      setindemnityFormId: (indemnityFormId) => set({ indemnityFormId }),
    }),
    {
      name: 'eventsStore',
    },
  ),
);
