import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import React from 'react';

import { ProgressBarProps } from './types';

export const ProgressBar: React.FC<ProgressBarProps> = ({ completed, steps }) => {
  const completedIndex = completed
    ? findIndex(steps, (item) => includes(toUpper(item), toUpper(completed.toString()))) + 1
    : 0;

  const percentageCompleted = Math.round((completedIndex / steps.length) * 100).toString();

  const completedStyle = '-m-3 h-6 w-6 rounded-full border-2 border-gold bg-gold';
  const emptyStyle = '-m-3 h-6 w-6 rounded-full border-2 border-[#CCCCCC] bg-white';
  const progressStyling = 'absolute h-1 rounded-full bg-gold text-center leading-none text-black';

  return (
    <div className="mx-4 md:mx-auto md:w-[75%]">
      <div className="relative flex items-center justify-between rounded-full border-2 border-[#CCCCCC] bg-gray-200">
        {steps.map((step, index) => {
          return completed == 'return' ? (
            <div
              key={index}
              className={steps.length > index ? `${completedStyle}` : `${emptyStyle}`}
            />
          ) : (
            <div
              key={index}
              className={completedIndex > index ? `${completedStyle}` : `${emptyStyle}`}
            />
          );
        })}
        {completed == 'return' ? (
          <div className={progressStyling} style={{ width: `100%` }} />
        ) : (
          <div className={progressStyling} style={{ width: `${percentageCompleted}%` }} />
        )}
      </div>
      <div className="mt-8 flex justify-between">
        {steps.map((step, index) => {
          return (
            <div
              key={index}
              className="font-poppins -mx-8 -mt-3 w-[25%] text-center text-xs sm:w-[20%] lg:w-[15%] xl:w-[20%] 2xl:w-[16%]"
            >
              {step}
            </div>
          );
        })}
      </div>
    </div>
  );
};
