import {
  BillingPage,
  CardholderPage,
  DonationPage,
  HomePage,
  MeetingContributionsPage,
  MembershipRenewalPage,
  ProofofPaymentPage,
  ThankYouMessagePage,
  WebLayout,
  MeetingsListPage,
  RetreatPage,
  AboutUsPage,
  IndemityPage,
  PartnersRegisterationPage,
  ContactSection,
  DonationDetailsPage,
  TermsAndConditionsPage,
  CharityOutReach,
  IndividualCountryCharities,
  DeclinePage,
  IndividualCharityView,
  TermsAndConditionsMobile,
  ThankYouPage,
  IndemnityFormCompletionPage,
} from '@project/components';

export const WebRouter = [
  {
    path: '/',
    element: <WebLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/meetings',
        element: <MeetingsListPage />,
      },
      {
        path: '/meeting-contributions/:id',
        element: <MeetingContributionsPage />,
      },
      {
        path: '/retreat/:id',
        element: <RetreatPage />,
      },
      {
        path: '/special-donations',
        element: <DonationPage formType="special" />,
      },
      {
        path: '/global-donations',
        element: <DonationPage formType="global" />,
      },
      {
        path: '/payment',
        element: <BillingPage />,
      },
      {
        path: '/membership-renewal',
        element: <MembershipRenewalPage />,
      },
      {
        path: '/upload-proof-of-payment',
        element: <ProofofPaymentPage />,
      },
      {
        path: '/card-holders',
        element: <CardholderPage />,
      },
      {
        path: '/membership-renewal',
        element: <MembershipRenewalPage />,
      },
      {
        path: '/about-kfm',
        element: <AboutUsPage />,
      },
      {
        path: '/indemnity-page/:id',
        element: <IndemityPage />,
      },
      {
        path: '/register-partner',
        element: <PartnersRegisterationPage />,
      },
      {
        path: '/contact-us/:type',
        element: <ContactSection />,
      },
      {
        path: '/donation-details/:id',
        element: <DonationDetailsPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: '/terms-and-conditions-mobile',
        element: <TermsAndConditionsMobile />,
      },
      {
        path: '/charity-outreach',
        element: <CharityOutReach />,
      },
      {
        path: '/individual-country-charities/:id',
        element: <IndividualCountryCharities />,
      },
      {
        path: '/individual-charity-view/:id/:countryId',
        element: <IndividualCharityView />,
      },
      {
        path: '/thank-you',
        element: <ThankYouPage />,
      },
      {
        path: '/indemnity-form-completion',
        element: <IndemnityFormCompletionPage />,
      },
    ],
  },
];

export const WebModalRouter = [
  {
    path: '/thank-you-modal',
    element: <ThankYouMessagePage />,
  },
  {
    path: '/decline',
    element: <DeclinePage />,
  },
];
