import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import map from 'lodash/map';
import { Carousel } from 'react-responsive-carousel';
import { Dialog } from '@headlessui/react';

import { Button, TopImage } from '@project/components';
import { CharityData } from '@project/helper';

export const IndividualCharityView: React.FC = () => {
  const navigate = useNavigate();
  const { id, countryId } = useParams();
  const data = CharityData;
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const handleIsOpen = (index: number) => {
    setActiveImage(index);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const filteredItem = data?.find((item) => item.countryId === countryId);

  const charityInfo = filteredItem?.charities.find((charity) => charity?.id === id);

  const ImageModal = useCallback(
    () => (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="fixed inset-10 mx-auto my-auto hidden items-center justify-center md:flex"
      >
        <div className="fixed inset-0 flex overflow-y-auto bg-black/80">
          <div className="m-auto flex items-center justify-center">
            <Dialog.Panel className="h-[400px] w-[500px] overflow-y-auto">
              <Carousel
                autoPlay={false}
                infiniteLoop={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows
                selectedItem={activeImage}
              >
                {map(charityInfo?.charityImages, (src, index) => (
                  <img src={src} alt="Expanded image" key={index} />
                ))}
              </Carousel>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    ),
    [isOpen, activeImage],
  );

  const goBack = () => navigate(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mb-20">
      <TopImage />
      <div className="flex flex-col">
        <div className="mt-20 mb-14 md:mx-10 lg:my-14">
          <div className="flex flex-row items-center">
            <Button style="bg-gray-200 rounded md:ml-2 ml-4" onClick={goBack}>
              <ChevronLeftIcon className="w-8" />
            </Button>
            <div className="ml-4 text-xl font-medium underline decoration-gold decoration-2 underline-offset-4 md:text-2xl">
              {charityInfo?.charityName}
            </div>
          </div>
          <p className="mx-6 mt-20 text-lg leading-loose tracking-wide md:mx-14 md:mt-14 md:text-justify xl:mx-[10%]">
            {charityInfo?.charityDetails}
          </p>
        </div>
        <div className="mt-6 flex flex-row flex-wrap justify-center bg-black p-2 md:p-10">
          {map(charityInfo?.charityImages, (item, index) => (
            <div className="m-4 flex flex-row lg:mx-6">
              <img
                key={index}
                onClick={() => handleIsOpen(index)}
                className="w-[300px] border-y-2 border-gold object-cover py-6"
                src={item}
              />
            </div>
          ))}
        </div>
        <ImageModal />
      </div>
    </div>
  );
};
