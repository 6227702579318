import z from 'zod';

import { commonSchemas } from '@project/schemas';

export const UploadProofOfPaymentSchema = z.object({
  referenceNumber: z.string(),
  userBank: z.string(),
  proofOfPayment: commonSchemas.file,
  amountToPayCurrency: z.string(),
  amountToPay: z.number(),
});
