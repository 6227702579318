import { toast } from 'react-hot-toast';
import { useFormikContext } from 'formik';
import 'react-phone-number-input/style.css';
import { Checkbox } from '@codehesion-za/headless';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { RegionDropdown } from 'react-country-region-selector';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { PartnerRegisterationValues } from '@project/schemas';
import { CardHolderIcon } from '@project/assets';
import {
  Button,
  ConfirmParamsModel,
  TextField,
  BillingFormModel,
  ThankYouMessagePage,
  Dropdown,
  BillingValues,
  RadioGroup,
} from '@project/components';
import './billing-form.css';
import { useState } from 'react';
import { CountryCode } from 'libphonenumber-js/core';
import {
  PAYMENT_INTENT_SUCCESS,
  PAYMENT_PROCESSING_ERROR,
  REFERENCE_NUMBER_SENT,
  TOAST_DURATION,
} from '@project/helper';
import { StripeError } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { getCode, getNames } from 'country-list';
import { BaseOption } from '@project/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBillingDetailsStore } from '@project/stores';
import { useMutation } from '@tanstack/react-query';
import { CreateManualPaymentApi, manualPaymentService } from '@project/services';
import { Transition } from '@headlessui/react';
import { isValid } from 'zod';
import { GAEvent, trackEvent } from '@project/services';

const countries = getNames();

// Transform country data into a suitable format for Dropdown
const countryOptions: BaseOption[] = countries
  .map((country: string) => ({
    label: country,
    value: getCode(country) || '',
  }))
  .filter((option) => option.value);

const headerStyling = 'mb-6 text-xl font-semibold tracking-wide';

export const PartnersBillingForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const navigate = useNavigate();
  const { setValues, values, errors, setFieldValue, isSubmitting, touched, setTouched } =
    useFormikContext<PartnerRegisterationValues>();

  const [isLoading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const billingDetailsStore = useBillingDetailsStore();
  const goBack = () =>
    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: false,
      showCardHolderForm: true,
      showBillingForm: false,
      currentStep: '3/5',
    }));

  const handleThankYouMessageOnClose = () => {
    setShowSuccessModal(false);
    setValues((previous) => ({
      ...previous,
      showNextForm: false,
      showProfileType: false,
      showCardHolderForm: false,
      showBillingForm: false,
      showPasswordForm: false,
      currentStep: '5/5',
    }));
    navigate('/partner/auth/login');
  };

  const isFormFilled = () =>
    values.firstName &&
    values.lastName &&
    values.email &&
    values.countryCode &&
    values.phoneNumber &&
    values.street1 &&
    values.country &&
    values.region &&
    values.townCity &&
    values.postcodeZip &&
    values.termsAndConditions;

  const goToUploadPop = () => navigate('/upload-proof-of-payment');

  const { mutateAsync: createManualPayment, isLoading: isManualPaymentLoading } = useMutation(
    (user: BillingValues) => manualPaymentService.createManualPayment(values.stripeId, user),
    {
      onSuccess: (data: CreateManualPaymentApi) => {
        toast.success(REFERENCE_NUMBER_SENT, TOAST_DURATION);
        navigate(`/donation-details/${data.id}`);
      },
      onError: (error) => {
        Sentry.captureException(error);
        toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
      },
    },
  );

  const onSubmitForm = async () => {
    if (values.paymentMethod === 'debit_credit') {
      trackEvent(GAEvent.RegistrationPaymentAttempt);

      if (!stripe || !elements) {
        toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
        trackEvent(GAEvent.RegistrationPaymentFailed);
        return;
      }

      const result = await elements?.submit();
      if (result.error) {
        trackEvent(GAEvent.RegistrationPaymentFailed);
        toast.error(result.error?.message || PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
        return;
      }

      try {
        setErrorMessage(null);
        setLoading(true);

        const { paymentIntent, error } = await stripe.confirmPayment({
          elements,
          confirmParams: ConfirmParamsModel(BillingFormModel(values)),
          redirect: 'if_required',
        });

        if (paymentIntent && paymentIntent.status === PAYMENT_INTENT_SUCCESS) {
          setShowSuccessModal(true);
          trackEvent(GAEvent.RegistrationPaymentSuccess);
          // Redirect to login page after a successful payment
          setTimeout(() => navigate('/partner/auth/login'), 3000);
        } else {
          setErrorMessage(error?.message || PAYMENT_PROCESSING_ERROR);
          toast.error(error?.message || PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
          Sentry.captureException(error);
        }
      } catch (e) {
        setErrorMessage(PAYMENT_PROCESSING_ERROR);
        toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
        trackEvent(GAEvent.RegistrationPaymentFailed);
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    } else if (values.paymentMethod === 'manual_payment') {
      await createManualPayment(BillingFormModel(values));
      billingDetailsStore.set({ values: BillingFormModel(values) });
      return;
    }
  };

  const handlePhoneInputChange = (value: string | undefined) => {
    if (value && isValidPhoneNumber(value)) {
      const parsedNumber = parsePhoneNumber(value?.toString() ?? '');
      setFieldValue('number', value);
      setFieldValue('phoneNumber', parsedNumber?.nationalNumber);
      setFieldValue('countryCode', parsedNumber?.country);
    }
  };

  const errorStyling = (field: string | number | undefined, isTouched = false) =>
    `${isTouched && field ? 'border-red-600' : ''}`;

  const onPaymentElementLoadError = (stripeEvent: {
    elementType: 'payment';
    error: StripeError;
  }) => {
    toast.error(PAYMENT_PROCESSING_ERROR, TOAST_DURATION);
    Sentry.captureException(stripeEvent.error);
  };

  return (
    <div className="relative">
      <Button
        style="top-[-150px] absolute rounded bg-lightGrey p-1"
        onClick={goBack}
        variant="text"
      >
        <ArrowLeftIcon className="w-5 text-black" />
      </Button>
      {showSuccessModal && <ThankYouMessagePage handleOnClosed={handleThankYouMessageOnClose} />}
      <div className="mt-14 mb-5 flex flex-col border-t-2 border-lightGrey md:flex-row">
        <div className="mt-5 flex-1 sm:pr-4 md:pr-10 lg:pr-10 ">
          <div>
            <h1 className={headerStyling}>Billing Details</h1>
            <div className="mb-6">
              <TextField
                className={errorStyling(errors?.firstName, touched.firstName)}
                name="firstName"
                label="First Name"
                type="text"
                placeholder="Name"
                value={values.firstName}
                required
              />
            </div>
            <div className="mb-6">
              <TextField
                className={errorStyling(errors?.lastName, touched?.lastName)}
                name="lastName"
                label="Last Name"
                type="text"
                placeholder="Last Name"
                value={values.lastName}
                required
              />
            </div>
            <div className="mb-6">
              <TextField
                className={errorStyling(errors?.email, touched?.email)}
                name="email"
                label="Email"
                type="text"
                placeholder="email@address.com"
                value={values.email}
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="country">Phone Number *</label>
              <PhoneInput
                className={`mt-1 ${errors?.phoneNumber ? 'rounded-lg' : ''} ${errorStyling(
                  errors?.phoneNumber,
                  touched?.phoneNumber,
                )}`}
                placeholder="Phone Number"
                defaultCountry={values.countryCode ? (values.countryCode as CountryCode) : 'US'}
                value={values.number}
                onChange={handlePhoneInputChange}
                name="phoneNumber"
              />
              <span className="text-sm text-red-600">{errors.number}</span>
            </div>
            <div className="mb-6">
              <TextField
                className={`${errorStyling(errors?.street1, touched?.street1)}`}
                name="street1"
                label="Street Address"
                type="text"
                placeholder="Address"
                value={values.street1}
                required
              />
              <TextField
                name="street2"
                label=""
                type="text"
                placeholder="Apartment, suite, unit, etc. (Optional)"
                value={values.street2}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="country">Country / Region</label>
              <div
                className={`${errors?.country ? 'rounded-lg border' : ''} ${errorStyling(
                  errors?.country,
                  touched?.country,
                )}`}
              >
                <Dropdown
                  fieldName="country"
                  options={countryOptions}
                  value={{
                    label: countryOptions.find((c) => c.value === values.country)?.label ?? '',
                    value: values.country ?? '',
                  }}
                  label=""
                />
              </div>
              {errors?.country && touched?.country && (
                <span className="text-red-600">{errors?.country}</span>
              )}
            </div>
            <div className="mb-6">
              <label htmlFor="region">Province / State</label>
              <div
                className={`${
                  errors?.region && touched?.region && !values.region ? 'rounded-lg border' : ''
                } ${errorStyling(errors?.region, touched?.region)}`}
              >
                <RegionDropdown
                  id="region"
                  name="region"
                  country={values.country ?? ''}
                  value={values.region ?? ''}
                  onChange={(value) => {
                    setFieldValue('region', value);
                    setTouched({ ...touched, region: true });
                  }}
                  onBlur={() => {
                    if (!values.region) {
                      setTouched({ ...touched, region: true });
                    }
                  }}
                  countryValueType="short"
                />
              </div>
              {errors?.region && touched?.region && !values.region && (
                <span className="text-red-600">{errors?.region}</span>
              )}
            </div>
            <div className="mb-6">
              <TextField
                className={errorStyling(errors?.townCity, touched?.townCity)}
                name="townCity"
                label="Town / City"
                type="text"
                placeholder="City"
                required
              />
            </div>
            <div>
              <TextField
                className={errorStyling(errors?.postcodeZip, touched?.postcodeZip)}
                name="postcodeZip"
                label="Postcode / Zip"
                type="text"
                placeholder="Zip"
                required
              />
            </div>
          </div>
        </div>
        <div className="mt-5 flex-1 border-lightGrey md:border-l-2 md:pl-10 ">
          <div>
            <h1 className={headerStyling}>Your Donation</h1>
            <div className="mb-3 flex space-x-5">
              <img src={CardHolderIcon} />
              <p className="my-auto text-xs">Partner Registration</p>
            </div>
            <div className="h-0.5 bg-gray-200"></div>
            <div className="flex justify-between font-semibold">
              <p>Total</p>
              <p>
                {values.donationCurrency} {values.donationAmount.toFixed(2)}
              </p>
            </div>
            <div>
              {location.state !== 'manual' && (
                <>
                  <RadioGroup
                    name="paymentMethod"
                    id="stripe_payment"
                    label="Debit / Credit Card Payment"
                    value="debit_credit"
                    options={[]}
                  />
                  <Transition
                    show={values.paymentMethod === 'debit_credit'}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="mb-4">
                      <p className="text-xs">Pay with your card via Stripe payments.</p>
                      <PaymentElement onLoadError={onPaymentElementLoadError} />
                    </div>
                  </Transition>
                </>
              )}
              <>
                <RadioGroup
                  name="paymentMethod"
                  id="manual_payment"
                  label="Manual Payment"
                  value="manual_payment"
                />
                <Transition
                  show={values.paymentMethod === 'manual_payment'}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mb-4">
                    <p className="text-xs">
                      Once you select “complete payment” you will be directed to a page where all
                      KFMI banking details are listed. Once you have made the payment, you can
                      upload your proof of payment{' '}
                      <span
                        className="cursor-pointer text-blue-600 underline"
                        onClick={goToUploadPop}
                      >
                        here
                      </span>
                      .
                    </p>
                  </div>
                </Transition>
              </>
              <div className="h-0.5 bg-gray-200"></div>
              <p className="w-full py-5 text-left text-xs">
                Your personal data will be used to process your order, support your experience
                throughout this website, and for other purposes described in our terms and
                conditions.
              </p>
              <div className="mb-4">
                <Checkbox id="termsAndConditions" name="termsAndConditions" className="text-gold" />
                <label htmlFor="termsAndConditions" className="ml-2 text-end text-xs">
                  I have read and agree to the
                  <a className="ml-1 hover:text-gold" href="/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </label>
                {errors?.termsAndConditions && (
                  <p className="text-xs text-red-600">{errors?.termsAndConditions}</p>
                )}
              </div>
              {errorMessage && <p className="text-xs text-red-600">{errorMessage}</p>}
              <Button
                isDisabled={isSubmitting || isLoading || !isValid || !isFormFilled()}
                style={`bg-${
                  !isValid ? 'gray-200' : 'gold'
                } text-white font-bold text-sm rounded-md py-3 w-full mt-5`}
                onClick={onSubmitForm}
              >
                {isSubmitting || isManualPaymentLoading ? 'Processing...' : 'Complete Payment'}
              </Button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
