import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import './theme/theme.css';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);
