import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopImage } from '@project/components';
import {
  BotswanaMain,
  CanadaMain,
  KenyaMain,
  MalawiMain,
  SouthAfricaMain,
  UKMain,
  Zim2,
} from '@project/assets';

export const CharityOutReach: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CharityBlocks = ({ src, title, id }: { src: string; title: string; id: string }) => {
    const navigate = useNavigate();

    const handleBlockClick = () => {
      navigate(`/individual-country-charities/${id}`);
    };

    return (
      <div className="flex cursor-pointer flex-col p-4 sm:p-10" onClick={handleBlockClick}>
        <div>
          <img className="w-[500px]" src={src} />
        </div>
        <div>
          <p
            className={`z-10 bg-white p-1 text-center font-semibold text-black shadow-sm shadow-gray-400`}
          >
            {title}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-32 flex flex-col">
      <TopImage />
      <div className="my-12 ml-4 md:ml-36">
        <p className="mb-2 text-3xl font-semibold tracking-wide">Charity Outreach</p>
        <div className="w-24 rounded border-2 border-gold" />
      </div>
      <div className="mx-auto grid lg:grid-cols-3">
        <CharityBlocks id="3" src={MalawiMain} title="MALAWI Outreach" />
        <CharityBlocks id="1" src={Zim2} title="ZIMBABWE Outreach" />
        <CharityBlocks id="4" src={BotswanaMain} title="BOTSWANA Outreach" />
      </div>
      <div className="mx-auto grid lg:grid-cols-3">
        <CharityBlocks id="6" src={CanadaMain} title="CANADA Outreach" />
        <CharityBlocks id="0" src={SouthAfricaMain} title="SOUTH AFRICA Outreach" />
        <CharityBlocks id="2" src={UKMain} title="UNITED KINGDOM Outreach" />
      </div>
      <div className="mx-auto grid lg:grid-cols-3">
        <CharityBlocks id="5" src={KenyaMain} title="KENYA Outreach" />
      </div>
    </div>
  );
};
