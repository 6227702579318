import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { IBillingDetailsStore } from './types';

export const useBillingDetailsStore = create<IBillingDetailsStore>()(
  persist(
    (set) => ({
      values: {
        firstName: '',
        lastName: '',
        email: '',
        number: '',
        phoneNumber: '',
        countryCode: '',
        country: '',
        region: '',
        townCity: '',
        street1: '',
        street2: '',
        postcodeZip: '',
        termsAndConditions: false,
      },
      set,
      setPartnersBillingDetails: (data) => {
        const billingDetails = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          number: data.mobile,
          phoneNumber: data.mobile,
          countryCode: data.countryCode,
          country: data.countryCode,
          region: data.province,
          townCity: data.city,
          street1: data.street1,
          street2: data.street2,
          postcodeZip: data.zip,
          termsAndConditions: false,
        };

        set({ values: billingDetails });
      },
    }),
    {
      name: 'BillingDetailsStore',
    },
  ),
);
