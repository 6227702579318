import React from 'react';

import { TopperImage } from '@project/assets';

export const TopImage: React.FC = () => {
  return (
    <div className="h-full w-full">
      <img className="h-[60px] object-cover xs:h-[100px] md:h-full" src={TopperImage} />
    </div>
  );
};
