import map from 'lodash/map';

import { paginationModel } from '@project/models';
import { BaseOption } from '@project/types';
import {
  PartnersStats,
  PartnersProfile,
  PartnersArticles,
  PartnersStatsApi,
  PartnersProfileApi,
  PartnersArticlesApi,
  PartnersArticlesData,
  PartnersArticlesDataApi,
  PartnersDonationSummary,
  PartnersDonationSummaryAPI,
  PartnersDonationSummaryData,
  PartnersDonationSummaryDataApi,
  PartnersIndividualDonationSummary,
  PartnersIndividualDonationSummaryApi,
  PartnersIndividualDonationSummaryData,
  PartnersIndividualDonationSummaryApiData,
  DonorCategoryApi,
  DonorCategory,
} from './types';

export const partnersStatsModel = (data: PartnersStatsApi): PartnersStats => ({
  id: data?.id ?? '',
  donations: data?.donations ?? '',
  membershipStatus: data?.membership_status ?? '',
  progress: data?.progress ?? '',
  upgradeAmount: data?.upgrade_amount ?? '',
  firstName: data?.first_name ?? '',
  isExpired: data?.is_expired ?? false,
});

export const partnersProfileModel = (data: PartnersProfileApi): PartnersProfile => ({
  id: data?.id,
  name: data?.name ?? '',
  birthday: data?.birthday ?? '',
  email: data?.email ?? '',
  phoneNumber: data?.phone_number ?? '',
  country: data?.country ?? '',
  donorCategoryId: data?.donor_category_id ?? '',
  donorCategoryName: data?.donor_category_name ?? '',
  title: data?.title ?? '',
  occupation: data?.occupation ?? '',
  industry: data?.industry ?? '',
  membershipType: data?.membership_type ?? '',
  profileImage: data?.profile_image ?? '',
  nearestBranch: data?.nearest_branch ?? '',
});

export const donorCategoryModel = (data: DonorCategoryApi): DonorCategory => ({
  value: data?.id?.toString(),
  label: data?.name ?? '',
});

export type DonorCategoriesData = {
  categories: BaseOption[];
};

export const donorCategoriesModel = (data?: DonorCategoryApi): DonorCategoriesData => ({
  categories: map(data ?? [], donorCategoryModel),
});

export const partnersDonationSummary = (
  data: PartnersDonationSummaryAPI,
): PartnersDonationSummary => ({
  id: data?.id ?? 0,
  donationName: data?.donation_name ?? '',
  logo: data?.logo ?? '',
  totalDonated: data?.total_donated ?? '',
  csvData: data?.csv_data ?? [],
});

export const partnersDonationSummaryDataModel = (
  data?: PartnersDonationSummaryDataApi,
): PartnersDonationSummaryData => ({
  donationType: map(data?.donation_types ?? [], partnersDonationSummary),
  pagination: paginationModel(data?.meta),
});

export const partnersArticlesModel = (data: PartnersArticlesApi): PartnersArticles => ({
  id: data?.id ?? 0,
  heading: data?.heading ?? '',
  author: data?.author ?? '',
  createdAt: data?.created_at ?? '',
  content: data?.content ?? '',
  featureImage: data?.feature_image ?? '',
});

export const partnersArticlesModelDataModel = (
  data?: PartnersArticlesDataApi,
): PartnersArticlesData => ({
  articles: map(data?.articles ?? [], partnersArticlesModel),
  pagination: paginationModel(data?.meta),
});

export const partnersIndividualDonationsModel = (
  data: PartnersIndividualDonationSummaryApi,
): PartnersIndividualDonationSummary => {
  const donations = data?.total_donations?.donations ?? [];

  return {
    id: data?.id ?? 0,
    name: data?.name ?? '',
    content: data?.content ?? '',
    logo: data?.logo ?? '',
    individualDonations: donations.map((donation) => ({
      totalDonated: donation.total_donated,
      donatedOn: donation.donated_on,
      donationId: donation.id,
    })),
  };
};

export const partnersIndividualDonationsDataModel = (
  data?: PartnersIndividualDonationSummaryApiData,
): PartnersIndividualDonationSummaryData => ({
  individualDonations: map(data?.individual_donations, partnersIndividualDonationsModel),
  pagination: paginationModel(data?.meta),
});
