import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { MembershipRenewalModel } from './membership-renewal.model';
import { membershipRenewalService } from '@project/services';

const toastDuration = { duration: 3000 };

export const useGetMembershipRenewalQuery = () =>
  useQuery(['getMembershipRenewal'], () => membershipRenewalService.getMembershipRenewal(), {
    select: MembershipRenewalModel,
    onError: () => toast.error('Could not fetch membership renewal data.', toastDuration),
  });
