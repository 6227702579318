import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TopImage } from '@project/components';
import { Button } from '@project/components';

export const IndemnityFormCompletionPage: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name') || 'Kopa Kopa';
  const source = queryParams.get('source') || null;

  const goBack = () => {
    if (source === 'logged_in') navigate('/partner/home');
    else navigate('/');
  };

  return (
    <div>
      <TopImage />
      <div className="w-full min-w-fit px-3 py-10 lg:pl-36 lg:pr-[564px]">
        <div>
          <div className="mb-10 flex w-full flex-row justify-between">
            <div>
              <h1 className="text-3xl font-semibold">{`Dear ${name},`}</h1>
            </div>
          </div>
          <p className="my-10 leading-loose text-black">
            Thank you for filling in our TRAVEL RISK ASSESSMENT AND INDEMNITY FORM.
            <br />
            It has been successfully sent.
            <br />
            Any further communication will be sent via email or phone.
            <br />
            Thank you.
            <br />
            KFM
          </p>
          <Button
            style={`bg-${'gold'} text-white py-2.5 justify-end p-14 rounded-md mb-20 mt-10`}
            onClick={goBack}
          >
            PROCEED
          </Button>
        </div>
      </div>
    </div>
  );
};
