import { GeocodingDataType } from './types';

export const GeocodingModel = (result: any): GeocodingDataType => ({
  street: result.components.road,
  state: result.components.state,
  stateCode: result.components.state_code,
  suburb: result.components.suburb,
  town: result.components.town,
  postalCode: result.components.postcode,
  country: result.components.country,
  countryCode: result.components.country_code,
  currency: {
    code: result.annotations.currency.iso_code,
    symbol: result.annotations.currency.symbol,
  },
});
