import { SingleTeamMemberModelType } from './types';
import { SingleTeamMemberApi } from '@project/services';

export const SingleTeamMemberModel = (data: SingleTeamMemberApi): SingleTeamMemberModelType => ({
  id: data?.id ?? 0,
  firstName: data?.first_name ?? '',
  lastName: data?.last_name ?? '',
  title: data?.title ?? '',
  country: data?.country ?? '',
  description: data?.description ?? '',
  teamProfilePicture: data?.team_profile_picture ?? '',
});
