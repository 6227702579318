import z from 'zod';

export const RetreatContributionSchema = z
  .object({
    paymentMethod: z.string(),
    currencySymbol: z.string(),
    contributionCurrency: z.string(),
    contributionAmount: z.number().max(1000000).nonnegative('Contribution amount must be positive'),
    tip: z.number().nonnegative('Tip amount must be positive').or(z.null()).optional(),
    minimumAmount: z.number(),
    prayerRequests: z.array(z.string()),
    minDonations: z.array(
      z.object({
        symbol: z.string(),
        currency: z.string(),
        minDonation: z.string(),
      }),
    ),
  })
  .superRefine(({ contributionAmount, minimumAmount, paymentMethod }, ctx) => {
    if (paymentMethod === 'manual' && contributionAmount < minimumAmount * 0.3)
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Please note, your booking can only be secured with a minimum of 30% required as downpayment',
        path: ['contributionAmount'],
      });

    if (paymentMethod === 'debit_credit' && contributionAmount < minimumAmount)
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Donation needs to be more than ${minimumAmount}`,
        path: ['contributionAmount'],
      });
  });
