import React from 'react';

import { BankInfoProps } from './types';

const FIELD_DESCRIPTOR_STYLE = 'text-black pr-3 text-base';
const TEXT_STYLING = 'text-base text-gray-600';
const DIV_STYLING = 'flex flex-row';

export const BankInfo: React.FC<BankInfoProps> = ({
  country,
  bankName,
  bankAddress,
  imageSrc,
  accountName,
  recipientAddress,
  recipientPhoneNumber,
  accountNumber,
  swiftCode,
  sortCode,
  abaSwiftBicCode,
  branchCode,
}) => (
  <div className="mb-6 pt-5">
    <div>
      <div className="flex">
        <img src={imageSrc} alt="Country Flag" className="h-5 w-8" />
        <span className="pl-2 text-lg font-semibold uppercase">{country}</span>
      </div>
      <div className={DIV_STYLING}>
        <span className={FIELD_DESCRIPTOR_STYLE}>Bank Name:</span>
        <span className={TEXT_STYLING}>{bankName}</span>
      </div>
      {accountName && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Account Name:</span>
          <span className={TEXT_STYLING}>{accountName}</span>
        </div>
      )}
      {accountNumber && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Account Number:</span>
          <span className={TEXT_STYLING}>{accountNumber}</span>
        </div>
      )}
      {recipientAddress && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Recipient Address:</span>
          <span className={TEXT_STYLING}>{recipientAddress}</span>
        </div>
      )}
      {recipientPhoneNumber && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Recipient Mobile:</span>
          <span className={TEXT_STYLING}>{recipientPhoneNumber}</span>
        </div>
      )}
      {branchCode && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Branch Code:</span>
          <span className={TEXT_STYLING}>{branchCode}</span>
        </div>
      )}
      {swiftCode && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Swift Code:</span>
          <span className={TEXT_STYLING}>{swiftCode}</span>
        </div>
      )}
      {abaSwiftBicCode && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>ABA/Swift/BIC:</span>
          <span className={TEXT_STYLING}>{abaSwiftBicCode}</span>
        </div>
      )}
      {sortCode && (
        <div className={DIV_STYLING}>
          <span className={FIELD_DESCRIPTOR_STYLE}>Sort Code:</span>
          <span className={TEXT_STYLING}>{sortCode}</span>
        </div>
      )}
      <div className={DIV_STYLING}>
        <span className={FIELD_DESCRIPTOR_STYLE}>Bank Address:</span>
        <span className={TEXT_STYLING}>{bankAddress}</span>
      </div>
    </div>
  </div>
);
