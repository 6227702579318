import { useQuery } from '@tanstack/react-query';

import { paymentService } from '@project/services';
import { PaymentIntentModel } from './payment-intent.model';

export const useGetPaymentIntentQuery = (
  enabledQuery: boolean,
  amount: string,
  currency: string,
  donationType: string,
  paymentType: string,
  eventId?: number,
  prayerRequests?: string,
) =>
  useQuery(
    ['getPaymentIntent'],
    () =>
      paymentService.createIntent(
        eventId,
        amount,
        currency,
        donationType,
        paymentType,
        prayerRequests,
      ),
    {
      enabled: enabledQuery,
      select: PaymentIntentModel,
      staleTime: 0,
      cacheTime: 0,
    },
  );
