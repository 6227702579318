import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/outline';

import { PartnerArticleCardProps } from './types';
import { isVideoUrl } from '@project/helper';

export const ArticlesCard = ({
  featureImage,
  id,
  heading,
  content,
  author,
  createdAt,
}: PartnerArticleCardProps) => {
  const truncatedContent = content.slice(0, 100);
  const navigate = useNavigate();
  const isVideo = isVideoUrl(featureImage);

  const handleViewIndividualArticleView = () => navigate(`/partner/articles/${id}`);

  return (
    <div
      key={id}
      className="mb-10 grid cursor-pointer space-x-2 border p-4 shadow-md shadow-gray-400 md:grid-cols-6"
      onClick={handleViewIndividualArticleView}
    >
      <div className="col-span-4 flex flex-col justify-center">
        <p className="mb-2 font-semibold">{heading}</p>
        <p className="mb-4">{truncatedContent} ...</p>
        <div className="border-b border-gray-600" />
        <div className="flex flex-row flex-wrap">
          <div className="mr-4 flex flex-row">
            <UserIcon className="w-4" />
            <p className="pl-2 text-sm font-light md:text-base">{author}</p>
          </div>
          <div className="flex flex-row">
            <CalendarIcon className="w-4" />
            <p className="pl-2 text-sm font-light md:text-base">
              {dayjs(createdAt).format('DD MMMM YYYY')}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-4 flex items-center justify-center lg:col-span-2">
        {isVideo ? (
          <video className="h-44 w-full rounded object-cover" controls>
            <source src={featureImage} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className="h-44 w-full rounded object-cover"
            src={featureImage}
            alt="Article Image"
          />
        )}
      </div>
    </div>
  );
};
