import { networkService } from '@project/services';
import { getData, rejectPromise } from '../../utils';
import aboutUsDetailsUrls from './about-us-details.urls';

const getAboutUsDetails = () => {
  const url = aboutUsDetailsUrls.aboutUsDetailsUrl();

  return networkService.get(url).then(getData).catch(rejectPromise);
};

export default {
  getAboutUsDetails,
};
