import z from 'zod';

export const billingSchema = z.object({
  paymentMethod: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  number: z.string().min(6).max(20),
  phoneNumber: z.string().min(6).max(20),
  countryCode: z.string(),
  street1: z.string(),
  street2: z.string().optional(),
  country: z.string(),
  region: z.string(),
  townCity: z.string(),
  postcodeZip: z.string(),
  intentId: z.string(),
  termsAndConditions: z
    .boolean()
    .refine((val) => val, { message: 'You need to accept the terms and conditions' }),
});
