import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@project/components';

export const Footer = () => {
  const navigate = useNavigate();

  const termsNav = () => {
    navigate(`/terms-and-conditions`);
  };

  return (
    <div className="grid items-center justify-between bg-charcoal px-5 pb-10 md:grid-cols-2 xl:p-8">
      <div className="pt-6 xl:ml-20">
        <p className="text-white">
          COPYRIGHT © 2023 KINGDOM FINANCIERS INTERNATIONAL. ALL RIGHTS RESERVED
        </p>
      </div>
      <div className="mt-8 flex flex-row bg-charcoal text-white md:mt-0 md:justify-end md:space-x-4 xl:mr-20 xl:space-x-10">
        <Button style="h-12 w-34 rounded-md py-3 hover:text-gold" onClick={termsNav}>
          Terms & Conditions
        </Button>
      </div>
    </div>
  );
};
