import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { PartnerExpired } from './types';

export const usePartnerExpiredStore = create<PartnerExpired>()(
  persist(
    (set) => ({
      isExpired: false,
      set,
    }),
    {
      name: 'partnerExpiredStore',
    },
  ),
);
