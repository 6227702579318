import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { AboutUsDetailsModel } from './about-us-details.model';
import { aboutUsDetailsService } from '@project/services';

const toastDuration = { duration: 3000 };

export const useGetAboutUsDetailsQuery = () =>
  useQuery(['getAboutUsDetails'], () => aboutUsDetailsService.getAboutUsDetails(), {
    select: AboutUsDetailsModel,
    onError: () => toast.error('Could not fetch about us details.', toastDuration),
  });
