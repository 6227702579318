import { ForgotPasswordValuesProps, ResetPasswordValuesProps } from '@project/components';

export const forgotPasswordDTO = (formData: ForgotPasswordValuesProps) => ({
  partner: {
    email: formData.username,
  },
});

export const resetPasswordDTO = (formData: ResetPasswordValuesProps) => ({
  new_password: formData?.password,
  new_password_confirmation: formData?.confirmPassword,
  reset_password_token: formData?.token,
});

export const unlockDTO = (token: string) => ({
  partner: {
    token,
  },
});
