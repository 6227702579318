import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { ComponentProps } from './types';
import {
  RetreatContributionForm,
  CompletePaymentForm,
  RetreatConditions,
  Card,
} from '@project/components';

export const PartnersRetreatContributionFormHelper: React.FC<ComponentProps> = ({
  id,
  currency,
}) => {
  const [madeDonation, setMadeDonation] = useState(false);

  const setTrue = () => setMadeDonation(true);
  const setFalse = () => setMadeDonation(false);

  return (
    <div className="space-y-12">
      <Card className="space-y-5 p-5 text-sm font-light md:pt-8 md:pl-12 md:pb-14">
        <p>Have You Already Made A Donation?</p>
        <div className="space-x-6">
          <input
            type="radio"
            id="retreat-radio-yes"
            name="retreat-radio"
            onChange={setTrue}
            className="text-gold"
            checked={madeDonation}
          />
          <label htmlFor="retreat-radio-yes">Yes</label>
        </div>
        <div className="space-x-6">
          <input
            type="radio"
            id="retreat-radio-no"
            name="retreat-radio"
            onChange={setFalse}
            className="text-gold"
            checked={!madeDonation}
          />
          <label htmlFor="retreat-radio-no">No</label>
        </div>
      </Card>
      <Transition
        show={madeDonation}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-1"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        <CompletePaymentForm minDonationCurrency={currency} />
        <RetreatConditions />
      </Transition>
      <Transition
        show={!madeDonation}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-1"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        <RetreatContributionForm id={id} isPublic={false} />
      </Transition>
    </div>
  );
};
