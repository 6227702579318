import contactUsUrls from './contact-us.urls';
import { ContactUsDto } from './contact-us.dto';
import { getData } from '@project/services/utils';
import { networkService } from '@project/services';
import { ContactUsValues } from '@project/components';

const postContactUs = (data: ContactUsValues) => {
  const url = contactUsUrls.postContactUsUrl();
  const dto = ContactUsDto(data);

  return networkService.post(url, dto).then(getData);
};

export default {
  postContactUs,
};
