import { useFormikContext } from 'formik';
import { FlatList } from '@codehesion-za/headless';
import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { DropdownProps } from './types';
import { BaseOption } from '@project/types';

const renderItem = ({ item, index }: { item: BaseOption; index: number }) => (
  <Combobox.Option
    key={item.value + item.label + index}
    className={({ active }) =>
      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gold' : 'text-gray-900'}`
    }
    value={item}
  >
    {({ selected, active }) => (
      <>
        <span
          className={`block truncate ${selected ? 'font-medium' : 'font-normal'} ${
            active ? 'text-white' : 'text-black'
          }`}
        >
          {item.label}
        </span>
        {selected && (
          <span
            className={`absolute inset-y-0 left-0 flex items-center pl-3 text-${
              active ? 'white' : 'gray-900'
            }`}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </>
    )}
  </Combobox.Option>
);

const EmptyListComponent = (
  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
);

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  fieldName,
  options,
  value: selectedValue,
}) => {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const { setFieldValue } = useFormikContext<unknown>();
  const inputRef = useRef<HTMLInputElement>(null);
  const chevronRef = useRef<HTMLButtonElement>(null);

  // Extract relevant label part and set the placeholder
  const labelParts = label ? label.split(' ') : [];
  const relevantLabel =
    labelParts.length > 0 ? labelParts[labelParts.length - 1].replace('*', '') : 'Item';
  const placeholder = `Search ${relevantLabel}`;

  useEffect(() => {
    setFilteredOptions(
      query === ''
        ? options
        : options.filter((option) =>
            option.label
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          ),
    );
  }, [query, options]);

  const handleOnChange = (selectedOption: BaseOption) => {
    setFieldValue(fieldName, selectedOption.value);
    setIsOpen(false);
  };

  const handleDisplayValue = (displayValue: BaseOption) => displayValue.label;

  const handleInputOnChange = (event: React.FormEvent<HTMLInputElement>) =>
    setQuery(event.currentTarget.value);

  const handleAfterLeave = () => setQuery('');

  const handleInputClick = () => {
    setIsOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (chevronRef.current) {
      chevronRef.current.click();
    }
  };

  const handleComboboxOnChange = (value: BaseOption) => {
    if (value) {
      handleOnChange(value);
    }
  };

  return (
    <div className="mt-2 flex flex-col">
      <label className="mb-1">{label}</label>
      <Combobox value={selectedValue} onChange={handleComboboxOnChange} as="div">
        <div className="relative">
          <div className="combobox-input-div">
            <Combobox.Input
              ref={inputRef}
              className="w-full cursor-default border-none py-2.5 pl-3 pr-10 text-base leading-5 text-gray-900 focus:ring-0"
              displayValue={handleDisplayValue}
              onChange={handleInputOnChange}
              onClick={handleInputClick}
              onFocus={() => setIsOpen(true)}
              placeholder={placeholder}
            />
            <Combobox.Button
              ref={chevronRef}
              className="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={handleAfterLeave}
          >
            <Combobox.Options className={`combobox-options z-20 ${isOpen ? 'block' : 'hidden'}`}>
              <FlatList
                items={filteredOptions}
                renderItem={renderItem}
                ListEmptyComponent={EmptyListComponent}
              />
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
