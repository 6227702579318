import networkService from '../network-service/network.service';
import websiteUrl from './website.urls';
import { getData } from '@project/services/utils';

export const websiteStats = () => {
  const url = websiteUrl.getStats();

  return networkService.get(url).then(getData);
};

export default {
  websiteStats,
};
