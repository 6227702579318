import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCentreModal } from '@project/hooks';

export const DeclinePage: React.FC = () => {
  const navigate = useNavigate();
  const [CentreModal, isOpen, closeModal] = useCentreModal(true);

  const goBack = () => navigate('/');

  return (
    <div>
      <CentreModal isOpen={isOpen} onClose={closeModal} afterClose={goBack}>
        <div className="flex flex-col items-center justify-center bg-gray-900 p-8 text-center text-gold">
          <h2 className="mb-4 text-2xl">Your Payment was declined</h2>
          <h2 className="mb-4 text-2xl">Please try again</h2>
        </div>
      </CentreModal>
    </div>
  );
};
