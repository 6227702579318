import z from 'zod';

export const meetingContributionSchema = (minAmount: number) =>
  z.object({
    contributionCurrency: z.string(),
    contributionAmount: z
      .number()
      .min(minAmount)
      .max(1000000)
      .nonnegative('Contribution amount must be positive'),
    prayerRequests: z.array(z.string()),
    tip: z.number().nonnegative('Tip amount must be positive').optional(),
  });
