import {
  AppLayout,
  ProtectedRoute,
  PartnerHomePage,
  PartnersProfile,
  ViewArticlePage,
  PartnersArticlesTab,
  PartnersMeetingsTab,
  PartnersDonationTypePage,
  IndividualMeetingRegister,
  IndividualDonationTypeInfo,
  PartnersDonationHistoryPage,
  PartnersIndividualDonationHistory,
  IndividualDonationTypeDonateForm,
  PartnersTermsAndConditions,
} from '@project/components';

export const AppRouter = [
  {
    path: 'partner',
    element: <AppLayout />,
    children: [
      {
        path: 'home',
        element: (
          <ProtectedRoute>
            <PartnerHomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'charities',
        element: (
          <ProtectedRoute>
            <PartnersDonationTypePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'charities/:id',
        element: (
          <ProtectedRoute>
            <IndividualDonationTypeInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: 'donations',
        element: (
          <ProtectedRoute>
            <PartnersDonationHistoryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'donations/:id',
        element: (
          <ProtectedRoute>
            <PartnersIndividualDonationHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: 'charities/donation-form/:id',
        element: (
          <ProtectedRoute>
            <IndividualDonationTypeDonateForm />
          </ProtectedRoute>
        ),
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute>
            <PartnersProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: 'articles',
        element: (
          <ProtectedRoute>
            <PartnersArticlesTab />
          </ProtectedRoute>
        ),
      },
      {
        path: 'articles/:id',
        element: (
          <ProtectedRoute>
            <ViewArticlePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'meetings',
        element: (
          <ProtectedRoute>
            <PartnersMeetingsTab />
          </ProtectedRoute>
        ),
      },
      {
        path: 'meetings/:id',
        element: (
          <ProtectedRoute>
            <IndividualMeetingRegister />
          </ProtectedRoute>
        ),
      },
      {
        path: 'ts-and-cs',
        element: (
          <ProtectedRoute>
            <PartnersTermsAndConditions />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export const AppModalRouter = [];
