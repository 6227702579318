import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';

import { CompletePartialPaymentFormProps, CompletePartialPaymentType } from './types';
import { TextField, Button, Card, PaymentAmountField } from '@project/components';
import { useGetOutstandingBalanceQuery } from '@project/queries';
import { CompletePartialPaymentSchema } from './schema';

const FormComponents = () => {
  const { values, isSubmitting, isValid, handleSubmit } =
    useFormikContext<CompletePartialPaymentType>();
  const { data } = useGetOutstandingBalanceQuery(
    values.referenceNumber,
    values.contributionCurrency,
  );

  return (
    <Card className="p-5 font-light md:pl-12 md:pt-14 md:pr-11 md:pb-9">
      <p className="mb-3 md:mb-6">Reference Number Of Payment</p>
      <TextField name="referenceNumber" type="text" placeholder="Reference No." />
      {values.referenceNumber && (
        <div className="my-2 flex gap-x-1 md:my-4">
          <p>The outstanding balance for this reference number is:</p>
          <p>{data?.outstandingBalance}</p>
        </div>
      )}
      <div className="mt-3 mb-4 md:mt-7 md:mb-20">
        <PaymentAmountField
          amountFieldName="contributionAmount"
          currencyFieldName="contributionCurrency"
          selectedCurrency={values.contributionCurrency}
        />
      </div>
      <Button
        style="bg-gold text-white font-bold text-sm rounded py-3 min-w-fit w-1/4"
        isDisabled={!isValid}
        isLoading={isSubmitting}
        onClick={handleSubmit}
      >
        Donate
      </Button>
    </Card>
  );
};

export const CompletePaymentForm: React.FC<CompletePartialPaymentFormProps> = ({
  minDonationCurrency,
}) => {
  const navigate = useNavigate();
  const initialValues: CompletePartialPaymentType = {
    referenceNumber: '',
    contributionAmount: 0.0,
    contributionCurrency: minDonationCurrency,
  };

  const onSubmitForm = (formData: CompletePartialPaymentType) => {
    navigate('/upload-proof-of-payment', { state: formData });
    return new Promise(() => null);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={CompletePartialPaymentSchema}
    />
  );
};
