import map from 'lodash/map';

import { ContactUsValues } from '@project/components';
import { ContactUsDtoType } from './types';

const RecipientEmailModel = (data: string) => ({
  email: data,
});

export const ContactUsDto = (data: ContactUsValues): ContactUsDtoType => ({
  contact_us: {
    full_name: data.fullName,
    sender_email: data.email,
    topic: data.topic,
    message: data.message,
    recipient_emails: map(data.recipientEmails, (email) => RecipientEmailModel(email)),
  },
});
