import config from '../../../config';

const paymentBaseUrl = `${config.apiUrl}/payments`;

export default {
  createIntentUrl: () => `${paymentBaseUrl}/create_intent`,
  completePaymentUrl: () => `${paymentBaseUrl}/complete`,
  exchangeCurrencyUrl: (amount: number, amountCurrency: string, requestedCurrency: string) =>
    `${paymentBaseUrl}/exchange_currency?amount=${amount}&amount_currency=${amountCurrency}&requested_currency=${requestedCurrency}`,
};
