import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FormikProps } from 'formik/dist/types';
import { useMutation } from '@tanstack/react-query';
import { Form, ErrorObject } from '@codehesion-za/headless';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, TextField } from '@project/components';
import { LoginValuesProps, RouteState } from './types';
import { userAuthService } from '@project/services';
import { loginSchema } from './schemas';
import { GAEvent, trackEvent } from '@project/services';

export const LoginForm = () => {
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const reset = new URLSearchParams(search).get('reset');
  const unlock = new URLSearchParams(search).get('unlock');

  const initialValues = {
    username: '',
    password: '',
  };

  const onForgotPasswordClick = () => navigate('/partner/auth/forgot-password');

  const { mutateAsync: login } = useMutation(
    (formData: LoginValuesProps) => userAuthService.login(formData),
    {
      onSuccess: () => {
        trackEvent(GAEvent.LoginSuccess);
        navigate((state as RouteState)?.from || '/partner/home');
      },
      onError: (error: ErrorObject<typeof initialValues>) => {
        toast.error(
          error.message === 'invalid_grant' ? 'Invalid email or password' : error.message,
          { duration: 5000 },
        );
        trackEvent(GAEvent.LoginFailed);
      },
    },
  );

  const submitForm = async (formData: LoginValuesProps) => {
    trackEvent(GAEvent.LoginAttempt);
    return await login(formData);
  };

  useEffect(() => {
    if (reset === 'successful') {
      toast.success('Password successfully reset', { duration: 5000 });
    } else if (unlock === 'successful') {
      toast.success('Account successfully unlocked', { duration: 5000 });
    }
  }, [reset, unlock]);

  const FormComponents = ({ isSubmitting, handleSubmit }: FormikProps<LoginValuesProps>) => (
    <div className="auth-form-body">
      <TextField name="username" label="Email" type="email" />
      <TextField name="password" label="Password" type="password" />
      <div className="flex justify-end">
        <Button
          variant="text"
          onClick={onForgotPasswordClick}
          style="text-xs text-gray-400 underline"
        >
          Forgot Password
        </Button>
      </div>
      <Button
        type="submit"
        isLoading={isSubmitting}
        onClick={handleSubmit}
        style="bg-gold text-white py-3 px-2 rounded-md"
      >
        Login
      </Button>
    </div>
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmitForm={submitForm}
      onFailure={() => null}
      render={FormComponents}
      validationSchema={loginSchema}
    />
  );
};
