import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { accessTokenOperations } from '@project/services';
import {
  Card,
  CompletePaymentForm,
  LoadingContainer,
  RetreatConditions,
  TopImage,
} from '@project/components';
import { useGetEventQuery, useGetPublicEventQuery } from '@project/queries';

export const RetreatPage: React.FC = () => {
  const isAuthenticated = accessTokenOperations.get();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showCompletePayment, setShowCompletePayment] = useState(false);

  const { data: event, isLoading } = isAuthenticated
    ? useGetEventQuery(id ?? '-1')
    : useGetPublicEventQuery(id ?? '-1');

  const goToMeetingContribution = () =>
    navigate(`/meeting-contributions/${id}?meeting_name=${event?.name ?? 'Unknown Event'}`, {
      state: 'FROM_RETREAT_PAGE',
    });

  const handleShowCompletePayment = (e: React.FormEvent<HTMLInputElement>) =>
    setShowCompletePayment(e.currentTarget.checked);

  return (
    <div className="flex flex-col">
      <TopImage />
      <LoadingContainer isLoading={isLoading}>
        <div className="grid grid-cols-12 space-y-5 pb-5 pl-5 pr-5 md:pl-36 md:pr-44">
          <div className="col-span-12 pt-8">
            <div className="w-fit">
              <h1 className="text-2xl font-semibold">{event?.name ?? 'Unknown Event'}</h1>
              <div className="w-1/3 rounded border-b-2 border-b-gold" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <img src={event?.eventImage ?? ''} alt="A Poster For The Retreat" />
          </div>
          <div className="col-span-12 space-y-12 md:col-span-9 md:pl-12">
            <Card className="w-full space-y-5 p-5 text-sm font-light md:pt-8 md:pl-12 md:pb-14">
              <p>Have You Already Made A Donation?</p>
              <div className="space-x-6">
                <input
                  type="radio"
                  id="retreat-radio-yes"
                  name="retreat-radio"
                  onChange={handleShowCompletePayment}
                  className="text-gold"
                />
                <label htmlFor="retreat-radio-yes">Yes</label>
              </div>
              <div className="space-x-6">
                <input
                  type="radio"
                  id="retreat-radio-no"
                  name="retreat-radio"
                  onChange={goToMeetingContribution}
                  className="text-gold"
                />
                <label htmlFor="retreat-radio-no">No</label>
              </div>
            </Card>
            <Transition
              show={showCompletePayment}
              enter="transform transition duration-[400ms]"
              enterFrom="opacity-0 scale-1"
              enterTo="opacity-100 scale-100"
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100 scale-100 "
              leaveTo="opacity-0 scale-95 "
            >
              <CompletePaymentForm minDonationCurrency={event?.minDonationCurrency ?? 'USD'} />
              <RetreatConditions />
            </Transition>
          </div>
        </div>
      </LoadingContainer>
    </div>
  );
};
