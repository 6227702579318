import dayjs from 'dayjs';

import { GetManualPaymentApi } from '@project/services';
import { GetManualPaymentModel } from './types';

export const manualPaymentModel = (data: GetManualPaymentApi): GetManualPaymentModel => ({
  id: data?.id ?? '',
  createdAt: dayjs(data?.created_at ?? new Date()).format('YYYY-MM-DD') ?? '',
  referenceNumber: data?.reference_number ?? '',
  total: data?.total ?? '',
  donationType: data?.donation_type ?? '',
});
