import z from 'zod';

export const donationSchema = z.object({
  donationAmount: z
    .number()
    .min(1)
    .max(1000000)
    .nonnegative('Contribution amount must be positive'),
  donationCurrency: z.string(),
  donationType: z.string(),
  prayerRequests: z.array(z.string()),
});
