import React, { useEffect, useState } from 'react';
import { FlatList } from '@codehesion-za/headless';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from '@react-hook/media-query';
import map from 'lodash/map';

import {
  TopImage,
  TeamMemberProfile,
  Footer,
  LoadingContainer,
  ActivityLoader,
} from '@project/components';
import { AboutUsNpcOfferType } from '@project/services';
import { AboutUsPageBanner } from '@project/assets';
import {
  useGetAllTeamMembersQuery,
  SingleTeamMemberModelType,
  useGetAboutUsDetailsQuery,
  AboutUsDetailsModelType,
} from '@project/queries';
import { isEmpty } from 'lodash';

const StatCards = ({
  type,
  header,
  subHeader,
}: {
  type: string;
  header: string;
  subHeader: string;
}) => (
  <div
    className={`${
      type === 'odd' ? 'bg-black' : 'bg-oddStatCard bg-cover'
    } flex h-[250px] flex-col items-center`}
  >
    <div className="my-auto text-center">
      <p className="text-3xl font-bold text-white">{header}</p>
      <p className="mt-4 text-lg font-light text-white">{subHeader}</p>
    </div>
  </div>
);

const KFMIOffersCards = ({ item, index }: { item: AboutUsNpcOfferType; index: number }) => (
  <div key={`npc-offer-${index}`} className="mx-4 grid md:grid-cols-2 xl:mx-16">
    <div className="flex h-20 items-center justify-center rounded bg-gold md:ml-8 md:h-full md:w-32 lg:ml-[20%] xl:ml-[35%] xl:h-32">
      <img className="w-16" src={item.icon} alt="kfmi-npc-icon" />
    </div>
    <div className="mt-4 flex flex-col justify-center md:-ml-[40%] md:mt-0 lg:-ml-[40%]">
      <p className="mb-2 font-semibold text-black md:text-2xl">{item.heading.toUpperCase()}</p>
      <p className="text-black md:text-lg">{item.description}</p>
    </div>
  </div>
);

const EmptyListComponent = <span>Currently No Ongoing KFMI NPC Offers</span>;

const aboutUsSpacing = 'pb-8 pt-2';
const headerStyling =
  'mx-10 text-3xl font-semibold text-black underline decoration-gold underline-offset-4 lg:mx-32';

export const AboutUsPage: React.FC = () => {
  const [teamMembers, setTeamMembers] = useState<SingleTeamMemberModelType[]>([]);
  const [aboutUsDetails, setAboutUsDetails] = useState<AboutUsDetailsModelType>();
  const [descriptionLength, setDescriptionLength] = useState<string>('100');
  const { data: aboutUsData, isLoading: aboutUsLoading } = useGetAboutUsDetailsQuery();
  const { data, isLoading } = useGetAllTeamMembersQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAboutUsDetails(aboutUsData);
    setTeamMembers(data?.teamMembers ?? []);

    const descriptions = map(data?.teamMembers, 'description');

    const descriptionLengthList = descriptions.map((description) => {
      const length = description?.length;

      if (length === 300) return '300';
      if (length <= 100) return '100';
      return '200';
    });

    const character300 = descriptionLengthList.includes('300');

    const character200 = descriptionLengthList.includes('200');

    setDescriptionLength(character300 ? '300' : character200 ? '200' : '100');
  }, [isLoading, aboutUsLoading]);

  const smallScreenSize = useMediaQuery('(min-width: 200px) and (max-width: 470px)');
  const mediumScreenSize = useMediaQuery('(min-width: 472px) and (max-width: 700px)');
  const screenSize = useMediaQuery('(min-width: 1024px) and (max-width: 1440px)');
  const largeScreenSize = useMediaQuery('(min-width: 1440px) and (max-width: 2560px');

  const chunkSize = largeScreenSize
    ? 5
    : screenSize
    ? 4
    : smallScreenSize
    ? 1
    : mediumScreenSize
    ? 2
    : 3;

  const chunkedTeamMembers = [];

  for (let i = 0; i < teamMembers.length; i += chunkSize) {
    const chunk = teamMembers.slice(i, i + chunkSize);
    chunkedTeamMembers.push(chunk);
  }

  const className =
    descriptionLength === '300'
      ? 'meet-the-team-carousel-300'
      : descriptionLength === '200'
      ? 'meet-the-team'
      : 'meet-the-team-carousel-100';

  return (
    <div className="flex flex-col">
      <TopImage />
      <div className="my-16 ml-4 md:ml-36">
        <p className="mb-2 text-4xl font-semibold tracking-wide">About Us</p>
        <div className="w-24 rounded border-2 border-gold" />
      </div>
      <div className="grid md:mx-14 md:grid-cols-3">
        <StatCards type="odd" header="2020" subHeader="Year Established" />
        <StatCards type="even" header="+15000" subHeader="Donors And Partners" />
        <StatCards type="odd" header="+100" subHeader="Coordinators And Volunteers Worldwide" />
      </div>
      <LoadingContainer isLoading={aboutUsLoading && isLoading}>
        <div className="mx-4 mt-6 py-10 md:mx-16 lg:mx-36">
          <div className="mb-6 text-sm sm:text-base">
            <span className="text-2xl font-semibold">About Us:</span>
            <p className={aboutUsSpacing}>{aboutUsDetails?.aboutUs ?? ''}</p>
            <span className="text-2xl font-semibold">Our Vision:</span>
            <p className={aboutUsSpacing}>{aboutUsDetails?.vision ?? ''}</p>
            <span className="text-2xl font-semibold">Our Mission Statement:</span>{' '}
            <p className={aboutUsSpacing}>{aboutUsDetails?.missionStatement ?? ''}</p>
          </div>
        </div>
        <div>
          {window.innerWidth >= 425 && (
            <img className="h-[300px] lg:h-full" src={AboutUsPageBanner} />
          )}
          <div className="bg-black text-center sm:absolute sm:-mt-[70%] sm:bg-transparent md:-mt-[35%] lg:-mt-[23%] xl:-mt-[19%]">
            <p className="mb-4 pt-10 font-semibold text-white md:pt-0 md:text-4xl">
              OUR PROGRAMMES
            </p>
            <div className="mx-[50px] border-2 border-gold md:mx-[200px] xl:mx-[450px]" />
            <p className="mx-2 mt-4 pb-10 text-[12px] leading-loose tracking-wide text-white md:mx-10 md:pb-0 md:text-xl lg:mx-32 xl:mt-10">
              {aboutUsDetails?.programmeDescription ?? ''}
            </p>
          </div>
        </div>
        <div className="my-20">
          <p className={headerStyling}>KFMI NPC Offers:</p>
          <div className="grid-rows-8 mt-14 grid gap-y-10 lg:mx-16">
            <FlatList
              items={aboutUsDetails?.npcOffers ?? []}
              renderItem={KFMIOffersCards}
              ListEmptyComponent={EmptyListComponent}
            />
          </div>
        </div>
        <div className="mt-10 bg-gold">
          <h2 className="py-2 text-center text-2xl font-semibold text-white">Meet The Team</h2>
        </div>
        <div className="mx-auto flex items-center justify-center">
          <ActivityLoader isLoading={isLoading} />
        </div>
        <div className="my-20 justify-center">
          {!isEmpty(chunkedTeamMembers) && (
            <Carousel
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              infiniteLoop={true}
              showArrows={true}
              emulateTouch={true}
              interval={4000}
              className={className}
              autoFocus={true}
            >
              {chunkedTeamMembers.map((chunk, slideIndex) => (
                <div key={`slide-${slideIndex}`} className="flex justify-center">
                  {chunk.map((teamMember, index) => (
                    <div
                      key={`team-member-index-${index}#id-${teamMember.id}`}
                      className="xl:mx-4 "
                    >
                      <TeamMemberProfile teamMember={teamMember} />
                    </div>
                  ))}
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </LoadingContainer>
      <Footer />
    </div>
  );
};
