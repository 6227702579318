import { Form } from '@codehesion-za/headless';
import { FormikProps } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, donationSchema, PaymentAmountField } from '@project/components';
import { DonationFormValues, DonationProps } from './types';

export const PartnersIndividualSpecialDonationForm: React.FC<DonationProps> = ({
  donationTypeName,
}) => {
  const navigate = useNavigate();

  const onSubmitForm = (formData: DonationFormValues) => {
    navigate(
      `/payment?type=${formData.donationType}&amount=${formData.donationAmount}&currency=${formData.donationCurrency}&payment_type=donation`,
    );
    return new Promise(() => null);
  };

  const FormComponents = ({
    isSubmitting,
    handleSubmit,
    values,
  }: FormikProps<DonationFormValues>) => (
    <div className="rounded-md bg-white px-10 py-20 shadow shadow-slate-300">
      <p className="text-xl">Select Donation Amount</p>
      <div className="border border-b" />
      <div className="py-20">
        <PaymentAmountField
          amountFieldName="donationAmount"
          currencyFieldName="donationCurrency"
          selectedCurrency={values.donationCurrency ?? ''}
        />
      </div>
      <div className="border border-b" />

      <Button
        type="submit"
        isLoading={isSubmitting}
        onClick={handleSubmit}
        style="bg-gold text-white py-3 px-16 rounded mt-10"
      >
        Continue
      </Button>
    </div>
  );

  return (
    <Form
      initialValues={{ donationAmount: 1, donationCurrency: 'USD', donationType: donationTypeName }}
      onSubmitForm={onSubmitForm}
      onFailure={() => null}
      render={FormComponents}
      validationSchema={donationSchema}
    />
  );
};
