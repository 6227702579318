import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import type { ErrorObject } from '@codehesion-za/headless';
import { Button, SecureTextField } from '@project/components/atoms';
import {
  partnerRegisterationPasswordFormSchema,
  PartnerRegisterationValues,
} from '@project/schemas';
import { useGetIsPartner } from '@project/queries';
import { partnerService } from '@project/services';
import {
  EMAIL_ALREADY_EXIST,
  FILL_REQUIRED_INPUTS,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESS,
  TOAST_DURATION,
} from '@project/helper';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { GAEvent, trackEvent } from '@project/services';
import * as Sentry from '@sentry/react';

export const PartnersCreatePasswordForm: React.FC = () => {
  const { setValues, setErrors, values, errors, setFieldError } =
    useFormikContext<PartnerRegisterationValues>();
  const { data, isLoading: getIsPartnerLoading } = useGetIsPartner(values.email);
  const navigate = useNavigate();
  const [makePayment, setMakePayment] = useState<boolean>(false);

  const isFormFilled = () => values.newPassword && values.confirmPassword;

  const { mutateAsync: createPartnerMutation, isLoading: createPartnerLoading } = useMutation<
    PartnerRegisterationValues,
    ErrorObject<PartnerRegisterationValues>,
    PartnerRegisterationValues
  >((formData: PartnerRegisterationValues) => partnerService.createPartner(formData), {
    onSuccess: () => {
      trackEvent(GAEvent.RegistrationSuccess);
      if (makePayment) {
        setValues((previous) => ({
          ...previous,
          showNextForm: false,
          showProfileType: false,
          showPasswordForm: false,
          showCardHolderForm: true,
          currentStep: '4/5',
        }));
      } else {
        toast.success(REGISTRATION_SUCCESS, TOAST_DURATION);
        navigate('/partner/auth/login');
      }
    },
    onError: (error) => {
      toast.error(REGISTRATION_FAILED, TOAST_DURATION);
      trackEvent(GAEvent.RegistrationFailed);
      Sentry.captureException(error);
    },
  });

  const validateForm = (formValues: PartnerRegisterationValues) => {
    const result = partnerRegisterationPasswordFormSchema.safeParse(formValues);

    if (!result.success) {
      result.error.errors.forEach((err) => {
        if (err.path[0]) {
          setFieldError(err.path[0] as string, err.message);
        }
      });
    }

    return result.success;
  };

  const onSubmit = () => {
    toast.dismiss();

    if (data?.isPartner) {
      setErrors({ email: EMAIL_ALREADY_EXIST });
      return;
    }

    if (!isFormFilled()) {
      toast.error(FILL_REQUIRED_INPUTS, TOAST_DURATION);
      return;
    }

    const isValid = validateForm(values);
    if (isValid) {
      createPartnerMutation(values);
    }
  };

  const goBack = () =>
    setValues((previous) => ({
      ...previous,
      showNextForm: true,
      showProfileType: false,
      showPasswordForm: false,
      currentStep: '3/5',
    }));

  return (
    <div className="relative mt-10 border-t-2 border-slate-300">
      <Button
        style="top-[-190px] absolute rounded bg-lightGrey p-1"
        onClick={goBack}
        variant="text"
      >
        <ArrowLeftIcon className="w-5 text-black" />
      </Button>
      <div>
        <p className="mt-5 mb-5 text-base leading-loose">
          Create a password that contains more than six characters that is a combination of numbers,
          letters and special characters (!$@%).
        </p>
        <SecureTextField
          label="New Password"
          placeholder="***"
          name="newPassword"
          error={errors.newPassword}
        />
        <SecureTextField
          label="Confirm Password"
          placeholder="***"
          name="confirmPassword"
          error={errors.confirmPassword}
        />
        <div className="my-5 flex items-center">
          <input
            type="checkbox"
            id="makePayment"
            checked={makePayment}
            className="my-auto rounded text-gold outline-none"
            onChange={(e) => setMakePayment(e.target.checked)}
          />
          <label htmlFor="makePayment" className="ml-2 text-lg font-semibold">
            Would you like to make a donation towards your registration?
          </label>
        </div>
      </div>
      <Button
        isDisabled={getIsPartnerLoading || createPartnerLoading || !isFormFilled()}
        isLoading={getIsPartnerLoading || createPartnerLoading}
        style={`bg-${
          isFormFilled() ? 'gold' : 'gray-300'
        } text-white py-2.5 mx-auto w-full rounded-md`}
        onClick={onSubmit}
      >
        SUBMIT
      </Button>
    </div>
  );
};
