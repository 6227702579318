import { Tab } from '@headlessui/react';
import map from 'lodash/map';
import React, { Fragment } from 'react';

import { GoldMember, BrozenMember, SilverMember, PlatinumMember } from '@project/assets';

const tabs = [
  { imageSrc: BrozenMember },
  { imageSrc: SilverMember },
  { imageSrc: GoldMember },
  { imageSrc: PlatinumMember },
];

const membershipBenefits = [
  {
    title: 'BRONZE MEMBERSHIP BENEFITS',
    background: '#EDE0DA',
    listItems: [
      'FREE business feature in the KFMI Newsletter',
      '5% Discounted rates on all KFMI merchandise',
      'Exclusive monthly prayers with the Prophet',
      'Exclusive calls from the Office of the Prophet',
      'VIP treatment at all KFMI events',
      'Receive an exclusive hardcopy of our newsletter',
      '15% Discounted rates for Online IVP + ISOS events',
    ],
  },
  {
    title: 'SILVER MEMBERSHIP BENEFITS',
    background: '#F5F5F5',
    listItems: [
      'FREE business feature in the KFMI Newsletter',
      '10% Discounted Rates on all KFMI merchandise',
      'Exclusive monthly prayers with the Prophet',
      'Exclusive calls from the Office of the Prophet',
      'VIP treatment at all KFMI events',
      'Receive an exclusive hardcopy of our newsletter',
      '25% Discounted rates for Online IVP + ISOS events',
    ],
  },
  {
    title: 'GOLD MEMBERSHIP BENEFITS',
    background: '#F5EFDE',
    listItems: [
      'FREE business feature in the KFMI Newsletter',
      '15% Discounted Rates on all KFMI merchandise',
      'Exclusive monthly prayers with the Prophet',
      'Exclusive calls from the Office of the Prophet',
      'VIP treatment at all KFMI events',
      'Receive an exclusive hardcopy of our newsletter',
      '50% Discounted rates for Online IVP + ISOS events',
    ],
  },
  {
    title: 'PLATINUM MEMBERSHIP BENEFITS',
    background: '#EFF1F4',
    listItems: [
      'FREE monthly meeting access',
      'FREE business feature in the KFMI Newsletter',
      '20% Discounted rates on all KFMI merchandise',
      'Exclusive monthly prayers with the Prophet',
      'Exclusive calls from the Office of the Prophet',
      'VIP treatment at all KFMI events',
      'Receive an exclusive hardcopy of our newsletter',
      'FREE Online IVP + ISOS Access',
    ],
  },
];

const DisclamerText = () => (
  <div>
    <p className="my-6 pt-1 text-center font-semibold underline">Disclaimer</p>
    <p className="mb-2 text-center font-medium">
      All benefits are subject to cardholders having redeemed their monthly contributions
    </p>
    <p className="text-center font-medium">
      Cardholder attendance to discounted events must be booked through KFMI
    </p>
  </div>
);

export const CardBenefits: React.FC = () => (
  <div className="mt-10 flex flex-col justify-center">
    <div className="flex flex-col">
      <div className="flex flex-col items-center">
        <p className="text-xl font-semibold text-black md:text-3xl">CARD HOLDER BENEFITS</p>
        <div className="mb-2 w-[250px] border-2 border-gold md:w-[400px]" />
      </div>
      <Tab.Group>
        <Tab.List className="mx-4 mt-10 flex flex-row flex-wrap rounded-xl bg-[#4a4a4a] sm:flex-nowrap md:mx-6">
          {map(
            tabs,
            (tab: { imageSrc: string | undefined }, index: React.Key | null | undefined) => (
              <Tab as={Fragment} key={index}>
                {({ selected }) => (
                  <div
                    className={
                      selected ? 'rounded-xl bg-[#D1B866] shadow-inner shadow-[#927C0C]' : ''
                    }
                  >
                    <img className="p-5 sm:p-2 xl:p-5" src={tab.imageSrc} />
                  </div>
                )}
              </Tab>
            ),
          )}
        </Tab.List>
        <Tab.Panels
          className={`mx-4 mt-6 mb-10 flex flex-col justify-start rounded-xl border-2 border-gold md:mx-6`}
        >
          {map(membershipBenefits, (benefit, index) => (
            <Tab.Panel key={index}>
              <div className="flex flex-col items-center justify-center py-4">
                <p className="mb-6 mt-4 text-center text-lg font-semibold tracking-wide underline decoration-gold decoration-2 underline-offset-2 md:text-xl">
                  {benefit.title}
                </p>
                <ul className="ml-8 list-disc space-y-2">
                  {benefit.listItems.map((item, _index) => (
                    <li key={_index}>{item}</li>
                  ))}
                </ul>
                <DisclamerText />
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  </div>
);
