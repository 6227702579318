import homeContentUrls from './home-content.urls';
import { getData } from '@project/services/utils';
import networkService from '../network-service/network.service';

const getHomeContents = () => {
  const url = homeContentUrls.getHomeContentsUrl();

  return networkService.get(url).then(getData);
};

export default { getHomeContents };
