import { useQuery } from '@tanstack/react-query';

import { OutstandingBalanceModel } from './outstanding-balance.model';
import { manualPaymentModel } from './manual-payment.model';
import { manualPaymentService } from '@project/services';

export const useGetManualPaymentQuery = (id: string) =>
  useQuery(['getManualPayment', id], () => manualPaymentService.getManualPayment(id), {
    select: manualPaymentModel,
  });

export const useGetOutstandingBalanceQuery = (referenceNumber: string, currency: string) =>
  useQuery(
    ['getOutstandingBalance', referenceNumber, currency],
    () => manualPaymentService.getOutstandingBalance(referenceNumber, currency),
    {
      select: OutstandingBalanceModel,
    },
  );
