import React from 'react';
import { FormikProps } from 'formik';
import { toast } from 'react-hot-toast';
import { Form } from '@codehesion-za/headless';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CompletePartialPaymentType,
  PaymentAmountField,
  FileUploader,
  TextField,
  Dropdown,
  Button,
} from '@project/components';
import {
  UploadProofOfPaymentSchema,
  UploadProofOfPaymentType,
  manualPaymentService,
} from '@project/services';
import { INCORRECT_REFERENCE, TOAST_DURATION } from '@project/helper';
import * as Sentry from '@sentry/react';
import { GAEvent, trackEvent } from '@project/services';

const BANKS = [
  { label: 'JP Morgan Chase Bank NA', value: 'JP Morgan Chase Bank NA' },
  { label: 'First National Bank', value: 'First National Bank' },
  { label: 'NatWest Bank', value: 'NatWest Bank' },
  { label: 'Eco Bank', value: 'Eco Bank' },
];

export const ProofofPaymentForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = {
    referenceNumber: (location?.state as CompletePartialPaymentType)?.referenceNumber ?? '',
    userBank: '',
    amountToPayCurrency:
      (location?.state as CompletePartialPaymentType)?.contributionCurrency ?? 'USD',
    amountToPay: (location?.state as CompletePartialPaymentType)?.contributionAmount ?? 0,
    proofOfPayment: '',
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(0);
    }, 1500);
  };

  const { mutateAsync } = useMutation(
    (formData: UploadProofOfPaymentType) => manualPaymentService.uploadProofOfPayment(formData),
    {
      onMutate: () => {
        // Track upload attempt
        trackEvent(GAEvent.PopUploadAttempt);
      },
      onSuccess: () => {
        toast.success('Proof of payment uploaded successfully', TOAST_DURATION);
        trackEvent(GAEvent.PopUploadSuccess);
        goBack();
      },
      onError: (error) => {
        if (error && String(error).includes(INCORRECT_REFERENCE)) {
          toast.error('Incorrect contribution reference number', TOAST_DURATION);
        } else {
          toast.error('Failed to upload proof of payment, please try again', TOAST_DURATION);
          trackEvent(GAEvent.PopUploadFailed);
          Sentry.captureException(error);
        }
      },
    },
  );

  const onSubmitForm = (formData: UploadProofOfPaymentType) => mutateAsync(formData);

  const FormComponents = ({
    values,
    isSubmitting,
    handleSubmit,
  }: FormikProps<UploadProofOfPaymentType>) => {
    const getCurrentValue = { label: values.userBank, value: values.userBank };

    return (
      <div className="space-y-5 rounded-md bg-white p-5 shadow-xl">
        <div className="w-full">
          <TextField
            name="referenceNumber"
            label="Contribution reference number"
            type="text"
            value={values.referenceNumber}
          />
        </div>
        <div className="w-full">
          <Dropdown
            fieldName="userBank"
            options={BANKS}
            value={getCurrentValue}
            label="Which account did you pay into?"
          />
        </div>
        <div className="w-full">
          <PaymentAmountField
            amountFieldName="amountToPay"
            currencyFieldName="amountToPayCurrency"
            selectedCurrency={values.amountToPayCurrency}
          />
        </div>
        <div className="flex flex-col pb-4">
          <div className="space-y-2">
            <h2 className="text-base">Upload proof of donation</h2>
            <p className="text-sm text-gray-500">
              Accepted file types: jpg, png, pdf, jpeg. Max. file size: 10 MB.
            </p>
            <div className="py-4">
              <FileUploader
                fieldName="proofOfPayment"
                selectedFile={values.proofOfPayment}
                acceptedFileTypes=".jpg, .png, .pdf, .jpeg"
                labelFor="popInput"
              />
            </div>
          </div>
        </div>
        <Button
          style="bg-gold text-white font-bold text-sm rounded-md py-3 w-full"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? 'Uploading...' : 'Upload'}
        </Button>
      </div>
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={UploadProofOfPaymentSchema}
      validateOnBlur
      validateOnChange
    />
  );
};
