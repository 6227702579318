import map from 'lodash/map';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

import { MeetingCard } from '@project/components/molecules';
import { useGetEventsQuery } from '@project/queries';
import { TopImage } from '@project/components/atoms';
import { useEventsStore } from '@project/stores';

export const MeetingsListPage: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetEventsQuery();
  const eventStore = useEventsStore();

  const eventsList = useMemo(
    () => (isNil(data?.events) ? [] : map(data?.events, (event) => event)),
    [isLoading],
  );

  const goToMeeting = (
    id: number,
    meetingName: string,
    type: string,
    indemnityFormId: number,
    eventType: string,
  ) => {
    eventStore.setEventType(type);
    eventStore.setindemnityFormId(indemnityFormId);
    //TODO: @Waleed we shouldn't be using localstorage for this, are these values used? use eventStore instead
    localStorage.setItem('eventType', type);
    localStorage.setItem('indemnityFormId', String(indemnityFormId));
    type === 'other'
      ? navigate(`/retreat/${id}`)
      : navigate(
          `/meeting-contributions/${id}?meeting_name=${meetingName}&indemnity_form_id=${indemnityFormId}&event_type=${eventType}`,
        );
  };

  return (
    <div className="mb-32 w-full">
      <TopImage />
      <div className="mt-10 pl-5 pr-5 md:mt-12 md:pl-24 md:pr-20">
        <div className="mb-5 md:mb-10">
          <h1 className="text-2xl font-semibold">Upcoming Events</h1>
          <div className="mt-1 w-20 rounded border-2 border-gold" />
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <MoonLoader color="#B58B00" />
          </div>
        ) : isEmpty(eventsList) ? (
          <div className="md:pl-10">
            <span>No meetings are currently happening.</span>
          </div>
        ) : (
          map(eventsList, (event) => (
            <div key={`${event.name}${event.startAt}${event.id}`} className="mb-5 lg:pl-10">
              <MeetingCard
                name={event.name}
                date={event.startAt}
                navFunction={() =>
                  goToMeeting(
                    event.id,
                    event.name,
                    event.eventType,
                    event.indemnityFormId ? event.indemnityFormId : 0,
                    event.eventType,
                  )
                }
                type={event.eventType}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
