import dayjs from 'dayjs';
import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';
import capitalize from 'lodash/capitalize';

import { MeetingCardProps } from './types';
import { Card } from '@project/components/atoms';

export const PartnersMeetingCard: React.FC<MeetingCardProps> = ({
  name,
  date,
  type,
  bg,
  startTime,
  endTime,
  id,
  hasRegistered,
  navFunction,
}) => {
  const truncatedName = name.slice(0, 20);

  const dateStyling = 'font-bask text-white md:text-2xl items-center';
  const detailsStyling = 'font-light leading-loose text-lg';

  return (
    <Card className="mb-6" key={id}>
      <div className="flex flex-row">
        <div
          className={`flex w-20 flex-col rounded-tl rounded-bl bg-${bg} pt-4 text-center md:pt-6`}
        >
          <span className={dateStyling}>{dayjs(date).format('DD')}</span>
          <span className={dateStyling}>{dayjs(date).format('MMM')}</span>
        </div>
        <div className="flex w-full flex-col md:flex-row">
          <div>
            <div className="flex flex-col pl-10 pt-3">
              {window.innerWidth >= 800 ? (
                <span className={detailsStyling}>{name}</span>
              ) : (
                <span className={detailsStyling}>{truncatedName}</span>
              )}
            </div>
            <div className="flex flex-col pl-10">
              <div className="mt-2 flex flex-row md:w-[200px]">
                <ClockIcon className="mr-2 w-4" />
                <span className={detailsStyling}>
                  {dayjs(startTime).format('HH:mm')} - {dayjs(endTime).format('HH:mm')}
                </span>
              </div>
              <div className="mb-2 flex flex-row items-center">
                <MapPinIcon className="mr-2 w-4" />
                <span className={detailsStyling}>{capitalize(type)}</span>
              </div>
            </div>
          </div>
          <div className="my-auto flex w-full min-w-fit justify-end pb-1">
            <button
              className={`mr-2 h-fit rounded md:w-fit bg-${bg} py-2 px-4 text-[10px] text-white xs:text-xs sm:px-4 md:mr-2`}
              onClick={navFunction}
            >
              {hasRegistered ? 'Registered' : 'Register Now'}
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};
