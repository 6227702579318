import dayjs from 'dayjs';
import find from 'lodash/find';
import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';

import { eventService } from '@project/services';
import {
  PartnersRetreatContributionFormHelper,
  MeetingContributionForm,
  LoadingContainer,
  Button,
} from '@project/components';

export const IndividualMeetingRegister: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [symbol, setSymbol] = useState('');
  const [minDonation, setMinDonation] = useState('');
  const [minDonationCurrency, setMinDonationCurrency] = useState('');

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const indemnityFormId = urlParams.get('indemnity_form_id');
  const eventType = urlParams.get('event_type');

  const goBack = () => navigate(-1);

  const { data: event, isLoading } = useQuery(
    ['getEvent', id],
    () => eventService.getEvent(id || ''),
    {
      onSuccess: (data) => {
        setMinDonationCurrency(data?.minDonationCurrency ?? 'USD');

        const minDonationItem = find(
          data?.minDonations ?? [],
          (minDonationsItem) => minDonationsItem.currency === minDonationCurrency,
        ) ?? { symbol: '$', currency: 'USD', minDonation: '0.00' };

        setMinDonation(minDonationItem.minDonation);
        setSymbol(minDonationItem.symbol);
      },
    },
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="grid h-screen lg:grid-cols-6">
        <div className="col-span-6 pt-20 md:px-4 lg:col-span-2 xl:px-14">
          <div className="flex flex-col">
            <div className="md:md-0 ml-4 flex flex-col">
              <div className="flex flex-row">
                <Button style="bg-gray-200 rounded" onClick={goBack}>
                  <ChevronLeftIcon className="w-10" />
                </Button>
                <div className="ml-4 md:ml-14">
                  <p className="text-lg font-semibold xl:text-2xl">{event?.name}</p>
                  <div className="w-32 border-b-2 border-gray-300" />
                </div>
              </div>
              <div className="mt-6 ml-14 md:ml-24">
                <span className="mb-4">
                  {dayjs(event?.startAt).format('DD MMMM YYYY')} GMT {event?.gmtTimezone}
                </span>
                <div className="mt-2 mb-2 flex flex-row">
                  <ClockIcon className="mr-2 w-4" />
                  <span>
                    {dayjs(event?.startAt).format('HH:mm')} - {dayjs(event?.endAt).format('HH:mm')}
                  </span>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <MapPinIcon className="mr-2 w-4" />
                  <span>{capitalize(event?.eventType)}</span>
                </div>
                {minDonation && symbol && (
                  <p className="mb-4">
                    <span>Minimum event donation: </span> {symbol} {minDonation}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 justify-center bg-partners-gray px-2 py-20 md:px-14 lg:col-span-4">
          {event?.eventType === 'other' ? (
            <PartnersRetreatContributionFormHelper id={id ?? '-1'} currency={minDonationCurrency} />
          ) : (
            <MeetingContributionForm
              id={id ?? '-1'}
              isPublic={false}
              indemnityFormId={Number(indemnityFormId) ?? 0}
              eventType={eventType ?? ''}
            />
          )}
        </div>
      </div>
    </LoadingContainer>
  );
};
