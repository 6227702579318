import {
  CreatePartnerDto,
  UpdatePartnerDto,
  ChangePartnersPasswordDto,
} from './create-partner.dto';
import partnerUrls from './partner.urls';
import { getData, rejectPromise } from '../../utils';
import { authNetworkService } from '@project/services';
import { PartnerRegisterationValues } from '@project/schemas';
import networkService from '../network-service/network.service';
import { ChangePartnersPasswordValues, PartnersProfileValues } from '@project/components';

const createPartner = (data: PartnerRegisterationValues) => {
  const url = partnerUrls.createPartnerUrl();
  const dto = CreatePartnerDto(data);

  return networkService.post(url, dto).then(getData);
};

const getPartnersStats = () => {
  const url = partnerUrls.getPartnersStats();

  return authNetworkService.get(url).then(getData);
};

const getCurrentPartner = () => {
  const url = partnerUrls.getCurrentPartner();

  return authNetworkService.get(url).then(getData);
};

const editCurrentPartner = (data: PartnersProfileValues) => {
  const url = partnerUrls.editCurrentPartner();
  const dto = UpdatePartnerDto(data);

  return authNetworkService.patch(url, dto).then(getData);
};

const changePartnersPassword = (data: ChangePartnersPasswordValues) => {
  const url = partnerUrls.changePartnersPassword();
  const dto = ChangePartnersPasswordDto(data);

  return authNetworkService.patch(url, dto).then(getData);
};

const getPartnerDonationSummary = (search: string) => {
  const url = partnerUrls.getPartnerDonationSummary(search);

  return authNetworkService.get(url).then(getData);
};

const getPartnersArticles = (search?: string, page?: number, per?: number) => {
  const url = partnerUrls.getArticles(search, page, per);

  return authNetworkService.get(url).then(getData);
};

const getSignalPartnersDonationInfo = (id: string, search: string, from: string, to: string) => {
  const url = partnerUrls.getSignalPartnersDonationInfo(id, search, from, to);

  return authNetworkService.get(url).then(getData);
};

const getPartnersEvents = (search: string) => {
  const url = partnerUrls.getPartnersEvents(search);

  return authNetworkService.get(url).then(getData);
};

const getIsPartner = (email: string) => {
  const parsedEmail = encodeURIComponent(email);
  const url = partnerUrls.getIsPartnerUrl(parsedEmail);

  return networkService.get(url).then(getData);
};

const deletePartner = () => {
  const url = partnerUrls.deletePartnerUrl();

  return authNetworkService.delete(url);
};

const getBillingDetails = () => {
  const url = partnerUrls.billingDetailsUrl();

  return authNetworkService.get(url).then(getData).catch(rejectPromise);
};

const getDonorCategories = () => {
  const url = partnerUrls.donorCategoriesUrl();

  return authNetworkService.get(url).then(getData).catch(rejectPromise);
};

export default {
  getIsPartner,
  createPartner,
  deletePartner,
  getPartnersStats,
  getBillingDetails,
  getCurrentPartner,
  getPartnersEvents,
  editCurrentPartner,
  getPartnersArticles,
  changePartnersPassword,
  getPartnerDonationSummary,
  getSignalPartnersDonationInfo,
  getDonorCategories,
};
