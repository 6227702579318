import dayjs from 'dayjs';
import map from 'lodash/map';
import { CSVLink } from 'react-csv';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FlatList } from '@codehesion-za/headless';

import { useGetPartnerSingleDonationInfo } from '@project/queries';
import { IndividualHistoryProps } from './types';
import {
  DatePicker,
  LoadingContainer,
  SearchField,
  DonationTypeInfoCard,
  IndividualHistory,
} from '@project/components';

const RenderItem = ({ item, index }: { item: IndividualHistoryProps; index: number }) => (
  <IndividualHistory
    key={`${item?.donationId}-${index}`}
    donatedOn={dayjs(item?.donatedOn ?? '').format('YYYY-MM-DD')}
    totalDonated={item?.totalDonated ?? ''}
    donationId={item?.donationId ?? ''}
  />
);

const EmptyList = <span>No donation history to show</span>;

export const PartnersIndividualDonationHistory: React.FC = () => {
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const { data, isLoading } = useGetPartnerSingleDonationInfo(id ?? '', search, fromDate, toDate);

  const csvData = map(data?.individualDonations, (item) => ({
    formattedDonatedOn: dayjs(item?.donatedOn ?? '').format('YYYY-MM-DD'),
    totalDonated: item?.totalDonated ?? '',
  }));

  const csvHeaders = [
    { label: 'Date', key: 'formattedDonatedOn' },
    { label: 'Amount', key: 'totalDonated' },
  ];

  return (
    <div className="grid h-screen flex-1 grid-cols-7">
      <div className="col-span-7 mx-6 mb-10 bg-white pt-16 md:mx-10 lg:col-span-2 lg:pb-0">
        <DonationTypeInfoCard
          header={data?.name ?? ''}
          content={data?.content ?? ''}
          src={data?.logo ?? ''}
          isLoading={isLoading}
        />
      </div>
      <div className="col-span-7 bg-[#F5F5F5] lg:col-span-3 lg:pl-4">
        <div className="w-sceen my-16 mx-4 flex flex-col md:mx-6">
          <p className="text-lg">Donations</p>
          <div className="mb-10 w-24 border-b-2 border-lightGrey" />
          <div className="md:pr-32">
            <div>
              <SearchField
                className="mb-10 rounded-full border-[#D3D3D3] bg-[#EAEAEA]"
                placeholder="Search"
                onChange={setSearch}
              />
              <div className="grid grid-cols-2 space-x-2">
                <DatePicker onChange={setFromDate} label="From:" />
                <DatePicker onChange={setToDate} label="To:" />
              </div>
            </div>
          </div>
          <LoadingContainer isLoading={isLoading}>
            <div className="mt-14">
              <FlatList
                items={data?.individualDonations ?? []}
                renderItem={RenderItem}
                ListEmptyComponent={EmptyList}
              />
            </div>
          </LoadingContainer>
        </div>
      </div>
      <div className="col-span-7 bg-[#F5F5F5] lg:col-span-2">
        <div className="flex justify-center self-end py-10 md:pr-16 lg:mt-16 lg:justify-end">
          <CSVLink
            headers={csvHeaders}
            filename={`Donation History for ${data?.name} ${dayjs().format('DD-MM-YYYY')}`}
            data={csvData ?? []}
            className="flex rounded bg-gold py-2 px-10 text-center hover:bg-gold"
          >
            <p className="w-40 py-1.5 text-sm font-bold text-white">CSV EXPORT</p>
          </CSVLink>
        </div>
      </div>
    </div>
  );
};
