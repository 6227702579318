import homeContentUrls from './donation-types.urls';
import { getData } from '@project/services/utils';
import networkService from '../network-service/network.service';
import { getDonationSumsUrl } from './donation-types.urls';
import { authNetworkService } from '@project/services';

const getDonationTypes = () => {
  const url = homeContentUrls.getAllDonationTypesUrl();

  return networkService.get(url).then(getData);
};

export const getDonationSums = (fromDate: string, toDate: string) => {
  const url = getDonationSumsUrl(fromDate, toDate);
  return authNetworkService.get(url).then((data) => data);
};

export default { getDonationTypes, getDonationSums };
