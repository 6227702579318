import { Checkbox, FlatList } from '@codehesion-za/headless';

import { PrayerRequestCheckboxProps, PrayerRequestCheckboxItemType } from './types';

const RenderItem = ({ item, index }: { item: PrayerRequestCheckboxItemType; index: number }) => (
  <div key={`${index}-${item.label}`} className="mb-6 items-center md:ml-6 lg:ml-0">
    <Checkbox id={item.label} name={item.fieldName} value={item.value} className="text-gold" />
    <label htmlFor={item.label} className="pl-1">
      {item.label}
    </label>
  </div>
);

const EmptyList = <span>No Prayer Requests</span>;

export const PrayerRequestCheckboxes: React.FC<PrayerRequestCheckboxProps> = ({ fieldName }) => {
  const prayerRequestOptions = [
    {
      label: 'Healing',
      value: 'healing',
      fieldName: fieldName,
    },
    {
      label: 'Financial Breakthrough',
      value: 'financial_breakthrough',
      fieldName: fieldName,
    },
    {
      label: 'Academic & Career',
      value: 'academic_career',
      fieldName: fieldName,
    },
    {
      label: 'Fruit of the Womb',
      value: 'fruit_of_the_womb',
      fieldName: fieldName,
    },
    {
      label: 'Marriage',
      value: 'marriage',
      fieldName: fieldName,
    },
    {
      label: 'Family',
      value: 'family',
      fieldName: fieldName,
    },
  ];

  return (
    <div className="flex flex-col text-sm">
      <p className="py-4 font-semibold">Please indicate if you have a prayer request:</p>
      <div className="mt-4 flex max-w-[600px] flex-col flex-wrap md:flex-row lg:max-w-[900px] lg:flex-nowrap lg:justify-evenly lg:space-x-4">
        <FlatList
          items={prayerRequestOptions}
          renderItem={RenderItem}
          ListEmptyComponent={EmptyList}
        />
      </div>
    </div>
  );
};
