import React from 'react';
import { TextInput, FormError } from '@codehesion-za/headless';

import { TextInputPropsType } from './types';

export const TextField: React.FC<TextInputPropsType> = ({
  name,
  label,
  labelHelp,
  required,
  errorText,
  ...rest
}) => {
  const Required = () => (required ? <span> *</span> : null);

  return (
    <div className="relative mt-2 flex w-full flex-col">
      <label className="mb-1" htmlFor={name}>
        {label}
        <Required />
      </label>
      {labelHelp && <span className="text-xs">{labelHelp}</span>}
      <TextInput name={name} {...rest} className={`rounded-md border-gray-300 ${rest.className}`} />
      <span className="text-red-600">
        <FormError name={name} errorText={errorText} />
      </span>
    </div>
  );
};
