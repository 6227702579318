import articleUrls from './article.urls';
import { getData } from '@project/services/utils';
import { authNetworkService } from '@project/services';

const getArticle = (id: string) => {
  const url = articleUrls.getArticleUrl(id);

  return authNetworkService.get(url).then(getData);
};

export default { getArticle };
