import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { eventService } from '@project/services';
import {
  RetreatContributionForm,
  MeetingContributionForm,
  TopImage,
  Button,
  LoadingContainer,
} from '@project/components';

const injectLinks = (text: string) => {
  const urlRegex = /https?:\/\/[^\s<]+/g;
  const replacedText = text.replace(urlRegex, (match) => {
    return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });

  return replacedText;
};

const convertToHtml = (value?: string) => {
  if (!value) return null;
  return parse(value);
};

export const MeetingContributionsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [minDonation, setMinDonation] = useState('');
  const [symbol, setSymbol] = useState('');
  const [description, setDescription] = useState('');

  const goBack = () => {
    navigate(-1);
  };

  const { data: event, isLoading } = useQuery(
    ['getPublicEvent', id],
    () => eventService.getPublicEvent(id || ''),
    {
      onSuccess: (data) => {
        setDescription(data.charity ?? '');
        for (let i = 0; i < data.minDonations.length; i++) {
          const donation = data.minDonations[i];
          if (donation.currency === data.minDonationCurrency) {
            setMinDonation(donation.minDonation);
            setSymbol(donation.symbol);
            break;
          }
        }
      },
    },
  );

  const meetingName = urlParams.get('meeting_name');
  const indemnityFormId = urlParams.get('indemnity_form_id');
  const eventType = urlParams.get('event_type');

  const eventDetailsSpacing = 'mb-4';
  const eventDetailsText = 'font-semibold';

  return (
    <div className="mb-32 flex flex-col">
      <TopImage />
      <LoadingContainer isLoading={isLoading}>
        <div className="mt-8 ml-8 xl:ml-10">
          <Button onClick={goBack} variant="text">
            <ArrowLeftIcon className="w-5 text-black" />
          </Button>
        </div>
        <div className="mt-2 flex flex-row">
          <div className="ml-7 w-fit xl:ml-28">
            <p className="text-lg font-semibold md:text-2xl">Meeting Contribution</p>
            <div className="mt-1 mb-3 w-1/4 border-b-2 border-b-gold" />
            <p className={eventDetailsSpacing}>
              <span className={eventDetailsText}>Meeting Name: </span>
              {meetingName}
            </p>
            <p className={eventDetailsSpacing}>
              <span className={eventDetailsText}>Meeting Description: </span>
              {convertToHtml(injectLinks(description))}
            </p>
            <p className={eventDetailsSpacing}>
              <span className={eventDetailsText}> Date and Time:</span> {event?.startAt} -{' '}
              {dayjs(event?.endAt).format('DD MMMM YYYY h:mm A')} GMT {event?.gmtTimezone ?? 2}
            </p>
            <div>
              {event?.eventType === 'In Person' ||
                (event?.eventType === 'other' && (
                  <p className={eventDetailsSpacing}>
                    <span className={eventDetailsText}>Place:</span> {event?.eventType} -{' '}
                    {event?.link}
                  </p>
                ))}
            </div>
            {minDonation && symbol && (
              <p className={eventDetailsSpacing}>
                <span className={eventDetailsText}>Price per person: </span> {symbol} {minDonation}
              </p>
            )}
          </div>
        </div>
        <div className="mx-3 mt-3 sm:ml-6 md:ml-6 xl:ml-28">
          {state === 'FROM_RETREAT_PAGE' ? (
            <RetreatContributionForm id={id ?? '-1'} isPublic={true} />
          ) : (
            <MeetingContributionForm
              id={id ?? '-1'}
              isPublic={true}
              indemnityFormId={Number(indemnityFormId) ?? 0}
              eventType={eventType ?? ''}
            />
          )}
        </div>
      </LoadingContainer>
    </div>
  );
};
