import map from 'lodash/map';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ActivityLoader, Button } from '@project/components';
import { useGetHomeContentQuery } from '@project/queries';
import { getIsLoggedIn } from '@project/helper';
import { SlidesProps } from './types';
import './carousel.css';

const Slides = ({ heading, subHeading, buttonText, path, src }: SlidesProps) => {
  const truncatedHeading = heading.slice(0, 50);
  const truncatedSubHeading = subHeading.slice(0, 100);

  const goToPath = () => (window.location.href = path);
  const isVideo = src.includes(
    'response-content-type=video%2Fmp4' ||
      'response-content-type=video%2Favi' ||
      'response-content-type=video%2Fmov',
  );

  const videoStyle: React.CSSProperties = {
    backgroundColor: 'black',
  };

  return (
    <div className="mb-10 flex h-full flex-col justify-between md:mb-14">
      <div className="relative h-[250px] md:h-[700px]">
        {isVideo ? (
          <video className="h-full w-full" autoPlay controls muted loop style={videoStyle}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img className="h-full object-cover" src={src} alt="An image in the carousel" />
        )}
        <div className="absolute bottom-0 left-0 pl-10 pb-4 md:pb-20 md:pl-20">
          <div className="flex w-[200px] flex-col justify-center text-left md:w-[600px]">
            <p className="text-sm font-semibold tracking-wide text-white md:mt-1 md:text-xl lg:text-4xl">
              {truncatedHeading}
            </p>
            <p className="text-sm text-[10px] text-white md:text-lg lg:text-2xl">
              {truncatedSubHeading}
            </p>
            <div className="rounded md:py-2">
              <Button
                style="bg-white py-1.5 md:py-2 px-3 rounded-md mt-1 bg-gold"
                onClick={goToPath}
              >
                <p className="text-[10px] text-white md:text-sm lg:text-xl">{buttonText}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HomePageCarousel: React.FC = () => {
  const { data, isLoading } = useGetHomeContentQuery();
  const isLoggedIn = getIsLoggedIn();

  const visibleSlides = useMemo(
    () =>
      filter(
        data ?? [],
        (item) =>
          (isLoggedIn || item?.entireWebsite) &&
          !isNil(item?.featureImage) &&
          !isEmpty(item.featureImage),
      ),
    [isLoading],
  );

  return (
    <div className="border-b-4 border-gold bg-white">
      <div className="mx-auto flex items-center justify-center">
        <ActivityLoader isLoading={isLoading} />
      </div>
      {!isEmpty(visibleSlides) && (
        <Carousel
          autoPlay={true}
          showThumbs={true}
          showStatus={false}
          showIndicators={true}
          infiniteLoop={true}
          showArrows={true}
          emulateTouch={true}
          interval={5000}
          autoFocus={true}
          className="home-carousel"
        >
          {map(visibleSlides, (item, index) => (
            <div className="z-20 overflow-y-hidden" key={`div-${index}`}>
              <Slides
                key={`slide-${index}`}
                heading={item?.heading ?? 'Article Heading'}
                subHeading={item?.subHeading ?? 'Article Sub Heading'}
                buttonText={item?.buttonType ?? 'Button Text'}
                path={item?.link ?? ''}
                src={item?.featureImage ?? 'src/assets/images/scroll-1.png'}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};
