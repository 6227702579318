import { useQuery } from '@tanstack/react-query';
import React from 'react';
import map from 'lodash/map';
import { useNavigate } from 'react-router-dom';

import { partnerService, EventsModel } from '@project/services';
import { PartnersMeetingCard } from '../partners-meeting-card';

export const PartnersUpcomingMeetings: React.FC = () => {
  const navigate = useNavigate();
  const search = '';

  const { data: events } = useQuery(
    ['getPartnersEvents'],
    () => partnerService.getPartnersEvents(search),
    {
      select: (data) => EventsModel(data),
    },
  );

  const navigateToEvents = () => navigate('/partner/meetings');

  return (
    <div className="mt-4 mb-10 flex flex-col gap-y-4">
      {map(events?.events.slice(0, 4), (item) => (
        <PartnersMeetingCard
          name={item?.name}
          date={item?.startAt}
          type={item?.eventType}
          startTime={item?.startAt}
          endTime={item?.endAt ?? ''}
          bg={item?.hasRegistered ? 'gold' : 'black'}
          hasRegistered={item?.hasRegistered}
          id={item?.id}
          navFunction={navigateToEvents}
        />
      ))}
    </div>
  );
};
