import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { ExchangeCurrencyModel } from './exchange-currency.model';
import { paymentService } from '@project/services';

const toastDuration = { duration: 3000 };

export const useGetExchangeCurrencyQuery = (
  amount: number,
  amountCurrency: string,
  requestedCurrency: string,
) =>
  useQuery(
    ['getExchangeCurrency', amount, amountCurrency, requestedCurrency],
    () => paymentService.exchangeCurrency(amount, amountCurrency, requestedCurrency),
    {
      select: ExchangeCurrencyModel,
      onError: () => toast.error('Could not get converted amount', toastDuration),
    },
  );
