import { getData } from '@project/services/utils';
import { networkService } from '@project/services';
import membershipRenewalUrls from './membership-renewal.urls';

const getMembershipRenewal = () => {
  const url = membershipRenewalUrls.getMembershipRenewalUrl();

  return networkService.get(url).then(getData);
};

export default {
  getMembershipRenewal,
};
