import isNil from 'lodash/isNil';
import { toast } from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { PaymentIntentData, PaymentIntentRequest, paymentService } from '@project/services';
import { Button, PaymentAmountField } from '@project/components';
import { useGetExchangeCurrencyQuery } from '@project/queries';
import { PartnerRegisterationValues } from '@project/schemas';
import { ErrorObject } from '@codehesion-za/headless';
import {
  FAILED_TO_PROCESS_MSG,
  FILL_REQUIRED_INPUTS,
  PAYMENT_INVALID_AMOUNT,
  TOAST_DURATION,
} from '@project/helper';
import * as Sentry from '@sentry/react';

export const PartnersCardholderForm: React.FC = () => {
  const { isSubmitting, values, setValues } = useFormikContext<PartnerRegisterationValues>();

  const { data: currencyExchange, isLoading: currencyExchangeLoading } =
    useGetExchangeCurrencyQuery(1, 'usd', values.donationCurrency);

  const isDonationAmountValid = useCallback(
    () => Number(values.donationAmount) >= (currencyExchange?.convertedAmount ?? 200),
    [values.donationAmount, currencyExchange?.convertedAmount],
  );

  const isFormFilled = useCallback(
    () => !isEmpty(values.donationCurrency) && !isNil(values.donationAmount),
    [values],
  );

  const { mutateAsync: createPaymentIntent, isLoading } = useMutation<
    PaymentIntentData,
    ErrorObject<PaymentIntentData>,
    PaymentIntentRequest
  >((data: PaymentIntentRequest) =>
    paymentService.createIntent(
      undefined,
      toString(data.donationAmount),
      data.donationCurrency,
      data.donationType,
      data.donationType,
    ),
  );

  const onSubmitForm = () => {
    if (!isFormFilled()) {
      toast.error(FILL_REQUIRED_INPUTS, TOAST_DURATION);
      return;
    }
    if (!isDonationAmountValid()) {
      toast.error(PAYMENT_INVALID_AMOUNT, TOAST_DURATION);
      return;
    }

    createPaymentIntent(
      {
        donationAmount: values.donationAmount,
        donationCurrency: values.donationCurrency,
        donationType: values.donationType,
      },
      {
        onSuccess: (response) => {
          if (!response.client_secret || !response.id) {
            toast.error(FAILED_TO_PROCESS_MSG, TOAST_DURATION);
            Sentry.captureException('Failed to create Card-Holder payment intent');
            return;
          }
          setValues((previous) => ({
            ...previous,
            stripeClientSecret: response.client_secret,
            stripeId: response.id,
            showNextForm: false,
            showProfileType: false,
            showCardHolderForm: false,
            showBillingForm: true,
            currentStep: '4/5',
          }));
        },
        onError: (error) => {
          Sentry.captureException(error);
          toast.error(FAILED_TO_PROCESS_MSG, TOAST_DURATION);
        },
      },
    );
  };

  return (
    <div className="relative mt-10 border-t-2 border-slate-300">
      <div className="mt-10 ">
        <p className="mt-6 mb-2 text-lg font-semibold">
          Please Enter a Donation Amount to become a Partner
        </p>
        <div className="mt-5">
          <PaymentAmountField
            amountFieldName="donationAmount"
            currencyFieldName="donationCurrency"
            selectedCurrency={values.donationCurrency ?? 'USD'}
          />
          {!isDonationAmountValid() && !currencyExchangeLoading && (
            <p className="text-base text-red-600">
              Minimum donation amount for this currency is {currencyExchange?.convertedAmount}{' '}
              {values.donationCurrency}
            </p>
          )}
        </div>
        <Button
          isDisabled={
            isLoading ||
            currencyExchangeLoading ||
            isSubmitting ||
            !isFormFilled() ||
            !isDonationAmountValid()
          }
          style={`bg-${
            isFormFilled() ? 'gold' : 'gray-300'
          } text-white py-2.5 justify-end w-full rounded-md mt-5`}
          onClick={onSubmitForm}
        >
          {isLoading || currencyExchangeLoading ? 'Loading...' : 'Next'}
        </Button>
      </div>
    </div>
  );
};
