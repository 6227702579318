import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import { Button } from '@project/components';

export const DonationTypeCard = ({
  header,
  id,
  logo,
}: {
  header: string;
  id: string;
  logo: string;
}) => {
  const navigate = useNavigate();

  const handleViewIndividualDonationHistory = () => navigate(`/partner/charities/${id}`);

  return (
    <div
      key={id}
      className="mb-6 flex flex-row items-center justify-between rounded-lg bg-white p-2 shadow-md shadow-slate-200"
    >
      <div className="flex flex-row items-center">
        <img className="w-20" src={logo} />

        <p className="ml-8 tracking-wide text-black sm:text-lg">{header}</p>
      </div>
      <Button onClick={handleViewIndividualDonationHistory} variant="text">
        <ArrowRightIcon className="mr-4 w-7 text-black" />
      </Button>
    </div>
  );
};
