import isNil from 'lodash/isNil';

import networkService from '../network-service/network.service';
import { intentDonationDto, paymentDto } from './payment.dto';
import { BillingValues } from '@project/components';
import paymentUrls from './payment.urls';
import { getData } from '../../utils';

const createIntent = (
  eventId?: number | undefined,
  amount?: string,
  currency?: string,
  donationType?: string,
  paymentType?: string,
  prayerRequests?: string,
) => {
  const url = paymentUrls.createIntentUrl();
  const dto = isNil(eventId)
    ? intentDonationDto({ amount, currency, donationType, paymentType, prayerRequests })
    : intentDonationDto({ eventId, amount, currency, donationType, paymentType, prayerRequests });

  return networkService.post(url, dto).then(getData);
};

const completePayment = (intentId: string, user: BillingValues) => {
  const url = paymentUrls.completePaymentUrl();
  const dto = paymentDto(intentId, user);

  return networkService.post(url, dto).then(getData);
};

const exchangeCurrency = (amount: number, amountCurrency: string, requestedCurrency: string) => {
  const url = paymentUrls.exchangeCurrencyUrl(amount, amountCurrency, requestedCurrency);

  return networkService.get(url).then(getData);
};

export default { createIntent, completePayment, exchangeCurrency };
