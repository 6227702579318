import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { TopImage } from '@project/components/atoms';
import { Footer } from '@project/components/molecules';
import { IndemityForm } from '@project/components/organisms';

export const IndemityPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  useEffect(() => {
    queryClient.invalidateQueries(['getPaymentIntent']);
  }, []);

  return (
    <div>
      <TopImage />
      <IndemityForm id={Number(id)} />
      <div className="bottom-0">
        <Footer />
      </div>
    </div>
  );
};
