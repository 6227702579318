import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

import { Button, ResetPasswordForm } from '@project/components';
import { Logo } from '@project/assets';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const onBack = () => navigate('/partner/auth/login');

  return (
    <div className="auth-page">
      <div className="auth-form-container">
        <div className="absolute left-4">
          <Button variant="text" onClick={onBack} style="auth-back-button">
            <ChevronLeftIcon className="h-6" />
          </Button>
        </div>
        <div className="auth-form-logo">
          <img src={Logo} alt="logo" className="auth-image" />
          <h1 className="auth-form-heading">New Password</h1>
        </div>
        <p className="mx-auto pt-2 pb-10 text-center text-xs font-light">
          Create a password that contains more than six characters that is a combination of numbers,
          letters and special characters (!$@%).
        </p>
        <ResetPasswordForm />
      </div>
    </div>
  );
};
