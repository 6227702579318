import {
  EasterCape,
  AlexFeature,
  Alex2,
  Alex3,
  Alex4,
  Alex5,
  Alex6,
  BloemFeature,
  Bloem1,
  Bloem2,
  Bloem3,
  Bloem4,
  Bloem5,
  Bloem6,
  Bloem7,
  WelkomFeature,
  WelKom1,
  WelKom2,
  WelKom3,
  WelKom4,
  WelKom5,
  WelKom6,
  WelKom7,
  QwaqwaFeature,
  Qw1,
  Qw2,
  Qw3,
  Qw4,
  Qw5,
  RustFeature,
  Rust1,
  Rust2,
  Rust3,
  Rust4,
  Kemp1,
  Kemp2,
  Kemp3,
  Kemp4,
  Kemp5,
  Kemp6,
  Kemp7,
  Krug1,
  Krug2,
  Krug3,
  Krug4,
  Krug5,
  Krug6,
  Krug7,
  Mid1,
  Mid2,
  Mid3,
  Mid4,
  Rose1,
  Rose2,
  Rose3,
  Rose4,
  Rose5,
  Rose6,
  Rose7,
  Sand1,
  Sand2,
  Sand3,
  Sand4,
  Sand5,
  Sand6,
  Pretoria1,
  Pretoria2,
  Pretoria3,
  Pretoria4,
  Pretoria5,
  Pretoria6,
  Pretoria7,
  Soweto1,
  Soweto2,
  Soweto3,
  Soweto4,
  Soweto5,
  Soweto6,
  Soweto7,
  Tem1,
  Tem2,
  Tem3,
  Tem4,
  Tem5,
  Tem6,
  Tem7,
  KZN_1,
  KZN_2,
  KZN_3,
  KZN_4,
  KZN_5,
  KZN_6,
  KZN_7,
  KZN1,
  KZN2,
  KZN3,
  KZN4,
  KZN5,
  KZN6,
  KZN7,
  Lovo1,
  Lovo2,
  Lovo3,
  Lovo4,
  Lovo5,
  Lovo6,
  Lovo7,
  Lovo8,
  Lim1,
  Lim2,
  Lim3,
  Lim4,
  Lim5,
  Lim6,
  Lim7,
  Lim8,
  Mp1,
  Mp2,
  Mp3,
  Mp4,
  Mpu1,
  Mpu2,
  Mpu3,
  Mpu4,
  Mpu5,
  Mpu6,
  Mpu7,
  Mpu8,
  Mpu9,
  Mpu10,
  Mpu11,
  West1,
  West2,
  West3,
  West4,
  West5,
  West6,
  Zim2,
  Zim1,
  Bir1,
  Bir2,
  Bir3,
  Bir4,
  Bed1,
  Bed2,
  Bed3,
  Bed4,
  Bed5,
  Bed6,
  Lon1,
  Lon2,
  Lon3,
  Lon4,
  Lon5,
  Lon6,
  Blantyre1,
  Blantyre2,
  Blantyre3,
  Chidedza1,
  Chidedza2,
  Chidedza3,
  Chidedza4,
  Chidedza5,
  Chidedza6,
  Chidedza7,
  Chidedza8,
  Chikondi1,
  Chikondi2,
  Chikondi3,
  Chikondi4,
  Chikondi5,
  Freddy1,
  Freddy2,
  Freddy3,
  Freddy4,
  Freddy5,
  Freddy6,
  MalawiMain,
  Mtandire1,
  Mtandire2,
  Mtandire3,
  Mtandire4,
  Mtandire5,
  Mtandire6,
  Mtandire7,
  Mtandire8,
  Lilongwe1,
  Lilongwe2,
  Lilongwe3,
  Lilongwe4,
  Lilongwe5,
  Lilongwe6,
  Lilongwe7,
  Kamuzu1,
  Kamuzu2,
  Kamuzu3,
  Kamuzu4,
  Kamuzu5,
  Kamuzu6,
  Kamuzu7,
  Mulanje1,
  Mulanje2,
  Mulanje3,
  Mulanje4,
  Mulanje5,
  Mulanje6,
  Mulanje7,
  Botswana1,
  Botswana2,
  Botswana3,
  Botswana4,
  Botswana5,
  Botswana6,
  Botswana7,
  GBV1,
  GBV2,
  KenyaImage1,
  KenyaImage2,
  KenyaImage3,
  KenyaImage4,
  KenyaImage5,
  KenyaImage6,
  KenyaImage7,
  CanadaImage1,
  CanadaImage2,
  CanadaImage3,
  CanadaImage4,
} from '../../assets';

export type CharityItem = {
  charityName: string;
  featureImage: string;
  charityImages: string[];
  charityDetails: string;
  id: string;
};

export const CharityData: {
  countryId: string;
  countryName: string;
  countryImage: string;
  charities: CharityItem[];
}[] = [
  {
    countryId: '0',
    countryImage: 'src/assets/images/south-africa-1.jpeg',
    countryName: 'South Africa',
    charities: [
      {
        id: '0',
        charityName: 'Eastern Cape - Family',
        charityImages: [EasterCape],
        charityDetails:
          'On the 2nd of July 2021, a few Kingdom Financiers from the Eastern Cape visited the Gazi family and their 3 neighbours. Mrs Gazi is a housewife and has never had a permanent job. She gave birth to a child with a disability who is bedridden since birth. Their difficulties worsened during this COVID pandemic-induced lockdown. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: EasterCape,
      },
      {
        id: '1',
        charityName: 'Free State, Bloemfontein - Children Hospice',
        charityImages: [Bloem1, Bloem2, Bloem3, Bloem4, Bloem5, Bloem6, Bloem7],
        charityDetails: `During the month of September, Kingdom Financiers Bloemfontein, Free State, had the opportunity of working hand in hand with partners not only from Bloemfontein but other towns in the free state as well. We went to Sunflower Children's Hospice, a charity house with children aged 6 months to 16 years. The children are infected with HIV/AIDS & some are disabled. We were welcomed by the voices of children singing. The kids prepared amazing poems for us and we also got to hear their life stories. We donated a vast variety of groceries to cater for their daily needs. We are so grateful for the opportunity to touch lives through KFMI.`,
        featureImage: BloemFeature,
      },
      {
        id: '2',
        charityName: 'Free State, Welkom - Children’s Home',
        charityImages: [WelKom1, WelKom2, WelKom3, WelKom4, WelKom5, WelKom6, WelKom7],
        charityDetails:
          'In the month of December we celebrated the birth of our Lord Jesus Christ. The Free State, South Africa partners, in the spirit of giving back, had the opportunity to visit one of our own partners in Welkom Mme Matoane who runs an orphanage called Heal The Heart Safe Home And Community Development. The orphanage houses about 24 kids who have been previously abused, vulnerable, and neglected in the Lejweleputswa district. KFMI partners spent the day with the kids and donated food, toiletries, clothing, blankets & beds to the orphanage. KFMI, impacting the world, one community at a time!',
        featureImage: WelkomFeature,
      },
      {
        id: '3',
        charityName: 'Free State, Qwaqwa - Children’s Home',
        charityImages: [Qw1, Qw2, Qw3, Qw4, Qw5],
        charityDetails:
          'Jehovah Jireh Mustard Seed. In the month of June 2022, Kingdom Financiers in the Free State embarked on yet another charity drive, this time in Qwaqwa. KFMI Free State visited Jehovah Jireh Mustard Seed – a home that houses 72 children whose ages range from 7 to 20 years. These children have suffered physical and emotional abuse (especially rape). The Kingdom Financiers contributed some money to the home to buy heaters and electricity. We as KFMI Free State would like to thank KFM INTERNATIONAL for this wonderful platform that they have given to us. We are not only touching lives, but walking in our calling through KFMI.',
        featureImage: QwaqwaFeature,
      },
      {
        id: '4',
        charityName: 'Gauteng, Rustenburg - School Drive',
        charityImages: [Rust1, Rust2, Rust3, Rust4],
        charityDetails:
          'On the 17th of March KFMI Gauteng and Northwest visited Bosabosele Primary School and Kroondal Primary School in Rustenburg, northwest. We donated school shoes, uniforms, and much needed food parcels to students from disadvantaged backgrounds. To God be all the glory! Changing lives, one province at a time!',
        featureImage: RustFeature,
      },
      {
        id: '5',
        charityName: 'Gauteng, Alexandria - Children’s & Elderly Home',
        charityImages: [Alex2, Alex3, Alex4, Alex5, Alex6],
        charityDetails:
          'On the 27th of June 2021 Kingdom Financiers SA, Gauteng visited Refilwe Legae Home in Alexandra under the care of Mama Tshidi. Mama Tshidi is taking care of 6 families consisting of 2 elderly persons, 6 adults, and 18 teenagers who were abandoned at her gate by their parents when they were born, as well as a 2 month old baby who was dumped at the dump site. Most of the children have Down Syndrome and other disabilities. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: AlexFeature,
      },
      {
        id: '6',
        charityName: 'Gauteng, Kempton Park - Orphanage',
        charityImages: [Kemp2, Kemp3, Kemp4, Kemp5, Kemp6, Kemp7],
        charityDetails:
          'On the 19th of May 2022, Kingdom Financiers from Gauteng, Johannesburg visited Busy Bees Kingdom Kids Night/Daycare and Orphanage Home which comprises of 150 adults and 50 kids, under the care of Mrs Deborah Nkolo. Busy Bees offers food to those in need during the day and provides shelter to those in need at night. Busy Bees also provides activities like baking, sowing, decorations, and catering classes. KFMI donated food parcels and spent some quality time with the kids praying, playing, and having a braai with the kids.',
        featureImage: Kemp1,
      },
      {
        id: '7',
        charityName: 'Gauteng, Krugersdorp - Hospice',
        charityImages: [Krug2, Krug3, Krug4, Krug5, Krug6, Krug7],
        charityDetails:
          'On the 25th of September 2021, we visited the Westrand Association for People with Disabilities under the care of Annaline Rossouw who is the director of the organization. The residence caters to 30 multi-disabled adults. The programs in the residence are holistic in nature and empower the residents to reach their full potential. The residents are fed, bathed, and dressed daily by the team, and their physical, medical, and physiological needs are attended to. KFMI partners donated the following; 5 wheelchairs, 3 bed mattresses, paint and paint brushes for the maintenance of the rooms. KFMI partners also painted one of the rooms',
        featureImage: Krug1,
      },
      {
        id: '8',
        charityName: ' Gauteng, Midrand - Elderly & Disabled Home',
        charityImages: [Mid1, Mid2, Mid3, Mid4],
        charityDetails:
          'On 07 August 2021, Kingdom Financiers from Midrand South Africa visited Itlhokomeng Home of the Aged and Disabled in Alexandra Township, Gauteng. This organization is an NGO that takes care of the elderly. They have 91 male and female elderly residents, of which a percentage of them are disabled and require full-time care. The organization operates with funding and donations provided by various sponsors and donors. Our biggest highlight was when we took the gogos and mkhulus through the salvation prayer that is found in the Parach Daily Devotional. It was so emotional seeing how welcoming and receptive they were to the prayer. As KFMI we demonstrated that we are also concerned with evangelizing making sure that we are feeding both body and soul. Donations included various grocery items and basic food supplies.',
        featureImage: Mid4,
      },
      {
        id: '9',
        charityName: 'Gauteng, Pretoria - Childrens Home & Safe House',
        charityImages: [Pretoria2, Pretoria3, Pretoria4, Pretoria5, Pretoria6, Pretoria7],
        charityDetails:
          'On the 27th of August 2021 KFMI staff and partners visited Amadea Safe House, under the care of Rina Malan. She started Amadea Safe House, meaning ‘Loving Child of God,’ home for 34 abandoned, abused (sexually, emotionally, and physically), and HIV/AIDS affected/infected children, and orphans who are placed in the care of the Malan’s by the Juvenile courts until their cases have been finalized. Some children are with them for more than 16 years. They work closely together with all the social welfare organizations and SAPD in Pretoria and its surroundings. They focus on early childhood development, health care and skills development for the older children. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: Pretoria1,
      },
      {
        id: '10',
        charityName: 'Gauteng, Rosettenville - Children’s Home',
        charityImages: [Rose1, Rose2, Rose3, Rose4, Rose5, Rose6, Rose7],
        charityDetails:
          'On the 24th of July 2021, we visited St Mary’s Children’s Home in Rossetenville, Johannesburg. St Mary’s Children’s Home has been operating since 1902. Their services include residential care to 54 children designated to them through the Children’s Court on account of the Child Care Act for reasons that include: abuse (mental, physical, emotional, and sexual), neglect, being orphaned, or some having been affected by HIV/AIDS. They come to them from surrounding informal settlements and many can be seen as the poorest of the poor. Because of COVID restrictions, only 18 Partners could visit St Mary’s Children’s Home.',
        featureImage: Rose1,
      },
      {
        id: '11',
        charityName: 'Gauteng, Sandton - KFMI 2021 Christmas Box',
        charityImages: [Sand1, Sand2, Sand3, Sand4, Sand5, Sand6],
        charityDetails:
          'For our 2021 Christmas Box Special, our charity drives focused on our partners who are in need in various countries. On the 24th of November, we prepared Christmas boxes for 3 of our partner families. We invited them to our Sandton offices and enjoyed dinner, talked, laughed, shared the word of God, and prayed with the families. We donated various grocery items and funds so that the families had an enjoyable festive season. Touching lives, one family at a time!',
        featureImage: Sand2,
      },
      {
        id: '12',
        charityName: 'Gauteng, Soweto - ILC Organization NPO',
        charityImages: [Soweto1, Soweto2, Soweto3, Soweto4, Soweto5, Soweto6, Soweto7],
        charityDetails:
          'On the 6th of November 2021, we visited the Independent Living Centre (ILC) NPO in SHAP Stadium Soweto, Gauteng. The organization is a registered NPO that seeks to serve the disadvantaged community of people with disabilities in Gauteng. The organization has more than 200 beneficiaries, including children, adults, and senior citizens. Their organization is run by five full-time staff and thirty part-time staff, responsible for hospital and home visits. Funding is from Gauteng DSD. We thoroughly enjoyed spending some quality time with the residents and we not only donated paint but we repainted the building, fixed the plumbing, and changed the door locks, and handles. We also donated some much needed basic groceries for the center.',
        featureImage: Soweto2,
      },
      {
        id: '13',
        charityName: 'Gauteng, Tembisa -Child and Family Welfare Society',
        charityImages: [Tem2, Tem3, Tem4, Tem5, Tem6, Tem7],
        charityDetails:
          'On 20 October 2021, Gauteng Kingdom Financiers visited Tembisa Child and Family Welfare Society in Tembisa Township, Gauteng. This organization is an NGO that takes care of children from birth to 21 years. They have 74 young children and a smaller number of teenagers that are at a university level. A large percentage of the children are below the age of 13 years. The organization is run by two ladies who have been involved in child welfare for over three decades. The organization operates with funding and donations provided by various sponsors and donors. They also raise funds through running an on-premise early childhood development school, bakery, and a sewing workshop to service the families living in and around the community of Tembisa. The organization has a staff of between 80 and 100 staff members. What a blessing it was for Midrand KFMI to spend time in the Tembisa Child and Family Welfare building. We donated essential food items necessary to help take care of the needs of all the children.',
        featureImage: Tem1,
      },
      {
        id: '14',
        charityName: 'KZN, Durban - Children’s Home',
        charityImages: [KZN_2, KZN_3, KZN_4, KZN_5, KZN_6, KZN_7],
        charityDetails:
          'On Saturday 25th September 2021, KZN Kingdom Financiers had their monthly charity drive. They visited Mother of Peace, an international children’s charity organization that was founded in 2003. They have 3 branches in Durban, Johannesburg and Zimbabwe. Illovo Durban branch has over 60 orphaned and abandoned children. They have children from the age of 2 months to 18yrs. They offer children a safe home, education and love. Items donated include food parcels, toiletries and children’s toys.',
        featureImage: KZN_1,
      },
      {
        id: '14',
        charityName: 'KZN, Durban - Children’s Home',
        charityImages: [KZN2, KZN3, KZN4, KZN5, KZN6, KZN7],
        charityDetails:
          'On Saturday the 24th of July 2021, 9 partners visited ‘Mother of Peace’, an international children’s charity that was founded in 2003. One of their branches (Illovo Durban Branch) has over 60 orphaned and abandoned children. They offer children a safe home, education, and love. Secondly, on the same day, we visited ‘Bobbi Bear’, a Non-Profit Organisation that was founded in 2005 to rescue and support raped and other abused children. The youngest baby they rescued from rape is 2 weeks old. They rescue them from their family, help them to open a case, support them with therapy and give them a home. We donated clothes and groceries.',
        featureImage: KZN1,
      },
      {
        id: '15',
        charityName: 'KZN, Durban - Flood Relief Drive',
        charityImages: [Lovo2, Lovo3, Lovo4, Lovo5, Lovo6, Lovo7, Lovo8],
        charityDetails:
          'On the 21st of May 2022, a number of Kingdom Financiers visited 11 Families who were affected by the recent floods in Kwa-Zulu Natal, Durban. Due to the floods that took place, a lot of people were left displaced with no shelter, food, clothes, or clean drinking water, and most lost their loved ones. The number of people who died during the flood is approximately 400 with some still missing. By the grace of God, the KFM team was sent to go and stand with the families who were most affected by the floods. The team reached out to 11 families, with a maximum number of 11 people in some of the families. The families still cannot comprehend the loss brought about by the floods but ascribe their preservation to the mercy of God. The KFM team donated items such as maize meal, rice, samp, vegetables, toiletries, sanitary products, tissues, Cremora, canned fish, canned beans, cooking oil, dishwashing liquid, sugar, salt, tea bags, and blankets. The kingdom financiers also made provision for the children, by organizing a jumping castle as well as party packs – restoring smiles and hope. Let’s continue to hold hands, spreading a message of hope, and love!',
        featureImage: Lovo1,
      },
      {
        id: '16',
        charityName: 'Limpopo, Thohoyandou - Hospital',
        charityImages: [Lim2, Lim3, Lim4, Lim5, Lim6, Lim7, Lim8],
        charityDetails:
          'On the 20th of May 2022, The Kingdom Financing Millionaires of Limpopo visited Hayani Hospital in Limpopo, South Africa. Hayani is a healthcare psychiatric institution situated in Thohoyandou, Vhembe district. The institution hosts +/- 230 patients, some of whom have been neglected by their families due to their mental illnesses. The Kingdom Financiers received a warm welcome from the board of directors, as well as the senior management of the institution. The Limpopo Kingdom Financiers celebrated with the patients, and the management of Hayani by graciously giving some of the much-needed items ranging from glass chess sets, foosball tables, a TV, a Fridge, DSTV decoder, slippers, printers, socks, vests, a radio, and toiletries. This was a day well spent, which brought so much hope to the lives of the patients. Hayani Hospital will never be the same again!',
        featureImage: Lim1,
      },
      {
        id: '17',
        charityName: 'Mpumalanga - Children’s & Disability Home',
        charityImages: [Mp2, Mp3, Mp4, Mp1],
        charityDetails:
          'The Kingdom Financiers of Mpumalanga, South Africa hosted a charity drive on the 4th of September 2021. The 2 Charities we identified were the Tekwane South Home Based Centre, as well as the Tentele Disabled Center which houses 15 Orphans and 9 disabled people. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: Mp1,
      },
      {
        id: '18',
        charityName: 'Mpumalanga - Disability Center',
        charityImages: [Mpu3, Mpu4, Mpu6, Mpu7, Mpu8],
        charityDetails:
          'On the 3rd of July 2021, Kingdom Financiers SA Mpumalanga visited the Tentele Disabled Centre and SKK Centre at the Pienaar location. The two centres cook food daily out of their own pockets for the needy and community members with disabilities. As Kingdom Financiers we donated groceries and R1000 for their travelling. Part of the other donation was that every month we will be paying R2500 to the bakery which supplies them with bread daily. That payment was already done for the month of July.',
        featureImage: Mpu8,
      },
      {
        id: '19',
        charityName: 'Mpumalanga - Disability Center',
        charityImages: [Mpu1, Mpu2, Mpu5, Mpu9, Mpu10],
        charityDetails:
          'On the 9th of October 2021, we spent some time with the residents of the Ntete Disabled Center. We donated some much-needed food parcels as well as groceries to assist them with their daily needs. We then visited the Autism Children Center which is based in Thekwane South Mpumalanga. This Center caters for children with autism. The children require special care as they have difficulty in their learning development. We donated various groceries to cater to their specific needs. Well done kingdom financiers Mpumalanga South Africa!',
        featureImage: Mpu11,
      },
      {
        id: '20',
        charityName: 'Western Cape, Cape Town - Community Feeding Scheme',
        charityImages: [West1, West2, West3, West4, West5, West6],
        charityDetails:
          'On the 2nd of July 2021, Kingdom Financiers Western Cape visited Natasha Mafu Feeding Scheme. With the COVID 19 breakout, Natasha noticed that many people in her community were also losing their jobs and struggling to feed their families. She decided to start a feeding scheme, making breakfast and dinner for the children daily. They queue outside her house between 9am and 5pm. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: West1,
      },
    ],
  },
  {
    countryId: '1',
    countryImage: '',
    countryName: 'Zimbabwe',
    charities: [
      {
        id: '21',
        charityName: 'Zimbabwe - Old Age Home',
        charityImages: [Zim2, Zim1],
        charityDetails:
          'On the 2nd of July 2021 Kingdom Financiers Zimbabwe went to Entembeni Old People’s Home, which houses over 100 elderly people (both male and female), the age group is plus 90 years. The majority have no relatives, they were picked from the streets of Bulawayo and surrounding areas. The oldest is about 110 years old. It was such a humbling experience. We delivered 100 *10kg mealie meal, 100 *2kg sugar, cooking oil, eggs and green bar washing soap.',
        featureImage: Zim2,
      },
    ],
  },
  {
    countryId: '2',
    countryImage: '',
    countryName: 'United Kingdom',
    charities: [
      {
        id: '22',
        charityName: 'Birmingham - Food Bank',
        charityImages: [Bir1, Bir2, Bir3, Bir4],
        charityDetails:
          'On the 6th of August 2021, our Kingdom Financiers from the United Kingdom visited Birmingham Central food bank to donate food parcels. This foodbank works with nutritionists to make sure that food parcels donated contain at least three days’ worth of nutritionally balanced meals for struggling individuals and families. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: Bir1,
      },
      {
        id: '23',
        charityName: 'Bedford - Food Bank',
        charityImages: [Bed1, Bed2, Bed3, Bed4, Bed5, Bed6],
        charityDetails:
          'On the 1st of December 2021, the United Kingdom KFMI partners donated food parcels to Bedford Food Bank to support struggling families in the community. The food bank provides emergency food to people in crisis, and additional support to help tackle the root causes that sweep people into poverty and build people’s resilience, so they are less likely to need a food bank in the future. Bedford Food Bank’s volunteers work with referral partners to provide a minimum of three days of emergency food and support to people experiencing a crisis. The food bank is committed to safeguarding the rights, well-being, and safety of children and vulnerable adults who come into contact with the food bank.',
        featureImage: Bed1,
      },
      {
        id: '24',
        charityName: 'London - Ukrainian Social Club',
        charityImages: [Lon1, Lon2, Lon3, Lon4, Lon5, Lon6],
        charityDetails:
          'On the 2nd of April 2022, UK KFM donated essential items to the Ukrainian Social Club in London (UK) to be distributed across different parts of Ukraine. Items donated were first aid medical kits, blankets, medicine, plasters, medical gloves, bedding, sleeping bags, power banks, sanitary products, hand sanitisers, wet wipes, canned food, non-perishables, and pet food. Ukrainian Social Club is working with the full support of official Ukrainian government organizations, especially the Ukrainian Embassy in the UK to coordinate the organization of various aid categories like essential goods including medicine, funding for medical care, and rehabilitation. The organization effectively delivers much-needed aid across Ukraine, thus ensuring that the most pressing needs are met first.',
        featureImage: Lon1,
      },
    ],
  },
  {
    countryId: '3',
    countryImage: '',
    countryName: 'Malawi',
    charities: [
      {
        id: '25',
        charityName: 'Blantyre, Ndirande - Community Drive',
        charityImages: [Blantyre1, Blantyre2, Blantyre3],
        charityDetails:
          'During our June 2021 Retreat, Kingdom Financiers from about 27 countries took some time to honor the community of Ndirande in Blantyre Malawi. The partners cheerfully distributed masks, several grocery items, and blankets to the elderly, from about 100 families. The event was attended by several dignitaries and chiefs of the area.',
        featureImage: Blantyre1,
      },
      {
        id: '26',
        charityName: 'Chidedza - Community Drive',
        charityImages: [
          Chidedza2,
          Chidedza3,
          Chidedza4,
          Chidedza5,
          Chidedza6,
          Chidedza7,
          Chidedza8,
        ],
        charityDetails:
          'On the 6th of August 2021 Kingdom Financing Ministries International (KFMI) Malawi partners visited Chidedza Community Based Organization (CBO) in Dowa District which is under Big Hands Foundation led by Mrs Success Mawelenga. Success Mawerenga shares the same passion with KFM International which is to help the less privileged communities including school children, orphans, people with disabilities, and the elderly. The Chidedza community has a lot of elderly people who are not able to take care of themselves due to their age and ill health. KFMI Malawi also visited the students of Nkanga Primary School to encourage them about the importance of education and prayer. We donated essential groceries and other items to help with the basic needs of the center.',
        featureImage: Chidedza1,
      },
      {
        id: '27',
        charityName: 'Mulanje - Flood Relief Drive',
        charityImages: [Mulanje2, Mulanje3, Mulanje4, Mulanje5, Mulanje6, Mulanje7],
        charityDetails:
          'On the 3rd and 4th of February Kingdom Financing Millionaires were given the opportunity to visit and spend a day helping people at Mulanje, one of the districts that was unfortunately affected by Cyclone Ana. A total of 36 070 households were affected by the disaster, representing a total population of 83 060 individuals. This has forced people to stay in ill-equipped camps, posing health risks, especially to people living with disability, infants, little children, and pregnant women. Kingdom Financing Millionaires travelled to the affected areas and provided some much-needed donations to families and communities impacted by the floods.',
        featureImage: Mulanje1,
      },
      {
        id: '28',
        charityName: 'Blantyre - Cyclone Freddy Outreach',
        charityImages: [Freddy2, Freddy3, Freddy4, Freddy5, Freddy6, MalawiMain],
        charityDetails:
          'Tropical Cyclone Freddy has impacted over 800 homes and rendered over 400 lives dead thus far. Flood victims are in need of support in this difficult time. We as Kingdom Financiers Globally have taken the mandate to help rescue the lives of these victims through supporting aids. With our global audience, we have deployed a helicopter rescue team to go into the affected areas and distribute much-needed supplies. Through your generous support, the relief funds will help in giving provisions to the cyclone victims such as food, clothing, and medical and sanitary facilities. In addition, the affected victims will receive financial assistance to help them recover and rebuild their lives. KFMI will continue to remain committed to working with the municipalities in these affected areas and giving much-needed support to the many families and communities.',
        featureImage: Freddy1,
      },
      {
        id: '29',
        charityName: 'Lilongwe, Mtandire - Community Drainage Construction',
        charityImages: [
          Mtandire2,
          Mtandire3,
          Mtandire4,
          Mtandire5,
          Mtandire6,
          Mtandire7,
          Mtandire8,
        ],
        charityDetails:
          'KFMI Malawi and partners visited Mtandire Flood Root Community in Lilongwe District under Thamanda Ministries led by Pastor Misomali in Corroboration with Mrs. Success Mawerenga. The community of Mtandire have been experiencing floods during rainy seasons for years. This was due to blocked drainage which was caused by the dumping of trash by the surrounding communities. There have been several cases whereby the community discovered dead bodies of children who did not manage to survive the flood waters in the same area. This touched the hearts of Kingdom Financiers who decided to help the community solve the problem by constructing drainage to keep the community safe. The partners involved the village head and members of the community in the process of construction and handed over donations to fund this life-changing construction project.',
        featureImage: Mtandire1,
      },
      {
        id: '30',
        charityName: 'Lilongwe - Social Rehabilitation Center',
        charityImages: [
          Lilongwe1,
          Lilongwe2,
          Lilongwe3,
          Lilongwe4,
          Lilongwe5,
          Lilongwe6,
          Lilongwe7,
        ],
        charityDetails:
          'On the 30th of October 2021, KFMI and Malawi Partners visited Lilongwe Social Rehabilitation Centre based at Falls in Lilongwe District where grocery items and a lump sum of cash were donated by KFMI. KFMI partners also had some special time to interact and pray with the children.',
        featureImage: Lilongwe1,
      },
      {
        id: '31',
        charityName: 'Lilongwe, Kamuzu - Hospital',
        charityImages: [Kamuzu2, Kamuzu3, Kamuzu4, Kamuzu5, Kamuzu6, Kamuzu7],
        charityDetails:
          'On the 28th of December 2021, KFM visited Kamuzu Central Hospital. Kamuzu Central Hospital is a tertiary referral hospital in the city of Lilongwe (The capital of Malawi). It is estimated to have 780 beds although the true number of patients always exceeds the number of beds. It serves approximately 5 million people referred from five district hospitals and from other parts of Malawi, and parts of neighbouring Tanzania, Zambia, Mozambique, and Zimbabwe. KFM spent time praying and sharing the word of God with the patients, including children who are battling cancer. KFM donated various grocery parcels to each child in the hospital.',
        featureImage: Kamuzu1,
      },
      {
        id: '27',
        charityName: 'Lilongwe, Chikondi - Children’s Home & Safe House',
        charityImages: [Chikondi1, Chikondi2, Chikondi3, Chikondi4, Chikondi5],
        charityDetails:
          'On the 27th of May 2022, KFM Malawi visited Chikondi and Mpatso Care Centre in Area 25 Lilongwe. Chikondi and Mpatso Care Centre is a center that provides a feeding program for orphaned children or children in the area whose parents cannot afford to feed them. The care center has a total of 151 children ranging from ages 3 years to 26 years, this includes 26 children in nursery school, 111 in primary school, 12 in high school, and 2 young adults at tertiary level. During our visit, the KFM Malawi team and Kingdom Financiers donated some basic food items to further assist the care centre with their feeding program, as well as clothing for the children. Among the food items that were donated were basics such as maize, rice, beans, soya pieces, sugar, oil, And sanitary pads for the girls. These items would be able to assist with feeding the children for a period of two (2) months without running out. This was an incredible experience for KFM Malawi as it also gave the team an opportunity to engage with the children at all levels while speaking to them about the love of God. The children were so happy to have been visited by the KFM Malawi team and they were grateful to have been remembered on this day and experience the love of God through the Kingdom Financiers.',
        featureImage: Chikondi1,
      },
    ],
  },
  {
    countryId: '4',
    countryImage: '',
    countryName: 'Botswana',
    charities: [
      {
        id: '33',
        charityName: 'Francistown - Community Schools & Orphanage',
        charityImages: [Botswana2, Botswana3, Botswana4, Botswana5, Botswana6, Botswana7],
        charityDetails: `On the 29th of September 2021, Botswana KFM, gave 80 pairs of school shoes to the Centre of the Deaf in Francistown, as well as, food items to SOS Centre in Francistown. The Francistown Centre of The Deaf is a Primary Boarding School for children, with hearing impairment. The center has 78 learners, 45 boys and 33 girls. The center admits learners from the age of 3 years from nursery school. On the same day, the partners gave food items to SOS Children's Villages in Francistown. SOS is an international children’s charity that offers safe and nurturing family homes to over 80,000 orphaned and abandoned children in over 130 countries and territories around the world.
        In Botswana SOS provides a home for 98 children who are either orphans or have been abused somehow. And they depend solely on donor funding.`,
        featureImage: Botswana1,
      },
      {
        id: '34',
        charityName: 'GBV Center',
        charityImages: [GBV1, GBV2],
        charityDetails:
          'On the 1st of July 2021, Kingdom Financiers Botswana visited 2 Charities, Botswana Gender-Based Violence Prevention and Support Centre (BGBVC), which is a non-profit organization founded in 1998 by Kagisano Society, to support women, men, girls and boys experiencing and affected by GBV in Botswana by providing temporary shelter, counseling, community outreach and education.',
        featureImage: GBV1,
      },
    ],
  },
  {
    countryId: '5',
    countryImage: '',
    countryName: 'Kenya',
    charities: [
      {
        id: '35',
        charityName: 'Children’s Orphanage',
        charityImages: [
          KenyaImage2,
          KenyaImage3,
          KenyaImage4,
          KenyaImage5,
          KenyaImage6,
          KenyaImage1,
        ],
        charityDetails:
          'Madam Rose Bensuda, to spread a message of hope. 6 years ago, Madam Rose received a call from the police asking her to come to their office, she heeded the call, and upon her arrival, she was surprised as the police requested her to take care of some abandoned children in the area. Being a strong woman of faith, she accepted the assignment, and immediately started housing the destitute children in her one-bedroom apartment. God’s grace has been sufficient, and now her center hosts 36 children, with 6 children in High School, and 30 children spreading across Nursery School and Primary School. Regardless of her many efforts, the center is still facing several challenges, ranging from food shortages, rentals, and school fees for the children in high school. We observed the following, despite their challenges at the center, the mood amongst the children remained jovial, they pray fervently trusting God, and they have great knowledge of the word of God. They spend their time in song, along with poetry and psalms. The Kingdom Financing Millionaires in Kenya blessed the children with the following items: Yellow beans, Basmati rice, maize flour ( unga), ngengu (small dry green beans, cooking oil, bar washing soap, pampers, Vaseline / Valon, as well as some much-needed clothes. We had an amazing time with the children, and to our surprise, they prayed for us!',
        featureImage: KenyaImage7,
      },
    ],
  },
  {
    countryId: '6',
    countryImage: '',
    countryName: 'Canada',
    charities: [
      {
        id: '36',
        charityName: 'Women’s Shelter',
        charityImages: [CanadaImage1, CanadaImage2, CanadaImage3, CanadaImage4],
        charityDetails:
          'On April 9th, 2022, A group of Kingdom Financiers went to visit and do charity in Downtown Toronto at a women’s shelter. It was our first-ever charity drive in Canada as plans to do one in the previous year were derailed by COVID strict restrictions. Canada nation-wide Kingdom Financiers came together and contributed to this worthy cause. With funds mobilised, we donated blankets, ladies’ undergarments, toothpaste, deodorants, ladies pampers, hair combs and brushes, and bath towels.',
        featureImage: CanadaImage1,
      },
    ],
  },
];
