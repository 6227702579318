import config from '@project/config';

const eventBaseUrl = `${config.apiUrl}/events`;

export default {
  getAllEventsUrl: () => `${eventBaseUrl}`,
  getLatestEventUrl: () => `${eventBaseUrl}/latest`,
  getEventUrl: (id: string) => `${eventBaseUrl}/${id}`,
  getPublicEventUrl: (id: string) => `${eventBaseUrl}/public_event/${id}`,
  getCurrenciesUrl: () => `${eventBaseUrl}/currencies`,
};
