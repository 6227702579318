import map from 'lodash/map';
import React, { useState } from 'react';

import { useGetPartnerDonationType } from '@project/queries';
import {
  DonationTypeCard,
  LoadingContainer,
  PartnersSidePannel,
  SearchField,
} from '@project/components';

export const PartnersDonationTypePage: React.FC = () => {
  const [search, setSearch] = useState('');
  const { data: donationTypes, isLoading } = useGetPartnerDonationType(search);

  return (
    <div className="grid h-screen flex-1 grid-cols-7 gap-y-10">
      <div className="col-span-7 mx-4 bg-white pt-10 lg:col-span-3 lg:pb-0 2xl:mx-8">
        <PartnersSidePannel />
      </div>
      <div className="col-span-7 bg-[#F5F5F5] pb-4 md:pl-4 lg:col-span-4">
        <div className="my-10 mx-4 flex flex-col md:mx-6">
          <p className="text-lg">Charities</p>
          <div className="w-20 border-b-2 border-lightGrey" />
          <p className="mt-2 mb-4 text-sm text-lightGrey">Select a charity and donate now!</p>
          <SearchField
            className="rounded-full border-[#D3D3D3] bg-[#EAEAEA] md:w-[450px]"
            placeholder="Search"
            onChange={setSearch}
          />
          <LoadingContainer isLoading={isLoading}>
            <div className="mt-14">
              {map(donationTypes?.donationType, (item) => (
                <DonationTypeCard id={item?.id} header={item?.name} logo={item?.logo} />
              ))}
            </div>
          </LoadingContainer>
        </div>
      </div>
    </div>
  );
};
