import React, { useEffect, useState } from 'react';
import { FlatList } from '@codehesion-za/headless';
import map from 'lodash/map';
import { CSVLink } from 'react-csv';
import { useGetPartnerDonationSummary } from '@project/queries';
import { PartnersDonationSummary } from '@project/services';
import {
  LoadingContainer,
  PartnersSidePannel,
  SearchField,
  DonationHistoryCard,
} from '@project/components';
import { getDonationSums } from '@project/services/sub-services/donation-types-service/donation-types.service';
import { PartnerDonationsCard } from '@project/components/atoms/partner-donations-card';

const RenderItem = ({ item, index }: { item: PartnersDonationSummary; index: number }) => (
  <DonationHistoryCard
    key={`${item.id}-${index}`}
    header={item.donationName}
    id={item.id}
    amount={item.totalDonated}
    logo={item.logo}
  />
);

const EmptyListComponent = <span>No donation summaries to load</span>;

export const PartnersDonationHistoryPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<PartnersDonationSummary[]>([]);
  const { data, isLoading } = useGetPartnerDonationSummary(search);
  const [totalDonations, setTotalDonations] = useState<string>('');
  const [csvValues, setCsvValues] = useState('[]');

  const fetchTotalDonations = (from: string | null, to: string | null) => {
    getDonationSums(from || '', to || '')
      .then((response) => {
        const totalDonationsSum = response.data.total_donations_sum;
        setTotalDonations(totalDonationsSum);
        setCsvValues(JSON.stringify(response.data.csv_data ?? []));
        console.log('CSV DATA IN', csvValues);
      })
      .catch((error) => {
        console.error('Error fetching donation sums:', error);
      });
  };

  useEffect(() => {
    fetchTotalDonations(fromDate, toDate);
  }, []);

  const values = JSON.parse(csvValues ?? '[]');

  const csvData = map(values, (item) => ({
    donationName: item?.name ?? '',
    totalDonated: item?.total_amount ?? '',
    DonationDate: item?.payment_date ?? '',
  }));

  const csvHeaders = [
    { label: 'Donation Name', key: 'donationName' },
    { label: 'Donation Date', key: 'DonationDate' },
    { label: 'Donation Amount', key: 'totalDonated' },
  ];

  useEffect(() => {
    if (data?.donationType) {
      const filtered = data.donationType.filter((item) => item.totalDonated !== '$0.00');
      setFilteredData(filtered);
    }
  }, [data]);

  const handleFromDateChange = (value: string) => {
    setFromDate(value || null);
    fetchTotalDonations(value, toDate);
  };

  const handleToDateChange = (value: string) => {
    setToDate(value || null);
    fetchTotalDonations(fromDate, value);
  };

  return (
    <div className="grid h-screen flex-1 grid-cols-6 gap-y-10">
      <div className="col-span-6 mx-4 bg-white pt-10 lg:col-span-2 lg:pb-0 2xl:mx-8">
        <PartnersSidePannel />
      </div>
      <div className="col-span-6 bg-[#F5F5F5] pb-4 md:pl-4 lg:col-span-4">
        <div className="w-s ceen my-10 mx-4 flex flex-col md:mx-6">
          <p className="text-lg">Donations</p>
          <div className="mb-7 w-24 border-b-2 border-lightGrey" />
          <div className="mb-4 flex flex-wrap items-center">
            <div className="mb-2 flex-1 sm:mb-0">
              <SearchField
                className="mr-4 rounded-full border-[#D3D3D3] bg-[#EAEAEA]"
                placeholder="Search"
                onChange={setSearch}
              />
            </div>
            <div className="mb-2 flex-1 sm:mb-0">
              <input
                type="date"
                className="mr-4 rounded-full border-[#D3D3D3] bg-[#EAEAEA]"
                value={fromDate || ''}
                onChange={(e) => handleFromDateChange(e.target.value)}
              />
            </div>
            <div className="mb-2 flex-1 sm:mb-0">
              <input
                type="date"
                className="mr-4 rounded-full border-[#D3D3D3] bg-[#EAEAEA]"
                value={toDate || ''}
                onChange={(e) => handleToDateChange(e.target.value)}
              />
            </div>
            <div className="col-span-7 bg-[#F5F5F5] lg:col-span-2">
              <div className="flex justify-center self-end lg:justify-end">
                <CSVLink
                  headers={csvHeaders}
                  filename={`Donation History`}
                  data={csvData ?? []}
                  className="flex rounded bg-gold py-2 px-5 text-center hover:bg-gold"
                >
                  <p className="w-40 py-1.5 text-sm font-bold text-white">CSV EXPORT</p>
                </CSVLink>
              </div>
            </div>
            <div className="mb-2 mt-4 flex-1 sm:mb-0">
              <PartnerDonationsCard totalDonations={totalDonations} />
            </div>
          </div>
          <LoadingContainer isLoading={isLoading}>
            <FlatList
              items={filteredData}
              renderItem={RenderItem}
              ListEmptyComponent={EmptyListComponent}
            />
          </LoadingContainer>
        </div>
      </div>
    </div>
  );
};
