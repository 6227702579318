import { useCallback } from 'react';
import debounce from 'lodash/debounce';

import { DatePickerProps } from './types';

export const DatePicker: React.FC<DatePickerProps> = ({ onChange, className, label }) => {
  const validateInputDebounce = useCallback(
    debounce((value: string) => onChange(value), 500),
    [],
  );

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) =>
    validateInputDebounce(event.currentTarget.value);

  return (
    <div className={`flex flex-col ${className}`}>
      <label className="pb-2 text-[#ADACAC]">{label}</label>
      <input
        type="date"
        onChange={handleOnChange}
        className="rounded-md border-[#D3D3D3] bg-[#EAEAEA] text-[#ADACAC]"
      />
    </div>
  );
};
