import { z } from 'zod';

import { commonSchemas } from '@project/schemas';

export const contactUsSchema = z.object({
  fullName: z.string(),
  email: commonSchemas.username,
  topic: z.string(),
  message: z.string(),
  recipientEmails: z.array(z.string()),
});
