import config from '../../../config';

const userBaseUrl = `${config.apiUrl}/users`;
const baseUrl = `${config.apiUrl}`;

export default {
  getCurrentUserUrl: () => `${userBaseUrl}/show_details`,
  getUsersUrl: (page?: number) => `${userBaseUrl}${page ? `?page=${page}` : ''}`,
  getArchivedUsersUrl: (page?: number) => `${userBaseUrl}/archived${page ? `?page=${page}` : ''}`,
  getUserUrl: (id: number) => `${userBaseUrl}/${id}`,
  restoreUserUrl: (id: number) => `${userBaseUrl}/${id}/restore`,
  getDonationTypes: (search?: string) => `${baseUrl}/donation_types?search=${search}`,
  getIndividualDonationType: (id: string) => `${baseUrl}/donation_types/${id}`,
};
