import _ from 'lodash';
import dayjs from 'dayjs';

import { Event, EventApi } from './types';

export const eventModel = (data?: EventApi): Event => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
  charity: data?.charity ?? '',
  link: data?.link ?? '',
  startAt: dayjs(data?.start_at ?? '').format('DD MMMM YYYY h:mm A'),
  endAt: data?.end_at ?? '',
  gmtTimezone: data?.gmt_timezone ?? 2,
  minDonationCurrency: data?.min_donation_currency ?? '',
  eventImage: data?.event_image ?? '',
  eventType: data?.event_type === 'in_person' ? 'In Person' : data?.event_type ?? '',
  indemnityFormId: data?.indemnity_form_id ?? 0,

  minDonations: _.isEmpty(data?.min_donations)
    ? []
    : _.map(data?.min_donations, (minDonation) => ({
        symbol: minDonation.symbol,
        minDonation: minDonation.min_donation,
        currency: minDonation.currency,
      })) ?? [],
});
