import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { AboutUsBanner, HomeIcon1, HomeIcon2, HomeIcon3 } from '@project/assets';
import { Button } from '@project/components/atoms';
import { WebsiteService, websiteStatsModel } from '@project/services';

export const AboutUsSection: React.FC = () => {
  const navigate = useNavigate();

  const donateNow = () => {
    navigate(`/special-donations`);
  };

  const { data: stats, isLoading } = useQuery(['getStats'], () => WebsiteService.websiteStats(), {
    select: websiteStatsModel,
  });

  const statesHeader = 'text-xl font-bold md:text-4xl';
  const statesBlock = 'text-center text-white';

  const DonateCard = ({
    title,
    message,
    icon,
    altText,
    type,
  }: {
    title: string;
    message: string;
    icon: string;
    altText: string;
    type: string;
  }) => {
    return (
      <div
        className={`flex w-[300px] ${
          type === 'even' ? 'bg-black' : 'bg-oddCard bg-contain'
        } sm:w-[310px] md:w-[440px]`}
      >
        <div className="flex h-[350px] flex-col justify-between p-8">
          <div>
            <img className="mx-auto mb-6 mt-2 w-12" src={icon} alt={altText} />
            <div className="text-center">
              <h1 className="ml-4 pb-2 pt-2 text-sm font-bold text-white md:text-base">{title}</h1>
            </div>
            <div className="ml-9 h-0.5 w-9/12 bg-white"></div>
            <p className="ml-9 mt-4 text-sm  text-white md:text-base">{message}</p>
          </div>
          <div className="mt-4 flex items-center justify-center">
            <Button
              style="bg-gold text-white text-sm h-8 w-36 rounded py-1.5 ml-9 align-bottom"
              onClick={donateNow}
            >
              DONATE
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-12">
      <div className="mx-4 flex flex-col items-center text-center md:mx-0">
        <p className="font-semiBold mb-2 text-4xl">About Us</p>
        <div className="w-48 rounded border-2 border-gold" />
        <p className="mt-12 text-lg font-semibold">
          With a global footprint in over 80 countries, our partners range from established
          <br />
          businessmen and women, small to large companies, as well as ordinary members of the
          community
          <br /> who are passionate about impacting lives and making a difference.
        </p>
        <p className="mt-10 md:mx-28">
          Kingdom Financing Ministries International (KFMI) is a global non-profit organization
          comprising of more than 15,000 partners and donors. It is dedicated to various causes,
          including religion, welfare and humanitarian efforts, healthcare, education, and
          development, with a distinct emphasis on addressing the needs of the Christian community.
        </p>
        <Button
          style="bg-gold text-white py-3 rounded px-4 text-sm mt-11"
          onClick={() => navigate('/about-kfm')}
        >
          LEARN MORE ABOUT US
        </Button>
      </div>
      <div className="absolute mt-[25%] w-full border-0 border-gold lg:mx-0 lg:border-[200px] xl:mt-[8%] xl:border-[65px]" />
      <div className="my-10 flex flex-row justify-center rounded-xl bg-white pt-6 pb-10 sm:mx-6 md:mx-16 md:pt-3 2xl:mx-36">
        <div className="z-10 grid border-[10px] border-gold lg:grid-cols-2 lg:border-none xl:grid-cols-3 2xl:gap-20">
          <div>
            <DonateCard
              type="even"
              title="Kingdom Building"
              message="We build churches across the globe through our international Church Building Fund."
              icon={HomeIcon3}
              altText="Hammer and screw icon"
            />
          </div>
          <div>
            <DonateCard
              type="odd"
              title="Kingdom Investment"
              message="We invest in our communities through our monthly global Charity drives. "
              icon={HomeIcon1}
              altText="Charity outreach icon"
            />
          </div>
          <div className="lg:ml-[60%] xl:ml-0">
            <DonateCard
              type="even"
              title="Get Involved"
              message="Become a Kingdom Financier today, change a life, change a community and change the
                world!"
              icon={HomeIcon2}
              altText="Helping hands icon"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        {window.innerWidth >= 768 && (
          <img className="h-[350px] w-full lg:h-full" src={AboutUsBanner} />
        )}
        <div className="mt-10 w-full bg-black md:absolute md:-mt-[43%] md:bg-transparent lg:-mt-[30%] xl:-mt-[29%]">
          <div className="flex flex-col items-center justify-center pt-8">
            <p className="mx-1 text-base  font-semibold text-white underline decoration-gold decoration-[3px] underline-offset-2 md:mx-0 md:text-4xl">
              DONATE AND IMPACT A LIFE TODAY!
            </p>
          </div>
          <p className="mt-8 text-center text-sm text-white md:mx-32 xl:mt-14 xl:text-xl">
            With a global footprint in over 80 countries, our partners range from established
            businessmen and women, small to large companies, as well as ordinary members of the
            community who are passionate about impacting lives and making a difference.
          </p>
          <div className="mt-8 grid grid-cols-3 pb-8 xl:mt-20">
            <div className={statesBlock}>
              {isLoading ? (
                <span className="font-normal text-gray-200">Loading...</span>
              ) : (
                <p className={statesHeader}>+ {stats?.totalUsers}</p>
              )}
              <p className="text-sm xl:text-xl">Partners And Donors</p>
            </div>
            <div className={statesBlock}>
              {isLoading ? (
                <span className="font-normal text-gray-200">Loading...</span>
              ) : (
                <p className={statesHeader}>+ {stats?.totalCharityOrganisations}</p>
              )}
              <p className="text-sm xl:text-xl">Charitable Organisations Reached</p>
            </div>
            <div className={statesBlock}>
              {isLoading ? (
                <span className="font-normal text-gray-200">Loading...</span>
              ) : (
                <p className={statesHeader}>+ {stats?.totalCommunitiesImpacted}</p>
              )}
              <p className="text-sm xl:text-xl">Communities Impacted</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
