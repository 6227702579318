import { BillingValues } from '../../../components';
import { Donation } from './types';

export const intentEventDto = (event_id: number) => ({
  payment: {
    event_id,
  },
});

export const intentDonationDto = (formData: Donation) => ({
  payment: {
    event_id: formData.eventId,
    amount: formData.amount,
    currency: formData.currency,
    donation_type: formData.donationType,
    payment_type: formData.paymentType,
    prayer_requests: formData.prayerRequests ?? '',
  },
});

export const paymentDto = (intentId: string, formData: BillingValues) => ({
  payment: {
    intent_id: intentId,
  },
  user: {
    email: formData.email,
    first_name: formData.firstName,
    last_name: formData.lastName,
    country_code: formData.country,
    mobile: formData.phoneNumber,
    street1: formData.street1,
    street2: formData.street2,
    city: formData.townCity,
    region: formData.country,
    province: formData.region,
    zip: formData.postcodeZip,
  },
});
