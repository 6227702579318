import map from 'lodash/map';
import isNil from 'lodash/isNil';

import { SingleTeamMemberModel } from './team-member.model';
import { TeamMembersApi } from '@project/services';
import { paginationModel } from '@project/models';
import { TeamMembersModelType } from './types';

export const TeamMembersModel = (data: TeamMembersApi): TeamMembersModelType => ({
  teamMembers: isNil(data) ? [] : map(data.team_members, (member) => SingleTeamMemberModel(member)),
  meta: paginationModel(data?.meta),
});
