import z from 'zod';
import { passwordSchema } from '@project/schemas';

const basePartnerRegistrationSchema = z.object({
  currentStep: z.string().default('1/5'),
  showProfileType: z.boolean(),
  showNextForm: z.boolean(),
  intentId: z.string(),
  stripeId: z.string(),
  stripeClientSecret: z.string(),
  donationAmount: z.number().max(1000000).nonnegative('Contribution amount must be positive'),
  donationType: z.string(),
  donationCurrency: z.string(),
  name: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  number: z.string(),
  phoneNumber: z.string().min(6).max(20),
  countryCode: z.string(),
  country: z.string(),
  street1: z.string(),
  street2: z.string().optional(),
  region: z.string(),
  townCity: z.string(),
  postcodeZip: z.string(),
  industry: z.string(),
  nearestBranch: z.string().optional(),
  assistantName: z.string().optional(),
  paymentMethod: z.string(),
  termsAndConditions: z
    .boolean()
    .refine((val) => val, { message: 'You need to accept the terms and conditions' }),
  newPassword: z.string(),
  confirmPassword: z.string(),
});

export const partnerRegisterationSchema = z
  .discriminatedUnion('type', [
    z
      .object({
        type: z.literal('organisation'),
      })
      .merge(basePartnerRegistrationSchema),
    z
      .object({
        type: z.literal('individual'),
        donorCategoryId: z.string(),
        profileImage: z.string(),
        title: z.string(),
        occupation: z.string(),
        dateOfBirth: z.string(),
        paymentMethod: z.string(),
        nearestBranch: z.string().optional(),
        assistantName: z.string().optional(),
      })
      .merge(basePartnerRegistrationSchema),
    z
      .object({ type: z.literal('family'), occupation: z.string() })
      .merge(basePartnerRegistrationSchema),
  ])
  .refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

export type PartnerRegisterationValues = z.infer<typeof partnerRegisterationSchema>;

export const partnerRegisterationPasswordFormSchema = z
  .object({
    newPassword: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

export type partnerRegisterationPasswordFormValues = z.infer<
  typeof partnerRegisterationPasswordFormSchema
>;
