import React from 'react';

import { IndividualHistoryProps } from './types';

export const IndividualHistory: React.FC<IndividualHistoryProps> = ({
  donatedOn,
  totalDonated,
}) => (
  <div className="mb-4 rounded-md bg-white p-4 shadow-md shadow-gray-300">
    <div className="grid grid-cols-2">
      <p className="font-light">You donated on:</p>
      <p className="font-light">{donatedOn}</p>
    </div>
    <div className="my-2 border border-gray-300" />
    <div className="grid grid-cols-2">
      <p className="font-light">Total Amount:</p>
      <p className="text-xl font-semibold">{totalDonated}</p>
    </div>
  </div>
);
