import React from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import { Button } from '@project/components/atoms';
import { PartnerRegisterationValues } from '@project/schemas';
import { GAEvent, trackEvent } from '@project/services';

const ProfileTypeCards = ({
  title,
  checked,
  onchange,
}: {
  title: string;
  checked: boolean;
  onchange: (value: any) => any;
}) => (
  <div
    className="mb-5 flex flex-row justify-between rounded-lg border border-slate-300 p-2"
    onClick={onchange}
  >
    <p>{title}</p>
    <input className="my-auto text-gold" type="radio" checked={checked} onChange={onchange} />
  </div>
);

export const RegisterToBecomePatnerFirstSceen: React.FC = () => {
  const { values, setFieldValue, setValues } = useFormikContext<PartnerRegisterationValues>();
  const navigate = useNavigate();

  const handleLoginClick = () => navigate('/partner/auth/login');

  const goBack = () => navigate(-1);

  const isSubmitting = () => {
    trackEvent(GAEvent.RegistrationAttempt);
    setValues((previous) => ({
      ...previous,
      showNextForm: true,
      showProfileType: false,
      currentStep: '2/5',
    }));
  };

  return (
    <div className="relative flex flex-col">
      <Button
        style="top-[-140px] absolute rounded bg-lightGrey p-1"
        onClick={goBack}
        variant="text"
      >
        <ArrowLeftIcon className="w-5 text-black" />
      </Button>
      <div className="mt-10 mb-4 border-t-2 border-slate-300">
        <p className="mt-6 mb-2 text-lg font-semibold">Please select your profile type:</p>
        <div className="pt-6">
          <ProfileTypeCards
            title={'Organisation'}
            checked={values?.type === 'organisation'}
            onchange={() => setFieldValue('type', 'organisation')}
          />
          <ProfileTypeCards
            title={'Family'}
            checked={values?.type === 'family'}
            onchange={() => setFieldValue('type', 'family')}
          />
          <ProfileTypeCards
            title={'Individual'}
            checked={values?.type === 'individual'}
            onchange={() => setFieldValue('type', 'individual')}
          />
        </div>
      </div>
      <Button style="bg-gold text-white py-2.5 mx-auto w-full rounded-md" onClick={isSubmitting}>
        NEXT
      </Button>
      <div className="mx-4">
        <p className="mb-6 mt-10 font-light">
          By continuing, you agree to accept our Privacy Policy & Terms of Service.
        </p>
        <p className="flex flex-row font-light">
          Have an Account?
          <Button onClick={handleLoginClick} style="text-gold underline ml-2">
            LOGIN
          </Button>
        </p>
      </div>
    </div>
  );
};
