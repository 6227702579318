import { z } from 'zod';
import { commonSchemas } from '@project/schemas';

export const partnersProfileSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  birthday: z.string().optional(),
  phoneNumber: z.string().optional(),
  country: z.string().optional(),
  donorCategoryId: z.string().optional(),
  donorCategoryName: z.string().optional(),
  title: z.string().optional(),
  occupation: z.string().optional(),
  industry: z.string().optional(),
  membershipType: z.string().optional(),
  email: z.string().optional(),
  editMode: z.boolean().optional(),
  profileImage: commonSchemas.file.or(z.string().or(z.null())).optional(),
  nearestBranch: z.string().optional(),
});
