import _ from 'lodash';

import { CurrenciesType, CurrenciesApiType } from './types';

export const CurrenciesModel = (data: CurrenciesApiType): CurrenciesType =>
  _.isNil(data)
    ? []
    : _.map(data, (currency) => ({
        label: `${currency.name} (${currency.code})`,
        value: currency.code,
      }));
