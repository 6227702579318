import { toast } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useGetPartnerStatsQuery } from '@project/queries';
import { partnerService } from '@project/services';
import { useLogout } from '@project/hooks';
import {
  ChangePartnersPasswordForm,
  PartnerTotalDonationsCard,
  LoadingContainer,
  ProfileForm,
  MemberCard,
  Button,
} from '@project/components';

export const PartnersProfile: React.FC = () => {
  const { signOut } = useLogout();
  const [progress, setProgress] = useState('');
  const { data: partnersStats, isLoading } = useGetPartnerStatsQuery();
  const toastDuration = { duration: 3000 };

  const { mutate, isLoading: deleteLoading } = useMutation(() => partnerService.deletePartner(), {
    onSuccess: () => {
      signOut();
      return toast.success('Account deleted successfully', toastDuration);
    },
    onError: () => toast.error('Could not delete account', toastDuration),
  });

  useEffect(() => {
    setProgress(partnersStats?.progress ?? '');
  }, [isLoading]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="grid h-screen lg:grid-cols-3">
        <div className="mx-4 mt-14 flex flex-col justify-between md:mx-14 lg:mx-6 xl:mx-12">
          <div>
            <p className="text-xl">Profile</p>
            <div className="mb-10 w-16 border border-gold" />
            <div className="flex flex-row">
              <div className="mr-4 w-[180%]">
                <MemberCard memberStatus={partnersStats?.membershipStatus} />
              </div>
              <PartnerTotalDonationsCard
                totalDonations={partnersStats?.donations}
                progressToNextLevel={partnersStats?.progress}
              />
            </div>
            <div className="mt-4 border-t-2 border-b-2 pb-14">
              <div className="mt-10 w-full rounded-2xl bg-gray-300 p-1">
                <div className="relative">
                  <div
                    style={{ width: `${progress}%` }}
                    className={`rounded border-b-2 border-b-gold`}
                  />
                  <div className="flex flex-row justify-between">
                    <p
                      style={{ left: `${progress}%` }}
                      className={`absolute -top-8 mt-2 -ml-8 -translate-x-1/2 transform`}
                    >
                      {partnersStats?.donations}
                    </p>
                    <p className={`absolute mt-2 ml-[95%] -translate-x-1/2 transform`}>
                      {partnersStats?.upgradeAmount ?? '$0.00'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            variant="text"
            onClick={mutate}
            style="mt-10 underline md:mb-16"
            isDisabled={deleteLoading}
            isLoading={deleteLoading}
          >
            <span>Delete account</span>
          </Button>
        </div>
        <div className="mt-16 border-r border-gray-300 bg-[#EAEAEA] lg:mt-0">
          <div className="mx-4 md:mx-12 lg:mx-6 xl:mx-12">
            <ProfileForm />
          </div>
        </div>
        <div className="bg-[#EAEAEA] pb-16">
          <div className="mx-4 md:mx-12 lg:mx-6 xl:mx-12">
            <ChangePartnersPasswordForm />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};
