import React from 'react';
import { FlatList } from '@codehesion-za/headless';
import { Link, useNavigate } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/24/outline';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/24/solid';

import { userAuthService } from '@project/services';
import { Button } from '@project/components';
import { HomeLogo } from '@project/assets';

const LINK_ITEMS = [
  { href: '/partner/home', label: 'Home' },
  { href: '/partner/charities', label: 'Charities' },
  { href: '/partner/articles', label: 'Articles' },
  { href: '/partner/donations', label: 'Donations' },
  { href: '/partner/meetings', label: 'Meetings' },
];

const MOBILE_LINK_ITEMS = [
  { href: '/partner/home', label: 'Home' },
  { href: '/partner/charities', label: 'Charities' },
  { href: '/partner/articles', label: 'Articles' },
  { href: '/partner/donations', label: 'Donations' },
  { href: '/partner/meetings', label: 'Meetings' },
  { href: '/partner/profile', label: 'My Profile' },
  { href: '/partner/ts-and-cs', label: 'Terms & Conditions' },
];

const LINK_STYLE =
  'hover:cursor-pointer hover:text-gray-500 hover:underline focus:underline text-sm md:text-base hover:text-gold focus:text-gold';
const ACCOUNT_MENU_LINK_STYLE = 'text-gray-300 font-semibold';

export const PartnerNav: React.FC = () => {
  const navigate = useNavigate();

  const signOut = () => userAuthService.logout().then(() => navigate('/'));

  const linkRenderItem = ({
    item,
    index,
  }: {
    item: { href: string; label: string };
    index: number;
  }) => (
    <Link key={`${item.label}${index}${item.href}`} className={LINK_STYLE} to={item.href}>
      {item.label}
    </Link>
  );

  const AccountDropDownMenu: React.FC = () => (
    <div className="relative w-fit text-left">
      <Menu as="div" className="text-gray-400">
        <Menu.Button className="flex">
          <span>Account</span>
          <ChevronDownIcon className="ml-1 mt-1 h-4" />
        </Menu.Button>
        <Transition
          as="div"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="partner-nav-account">
            <Menu.Item as="div">
              <Link to="/partner/profile" className={ACCOUNT_MENU_LINK_STYLE}>
                My Profile
              </Link>
            </Menu.Item>
            <div className="my-2 border-b-2 border-b-gray-400" />
            <Menu.Item as="div">
              <Link
                to="/partner/ts-and-cs"
                className={`${ACCOUNT_MENU_LINK_STYLE} text-xs font-light`}
              >
                Terms & Conditions
              </Link>
            </Menu.Item>
            <div className="my-2 border-b-2 border-b-gray-400" />
            <Menu.Item as="div">
              <Button
                variant="text"
                onClick={signOut}
                style="text-gray-300 font-semibold text-base"
              >
                Log Out
              </Button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );

  const HamburgerMenu: React.FC = () => (
    <div className="relative">
      <Disclosure>
        <Disclosure.Button className="absolute right-0">
          <Bars3Icon className="h-6 w-6 text-gray-400" />
        </Disclosure.Button>
        <Disclosure.Panel className="relative top-5 flex flex-col divide-y px-4 pt-4 pb-2">
          <FlatList items={MOBILE_LINK_ITEMS} renderItem={linkRenderItem} />
          <div>
            <Button
              variant="text"
              onClick={signOut}
              style="text-gray-300 font-semibold text-base text-[14px]"
            >
              Log Out
            </Button>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );

  return (
    <nav className="partner-nav">
      <div className="grid grid-cols-2 md:grid-cols-3">
        <div className="flex md:col-start-2 md:justify-center">
          <img
            className="h-[30px] sm:h-8 sm:pl-5 lg:pt-0 xl:h-10 xl:pl-10"
            src={HomeLogo}
            alt="Company logo"
          />
        </div>
        <div className="hidden justify-self-end text-white md:col-start-3 md:block">
          <BellIcon className="h-6 w-6 text-gold" />
        </div>
        <div className="block justify-self-end text-white md:col-start-3 md:hidden">
          <HamburgerMenu />
        </div>
      </div>
      <div className="hidden border-b-2 border-b-gray-400 md:block" />
      <div className="hidden grid-cols-3 md:grid">
        <div className="col-start-2 space-x-4 justify-self-center text-gray-400">
          <FlatList items={LINK_ITEMS} renderItem={linkRenderItem} />
        </div>
        <div className="z-50 col-start-3 justify-self-end text-gray-400">
          <AccountDropDownMenu />
        </div>
      </div>
    </nav>
  );
};
