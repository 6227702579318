import { FlatList } from '@codehesion-za/headless';

import { ZAFlag, USAFlag, UKFlag, MWFlag } from '@project/assets';
import { MalawiTableProps } from './types';
import { BankInfo } from './bank-info';
import './bank-info.css';

const MALAWI_TABLE_DATA = [
  { accountNumber: '5460000004354', currency: 'MWK' },
  { accountNumber: '5460000004398', currency: 'USD' },
  { accountNumber: '5460000004399', currency: 'EUR' },
  { accountNumber: '5460000004400', currency: 'GBP' },
  { accountNumber: '5460000004401', currency: 'ZAR' },
];

const RenderItem = ({ item, index }: { item: MalawiTableProps; index: number }) => (
  <tr key={`${index}-${item.accountNumber}`}>
    <td>{item.accountNumber}</td>
    <td className="text-sm uppercase">KFM International</td>
    <td>{item.currency}</td>
  </tr>
);

export const BankInfoDisplay: React.FC = () => (
  <div className="mt-20 w-full">
    <div className="">
      <div className="space-y-2">
        <div className="p-2 shadow-md">
          <BankInfo
            imageSrc={USAFlag}
            country="USA"
            bankName="JP Morgan Chase Bank NA"
            bankAddress="270 Park Avenue, New York NY 10017"
            accountName="Kingdom Financiers Ministries International"
            accountNumber="786639093"
            recipientAddress="1 Rockefeller Plaza, floor 11, New York NY 10020"
            recipientPhoneNumber="+1-260-564-111"
            swiftCode="CHASUS33"
          />
        </div>
        <div className="p-2 shadow-md">
          <BankInfo
            imageSrc={ZAFlag}
            country="South Africa"
            bankName="First National Bank"
            bankAddress="Corner Rivonia Road and 5th Street, Level 5, Shop 5-11, Sandhurst, Sandton, Gauteng, South Africa"
            accountName="Kingdom Financiers Ministries International NPC"
            accountNumber="62880758746"
            branchCode="254605"
            swiftCode="FIRNZAJJ"
          />
        </div>
      </div>
      <div className="space-y-2">
        <div className="p-2 shadow-md">
          <BankInfo
            imageSrc={UKFlag}
            country="United Kingdom (UK)"
            bankName="NatWest Bank"
            bankAddress="Service Centre, Bede house, 11 Western Boulevard, Leicester, LE2 7EJ"
            accountName="KFM INTERNATIONAL UK"
            accountNumber="41657675"
            abaSwiftBicCode="NWBKGB2125D"
            sortCode="601235"
          />
        </div>
        <div className="p-2 shadow-md">
          <BankInfo
            imageSrc={MWFlag}
            country="Malawi"
            bankName="Eco Bank"
            bankAddress="Corner Victoria Avenue and Henderson Street, Private Bag 389, Chichiri, Blantyre 3"
          />
          <table className="mt-2 xxs:-ml-2 xs:ml-0">
            <tr>
              <th>Account Number</th>
              <th>Account Name</th>
              <th>Currency</th>
            </tr>
            <FlatList items={MALAWI_TABLE_DATA} renderItem={RenderItem} />
          </table>
        </div>
      </div>
    </div>
  </div>
);
