import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {
  AboutUsSection,
  Button,
  CardBenefits,
  CharityProjectsSection,
  Footer,
  HomePageCarousel,
} from '@project/components';
import { HomeImg1, HomeImg2 } from '@project/assets';

export const HomePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['getPaymentIntent']);
  }, []);

  const donateNow = () => {
    navigate(`/special-donations`);
  };

  return (
    <div>
      <HomePageCarousel />
      <AboutUsSection />
      <CharityProjectsSection />

      <div className="mt-14 flex h-full w-full bg-white">
        {window.innerWidth >= 1024 ? (
          <img className="w-full" src={HomeImg2} alt="Homepage background" />
        ) : (
          <img className="w-full" src={HomeImg1} alt="Homepage background" />
        )}
        <div className="absolute mt-[8%] ml-[10%] md:mt-[22%] lg:mt-[8%] xl:mt-[10%] 2xl:mt-[12%]">
          <h1 className="mb-2 text-base font-bold text-white underline decoration-gold decoration-[3px] underline-offset-2 md:text-4xl">
            DONATE TOWARDS THE KINGDOM
          </h1>
          <h1 className="text-white md:text-xl">
            KFM International is designed to take care of the needs of <br />
            the kingdom of our Lord Jesus Christ. Impact a life today.
          </h1>
          <Button
            style="bg-gold text-black font-medium text-sm md:text-lg mt-2 md:mt-4 md:h-10 rounded md:pt-1.5 px-2 py-1"
            onClick={donateNow}
          >
            BECOME A DONOR!
          </Button>
        </div>
      </div>
      <CardBenefits />
      <Footer />
    </div>
  );
};
