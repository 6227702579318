import { AboutUsDetailsModelType } from './types';
import { AboutUsDetailsApi } from '@project/services';

export const AboutUsDetailsModel = (data?: AboutUsDetailsApi): AboutUsDetailsModelType => ({
  id: data?.id ?? 0,
  aboutUs: data?.about_us ?? '',
  vision: data?.vision ?? '',
  missionStatement: data?.mission_statement ?? '',
  programmeDescription: data?.programmes_description ?? '',
  npcOffers: data?.npc_offers ?? [],
});
