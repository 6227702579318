import find from 'lodash/find';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';
import React, { useEffect, useMemo } from 'react';

import { RetreatContributionFormProps, RetreatContributionType } from './types';
import { RetreatContributionSchema } from './schema';
import { useGetEventQuery, useGetPublicEventQuery } from '@project/queries';
import {
  PrayerRequestCheckboxes,
  PaymentAmountField,
  LoadingContainer,
  RadioGroup,
  Button,
  Card,
  TextField,
} from '@project/components';

const FormComponents = () => {
  const { values, setFieldValue, isSubmitting, handleSubmit, isValid } =
    useFormikContext<RetreatContributionType>();
  useEffect(() => {
    const currency = find(
      values.minDonations,
      (minDonation) => minDonation.currency === values.contributionCurrency,
    ) ?? {
      symbol: '$',
      currency: 'USD',
      minDonation: values.tip === null ? '1' : '0.00',
    };

    setFieldValue('currencySymbol', currency.symbol);
    setFieldValue('minimumAmount', Number(currency.minDonation));
  }, [values.contributionCurrency]);

  return (
    <Card className="space-y-4 py-10 px-8">
      <p className="text-base font-semibold">Register For Meeting</p>
      <div className="my-2 w-full">
        <p>Select your preferred payment method:</p>
        <div className="gap-y-2 lg:flex lg:gap-x-10">
          <RadioGroup
            name="paymentMethod"
            id="payment-method-eft"
            label="Partial payment (manual/ EFT only)"
            value="manual"
          />
          <RadioGroup
            name="paymentMethod"
            id="payment-method-stripe"
            label="Full payment"
            value="debit_credit"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <PaymentAmountField
          amountFieldName="contributionAmount"
          currencyFieldName="contributionCurrency"
          selectedCurrency={values.contributionCurrency}
        />
        {values.tip !== null && (
          <TextField name="tip" label="Tip(optional)" type="number" required={false}></TextField>
        )}
      </div>
      <div className="flex w-full justify-end space-x-1 text-xs">
        <span>Total Amount:</span>
        <span className="underline decoration-double">{`${values.currencySymbol}${
          values.contributionAmount + (values.tip ? values.tip : 0)
        }`}</span>
      </div>
      <div className="my-2">
        <PrayerRequestCheckboxes fieldName="prayerRequests" />
      </div>
      <Button
        style="bg-gold text-white font-bold text-sm py-3 rounded px-3"
        isLoading={isSubmitting}
        isDisabled={!isValid}
        onClick={handleSubmit}
      >
        Contribute
      </Button>
    </Card>
  );
};

export const RetreatContributionForm: React.FC<RetreatContributionFormProps> = ({
  id,
  isPublic = false,
}) => {
  const navigate = useNavigate();
  const { data: event, isLoading } = isPublic ? useGetPublicEventQuery(id) : useGetEventQuery(id);
  const initialCurrency = useMemo(
    () =>
      find(
        event?.minDonations,
        (minDonation) => minDonation.currency === (event?.minDonationCurrency ?? 'USD'),
      ) ?? {
        symbol: '$',
        currency: 'USD',
        minDonation: '0.00',
      },
    [isLoading],
  );

  const defaultMinDonation = event?.minDonations.find(
    (option) => option && option.currency === 'USD',
  )?.minDonation;

  const initialValues: RetreatContributionType = {
    paymentMethod: 'debit_credit',
    currencySymbol: initialCurrency.symbol,
    minimumAmount: event?.eventType === 'other' ? 1 : Number(initialCurrency.minDonation),
    contributionCurrency: event?.minDonationCurrency ?? 'USD',
    tip: event?.eventType === 'other' ? null : 0,
    contributionAmount:
      event?.eventType === 'other' ? 1 : defaultMinDonation ? Number(defaultMinDonation) : 0.0,
    prayerRequests: [],
    minDonations: event?.eventType === 'other' ? [] : event?.minDonations ?? [],
  };

  const onSubmitForm = (formData: RetreatContributionType) => {
    navigate(
      `/payment?type=${event?.name}&event_id=${event?.id}&amount=${
        formData.contributionAmount + (formData.tip ? formData.tip : 0)
      }&currency=${formData.contributionCurrency}&payment_type=event&prayer_requests=${
        formData.prayerRequests
      }&indemnity_form_id=${event?.indemnityFormId}&event_type=${event?.eventType}`,
      { state: formData.paymentMethod },
    );
    return new Promise(() => null);
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Form
        initialValues={initialValues}
        onSubmitForm={onSubmitForm}
        validationSchema={RetreatContributionSchema}
        render={FormComponents}
      />
    </LoadingContainer>
  );
};
