import { FlatList } from '@codehesion-za/headless';

import { RadioGroup, ActivityLoader } from '@project/components';
import { useGetDonationTypesQuery } from '@project/queries';
import { DonationTypeListItemType } from './types';

const RenderItem = ({ item, index }: DonationTypeListItemType) => (
  <RadioGroup
    key={`${item.id}-${item.name}-${index}`}
    name="donationType"
    id={`${item.id}-${item.name}-${index}`}
    label={item.name}
    value={item.name}
  />
);

const ListEmptyComponent = <span className="text-gray-600">No donation types</span>;

export const DonationTypesList = () => {
  const { data, isLoading } = useGetDonationTypesQuery();

  return (
    <>
      <ActivityLoader isLoading={isLoading} />
      <div className="flex flex-col text-sm">
        <FlatList
          items={data?.donationTypes ?? []}
          renderItem={RenderItem}
          ListEmptyComponent={ListEmptyComponent}
        />
      </div>
    </>
  );
};
