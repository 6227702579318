import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { articleService, partnersArticlesModelDataModel, partnerService } from '@project/services';
import { SingleArticleModel } from './partner-article.model';

const duration = { duration: 3000 };

export const useGetPartnerArticlesQuery = (search?: string, page?: number, per?: number) =>
  useQuery(
    ['getPartnerArticles', search, page, per],
    () => partnerService.getPartnersArticles(search, page, per),
    {
      select: partnersArticlesModelDataModel,
      onError: () => toast.error('Could not fetch articles', duration),
    },
  );

export const useGetArticleQuery = (id: string) =>
  useQuery(['getArticle', id], () => articleService.getArticle(id), {
    select: SingleArticleModel,
    onError: () => toast.error('Could not fetch article', duration),
  });
