import z from 'zod';
import dayjs from 'dayjs';

import { commonSchemas } from '@project/schemas';

export const indemitySchema = z
  .object({
    email: commonSchemas.username,
    blurredVision: z.string(),
    title: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    gender: z.string(),
    dateOfBirth: z.string(),
    passportNo: commonSchemas.passport,
    contactNo: z.string(),
    nextOfKin: z.string(),
    countryOfResidence: z.string(),
    countryOfTravel: z.string(),
    modeOfTransport: z.string(),
    arrivalDate: z.string(),
    arrivalTime: z.string(),
    departureDate: z.string(),
    departureTime: z.string(),
    criticallyIll: z.string(),
    blind: z.string(),
    pregnant: z.string(),
    headingProblems: z.string(),
    inpatientCare: z.string(),
    respiratorySupport: z.string(),
    wheelchairBound: z.string(),
    walkingAid: z.string(),
    covidPositive: z.string(),
    vaccinated: z.string(),
    vaccineCertificate: z.string(),
    vaccineCertificateForFlight: z.string(),
    cough: z.string(),
    fever: z.string(),
    diarrhoea: z.string(),
    vomiting: z.string(),
    lossOfConscious: z.string(),
    shortnessOfBreath: z.string(),
    voluntarilyAttendingRetreat: z.string(),
    accommodation: z.string(),
    transportCosts: z.string(),
    personalSaftey: z.string(),
    doctorsCertificate: z.string(),
    releaseKfmiFromAnyAccidents: z.string(),
    KfmiNotLiableForArrangementsOrTransport: z.string(),
    bookingSecurity: z.string(),
    durationOfStay: z.string(),
    personalGoods: z.string(),
    religiousTour: z.string(),
    cancellationOfTrip: z.string(),
    travingWithAMinor: z.string(),
    consent: z.boolean(),
  })
  .refine(
    ({ arrivalDate, departureDate }) => !dayjs(departureDate).isBefore(dayjs(arrivalDate), 'day'),
    {
      path: ['departureDate'],
      message: 'Cannot depart before you arrive',
    },
  );
