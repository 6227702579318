import React from 'react';

import { MembershipRenewalForm, TopImage } from '@project/components';

export const MembershipRenewalPage: React.FC = () => {
  return (
    <div className="mb-32 flex flex-col">
      <TopImage />
      <div className="ml-4 mt-12 md:ml-16">
        <p className="text-2xl font-semibold">Membership Renewal</p>
        <div className="w-20 border-2 border-gold" />
        <div className="pt-4">
          <MembershipRenewalForm />
        </div>
      </div>
    </div>
  );
};
