import { AxiosError, AxiosResponse } from 'axios';

export const getData = (apiResponse: AxiosResponse) => apiResponse?.data;

export const rejectPromise = (err: AxiosError) => {
  return Promise.reject(err);
};

export const formDataHeaders = {
  timeout: 0,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
