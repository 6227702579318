import React from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';

import { Button } from '@project/components/atoms';
import { getIsLoggedIn } from '@project/helper';
import { HomeLogo } from '@project/assets';

const DONATION_LINKS = [
  { href: '/special-donations', label: 'Special Donations' },
  { href: '/global-donations', label: 'Global Charity Donations' },
  { href: '/upload-proof-of-payment', label: 'Upload Proof Of Payment' },
];

const ABOUT_US_LINKS = [
  { href: '/about-kfm', label: 'About KFM International' },
  { href: '/charity-outreach', label: 'Charity Outreach' },
];

const PARTNERS_LINKS = [
  { href: '/membership-renewal', label: 'Membership Renewal' },
  { href: '/card-holders', label: 'Card Holder' },
  { href: '/register-partner', label: 'Become A Partner' },
];

const CONTACT_LINKS = [
  { type: 'usa-office', label: 'USA Office' },
  { type: 'sa-office', label: 'SA Office' },
  { type: 'uk-office', label: 'United Kingdom Office' },
  { type: 'malawi-office', label: 'Malawi Office' },
];

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = getIsLoggedIn();

  const goToLogin = () => navigate('/partner/auth/login');

  const handleLinkClick = (type: string) => navigate(`/contact-us/${type}`);

  const donationPress = () => navigate('/special-donations');

  const dropdownStype =
    'bg-black bg-opacity-70 ui-active:text-white absulote flex origin-top-right flex-col space-y-4 p-2 z-10 pt-5';
  const dropdownHeaderStyle = 'xl:w-40 w-32 rounded z-10';
  const menuButtonStyle = 'inline-flex p-1 hover:text-gold';
  const buttonStyle = 'my-auto z-10 md:text-base sm:text-sm text-xs rounded px-2';
  const singleNavStyle =
    'w-6 lg:w-20 border-white p-1 pb-6 text-center focus:border-b-2 active:border-b-2 xl:pb-3.5';

  return (
    <div className="flex-col border-b-2 border-gold bg-black bg-opacity-95 lg:h-[120px]">
      <div className="pt-6">
        <a href="/">
          <img
            className="ml-2 h-[30px] sm:ml-5 sm:h-8 lg:mt-0 xl:ml-10 xl:h-10"
            src={HomeLogo}
            alt="Company logo"
          />
        </a>
      </div>
      <div
        className={`${
          isLoggedIn
            ? 'ml-[205px] -mt-8 mr-4 flex-col xs:ml-[260px] xs:w-[100px] sm:ml-0 sm:w-full sm:flex-row'
            : '-mt-8 mr-2 flex-row space-x-2 sm:mr-4 sm:-mt-10 lg:mr-12'
        }  flex justify-end `}
      >
        <Button style={`${buttonStyle} bg-gold text-white py-1.5 md:px-6`} onClick={donationPress}>
          <div className="flex flex-row">
            <HeartIcon className="mr-2 w-4" />
            Donate
          </div>
        </Button>
        {isLoggedIn && (
          <Button
            style={`${buttonStyle} border border-gold border-2 text-gold md:px-10 py-1 bg-black bg-opacity-50 mt-2 sm:mt-0 sm:mx-2 md:mr-4 sm:mr-4`}
            onClick={() => navigate('/partner/home')}
          >
            Back to Portal
          </Button>
        )}
        {!isLoggedIn && (
          <Button
            style={`${buttonStyle} border border-gold border-2 text-gold md:px-10 py-1 bg-black bg-opacity-50 `}
            onClick={goToLogin}
          >
            Login
          </Button>
        )}
      </div>

      <div className="-mt-8 flex justify-end">
        <Disclosure as="nav">
          {({ open, close }) => {
            const handleClose = () => close();

            return (
              <>
                <div className="mb-4 items-center lg:m-12">
                  <div className="flex items-center justify-end">
                    <div className="flex-end mr-4 mt-6 flex pt-6 text-white md:mx-4 md:mt-6 lg:hidden lg:py-0">
                      <Disclosure.Button>
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            aria-hidden="true"
                            className="block h-6 w-6 justify-end text-white"
                          />
                        ) : (
                          <Bars3Icon className="block h-6 w-6 text-white" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:block">
                      <div className="text-Monteserrat -mr-8 flex text-sm text-white xl:text-base">
                        <Link className={`${singleNavStyle}`} to={'/'}>
                          Home
                        </Link>
                        <Link className={`${singleNavStyle} mx-6 xl:mx-20`} to={'/meetings'}>
                          Events
                        </Link>

                        <Menu as="div" className={dropdownHeaderStyle}>
                          <Menu.Button className={menuButtonStyle}>
                            <span>Donations</span>
                            <ChevronDownIcon
                              className="w-6 text-white hover:text-gold"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Menu.Items className={dropdownStype}>
                            {DONATION_LINKS.map((link) => (
                              <Menu.Item
                                className="border-b hover:text-gold"
                                as="a"
                                key={link.href}
                                href={link.href}
                              >
                                {link.label}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>

                        <Menu as="div" className={dropdownHeaderStyle}>
                          <Menu.Button className={menuButtonStyle}>
                            About Us
                            <ChevronDownIcon
                              className="w-6 text-white hover:text-gold"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Menu.Items className={dropdownStype}>
                            {ABOUT_US_LINKS.map((aboutUs) => (
                              <Menu.Item
                                className="border-b hover:text-gold"
                                as="a"
                                key={aboutUs.href}
                                href={aboutUs.href}
                              >
                                {aboutUs.label}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>

                        <Menu as="div" className={dropdownHeaderStyle}>
                          <Menu.Button className={menuButtonStyle}>
                            Partnerships
                            <ChevronDownIcon
                              className="w-6 text-white hover:text-gold"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Menu.Items className={dropdownStype}>
                            {PARTNERS_LINKS.map((partnerlink) => (
                              <Menu.Item
                                className="border-b hover:text-gold"
                                as="a"
                                key={partnerlink.href}
                                href={partnerlink.href}
                              >
                                {partnerlink.label}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>

                        <a
                          className="mr-8 ml-4 border-white p-1 pb-6 text-center focus:border-b-2 active:border-b-2 xl:pr-12 xl:pb-3.5"
                          href={'https://gifts.kfminternational.org'}
                          target="blank"
                        >
                          Gifts & Apparel
                        </a>

                        <Menu as="div" className={dropdownHeaderStyle}>
                          <Menu.Button className={menuButtonStyle}>
                            Contact Us
                            <ChevronDownIcon
                              className="w-6 text-white hover:text-gold"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Menu.Items className={dropdownStype}>
                            {CONTACT_LINKS.map((contact) => (
                              <Menu.Item
                                className="cursor-pointer border-b hover:text-gold"
                                as="a"
                                key={contact.label}
                                onClick={() => handleLinkClick(contact.type)}
                              >
                                {contact.label}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
                <Disclosure.Panel className="z-10 lg:hidden">
                  <div className="z-10 flex w-full flex-col">
                    <Disclosure.Button className="flex flex-col">
                      <div className="mr-20 flex w-full flex-col text-left xs:mr-32 sm:mr-44 md:mr-[520px]">
                        <Link className="mb-4 text-white" to={'/'} onClick={handleClose}>
                          Home
                        </Link>
                        <Link className="mb-4 text-white" to={'/meetings'} onClick={handleClose}>
                          Events
                        </Link>
                        <a
                          className="mb-4 text-white"
                          href={'https://gifts.kfminternational.org'}
                          target="blank"
                        >
                          Gifts & Apparel
                        </a>
                        <p className="text-gold">Donations:</p>
                        <div className="ml-6 flex flex-col">
                          <Link
                            className="mb-4 text-white"
                            to={'/special-donations'}
                            onClick={handleClose}
                          >
                            Special Donation
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/global-donations'}
                            onClick={handleClose}
                          >
                            Global Donation
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/upload-proof-of-payment'}
                            onClick={handleClose}
                          >
                            Upload Proof of Payment
                          </Link>
                        </div>

                        <p className="text-gold">About Us:</p>
                        <div className="ml-6 flex flex-col">
                          <Link className="mb-4 text-white" to={'/about-kfm'} onClick={handleClose}>
                            About KFM International
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/charity-outreach'}
                            onClick={handleClose}
                          >
                            Charity Outreach
                          </Link>
                        </div>

                        <p className="text-gold">Partnerships:</p>
                        <div className="ml-6 flex flex-col">
                          <Link
                            className="mb-4 text-white"
                            to={'/membership-renewal'}
                            onClick={handleClose}
                          >
                            Membership Renewal
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/card-holders'}
                            onClick={handleClose}
                          >
                            Card Holder
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/register-partner'}
                            onClick={handleClose}
                          >
                            Become A Partner
                          </Link>
                        </div>

                        <p className="text-gold">Contact Us:</p>
                        <div className="ml-6 flex flex-col">
                          <Link
                            className="mb-4 text-white"
                            to={'/contact-us/usa-office'}
                            onClick={handleClose}
                          >
                            USA Office
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'/contact-us/sa-office'}
                            onClick={handleClose}
                          >
                            SA Office
                          </Link>
                          <Link
                            className="mb-4 text-white"
                            to={'contact-us/uk-office'}
                            onClick={handleClose}
                          >
                            United Kingdom Office
                          </Link>
                          <Link
                            className="mb-10 text-white"
                            to={'contact-us/malawi-office'}
                            onClick={handleClose}
                          >
                            Malawi Office
                          </Link>
                        </div>
                      </div>
                    </Disclosure.Button>
                  </div>
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
      </div>
    </div>
  );
};
