import React from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';

import { ActivityLoaderProps } from './types';

export const ActivityLoader: React.FC<ActivityLoaderProps> = ({ isLoading, centered }) => {
  if (!isLoading) return null;

  return (
    <>
      {centered ? (
        <div className="mt-5 flex flex-row justify-center">
          <Spinner size={40} className="m-5 text-gold" />
        </div>
      ) : (
        <Spinner size={40} className="m-5 text-gold" />
      )}
    </>
  );
};
