import appConfig from '@project/config';

const { hostUrl, apiUrl } = appConfig;

const usersUrl = `${apiUrl}/partner_information`;

export default {
  tokenUrl: (): string => `${hostUrl}/users/token`,
  forgotPasswordUrl: (): string => `${usersUrl}/forgot_password`,
  resetPasswordUrl: (): string => `${usersUrl}/reset_password`,
  unlockAccountUrl: (): string => `${usersUrl}/unlock_account`,
};
