import { BillingValues, StripeConfirmParamsApiType } from './types';

// We have some users on the db that have phone country code instead of country code
// So we need to check if these are numbers because stripe accepts a  country code
// we need to check if the provided value is a valid country code. Country codes are usually alphabetic (e.g., "US", "CA"),
// while phone country codes are numeric (e.g., "1" for the US, "44" for the UK).
// If the value is numeric, we should return 'US' as the default country code. Otherwise, we return the provided value.
const handleCountry = (value: string) => {
  // Check if the value is numeric
  if (!isNaN(Number(value))) {
    return 'US';
  }
  // If the value is not numeric, return it as is
  return value;
};

const AddressModel = (data: BillingValues) => ({
  city: data?.townCity ?? '',
  country: handleCountry(data?.countryCode),
  line1: data?.street1 ?? '',
  line2: data?.street2 ?? '',
  postal_code: data?.postcodeZip ?? '',
  state: data?.region ?? '',
});

export const ConfirmParamsModel = (data: BillingValues): StripeConfirmParamsApiType => ({
  payment_method_data: {
    billing_details: {
      email: data?.email ?? '',
      name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}`,
      phone: data?.phoneNumber ?? '',
      address: AddressModel(data),
    },
  },
});
