import React from 'react';
import find from 'lodash/find';
import { useFormikContext } from 'formik';

import { DonationValues, DonationProps, DonationTypeOptionsProps } from './types';
import { BaseOption } from '@project/types';
import {
  PrayerRequestCheckboxes,
  PaymentAmountField,
  DonationTypesList,
  Dropdown,
  Button,
} from '@project/components';

const COUNTRIES = [
  { label: 'Asia and Oceana', value: 'Asia and Oceana' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Europe', value: 'Europe' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'North America', value: 'North America' },
  { label: 'USA', value: 'USA' },
  { label: 'South America', value: 'South America' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'East Africa', value: 'East Africa' },
  { label: 'South Africa - Eastern Cape', value: 'South Africa - Eastern Cape' },
  { label: 'South Africa - Free State', value: 'South Africa - Free State' },
  { label: 'South Africa - Guateng', value: 'South Africa - Eastern Cape' },
  { label: 'South Africa - Kwazulu-Natal', value: 'South Africa - Kwazulu-Natal' },
  { label: 'South Africa - Limpopo', value: 'South Africa - Limpopo' },
  { label: 'South Africa - Mpumalanga', value: 'South Africa - Mpumalanga' },
  { label: 'South Africa - Northern Cape', value: 'South Africa - Northern Cape' },
  { label: 'South Africa - North West', value: 'South Africa - North West' },
  { label: 'South Africa - Western Cape', value: 'South Africa - Western Cape' },
  { label: 'Southern Africa', value: 'Southern Africa' },
  { label: 'West Africa', value: 'West Africa' },
  { label: 'Other', value: 'Other' },
];

const DonationTypeOptions: React.FC<DonationTypeOptionsProps> = ({ selectedCountry, formType }) =>
  formType === 'special' ? (
    <div>
      <h2 className="pb-4 font-medium">Select Donation Type</h2>
      <DonationTypesList />
    </div>
  ) : (
    <div className="space-y-2 pb-4">
      <span className="text-base">
        Which country, region or province would you like to donate towards?
      </span>
      <Dropdown options={COUNTRIES} fieldName="donationType" value={selectedCountry} />
    </div>
  );

export const FormComponent: React.FC<DonationProps> = ({ formType }) => {
  const { errors, values, isSubmitting, handleSubmit } = useFormikContext<DonationValues>();

  const getSelectedCountry: BaseOption = find(
    COUNTRIES,
    (country) => country.value === values.donationType,
  ) ?? { label: 'Asia and Oceana', value: 'Asia and Oceana' };

  return (
    <div className="mx-2 min-w-fit space-y-4 border px-8 pt-4 pb-10 shadow-lg">
      {errors?.donationType && (
        <span className="text-sm text-red-600">Please select donation type before proceeding</span>
      )}
      <DonationTypeOptions selectedCountry={getSelectedCountry} formType={formType} />
      <div className="border-b-2 border-b-gray-300 " />
      <div className="w-full pt-2">
        <PaymentAmountField
          amountFieldName="donationAmount"
          currencyFieldName="donationCurrency"
          selectedCurrency={values.donationCurrency}
        />
      </div>
      <div className="border-b-2 border-b-gray-300" />
      <div className="my-2">
        <PrayerRequestCheckboxes fieldName="prayerRequests" />
      </div>
      <div className="border-b-2 border-b-gray-300" />
      <div className="flex pt-4">
        <Button
          style="bg-gold text-white font-bold text-sm rounded-md py-3 w-full"
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
