import slice from 'lodash/slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlatList } from '@codehesion-za/headless';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/outline';

import { ArticlesCard, LoadingContainer, PartnerArticleCardProps } from '@project/components';
import { useGetPartnerArticlesQuery } from '@project/queries';
import { isVideoUrl, stripHtmlTags } from '@project/helper';

const MainArticleCard: React.FC<PartnerArticleCardProps> = ({
  id,
  author,
  content,
  heading,
  createdAt,
  featureImage,
}) => {
  const navigate = useNavigate();
  const convertedContent = stripHtmlTags(content);
  const isVideo = isVideoUrl(featureImage);

  const goToArticle = () => navigate(`/partner/articles/${id}`);

  return (
    <div
      onClick={goToArticle}
      className="text-wrap h-fit cursor-pointer overflow-clip text-ellipsis rounded-md bg-white p-4 shadow-lg shadow-slate-300"
    >
      {isVideo ? (
        <video className="h-44 w-full rounded object-cover" controls>
          <source src={featureImage} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img className="w-full rounded-2xl" src={featureImage} alt="Article Image" />
      )}
      <p className="mt-4 mb-6 text-lg font-semibold">{heading}</p>
      <p className="font-light">{convertedContent}</p>
      <div className="bo mt-6 flex flex-row space-x-4 border-t-2 border-b-2 border-gray-300 py-2">
        <div className="flex flex-row">
          <UserIcon className="w-4" />
          <p className="pl-2 font-light">{author}</p>
        </div>
        <div className="flex flex-row">
          <CalendarIcon className="w-4" />
          <p className="pl-2 font-light">{createdAt}</p>
        </div>
      </div>
    </div>
  );
};

const SubArticles = ({ item, index }: { item: PartnerArticleCardProps; index: number }) => {
  const convertedContent = stripHtmlTags(item.content);

  return (
    <ArticlesCard
      key={`${index}-${item.id}`}
      featureImage={item.featureImage}
      id={item.id}
      heading={item.heading}
      content={convertedContent}
      author={item.author}
      createdAt={item.createdAt}
    />
  );
};

const ListEmptyComponent = <span className="text-gray-800">No articles to load</span>;

export const PartnersArticles: React.FC = () => {
  const [mainArticle, setMainArticle] = useState<PartnerArticleCardProps>();
  const [supportArticles, setSupportArticles] = useState<PartnerArticleCardProps[]>();
  const { data, isLoading } = useGetPartnerArticlesQuery('');

  useEffect(() => {
    setMainArticle(data?.articles[0]);
    setSupportArticles(slice(data?.articles, 1, 5));
  }, [isLoading]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className="mx-4 my-10 flex flex-col md:mx-6">
        <p className="text-lg">Latest Articles</p>
        <div className="w-32 border-b-2 border-slate-400" />
        <div className="mt-10 grid gap-4 xl:grid-cols-2">
          {mainArticle && (
            <div>
              <MainArticleCard
                id={mainArticle?.id ?? -1}
                author={mainArticle?.author ?? ''}
                heading={mainArticle?.heading ?? ''}
                content={mainArticle?.content ?? ''}
                createdAt={mainArticle?.createdAt ?? ''}
                featureImage={mainArticle?.featureImage ?? ''}
              />
            </div>
          )}
          <div className="mt-6 flex flex-col">
            <FlatList
              items={supportArticles ?? []}
              renderItem={SubArticles}
              ListEmptyComponent={ListEmptyComponent}
            />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};
