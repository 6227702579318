import map from 'lodash/map';
import isNil from 'lodash/isNil';

import { AllEventsApiType, AllEventsType, EventsItemType } from './types';

export const EventsItemModel = (
  id: number,
  name: string,
  link: string,
  charity: string,
  startAt: string,
  endAt: string | null,
  eventType: string,
  hasRegistered: boolean,
  indemnityFormId: number,
): EventsItemType => ({
  id: id,
  name: name,
  link: link,
  charity: charity,
  startAt: startAt,
  endAt: isNil(endAt) ? null : endAt,
  eventType: eventType,
  hasRegistered: hasRegistered,
  indemnityFormId: indemnityFormId,
});

export const EventsModel = (data: AllEventsApiType): AllEventsType => ({
  events: isNil(data?.events)
    ? []
    : map(data.events, (event) =>
        EventsItemModel(
          event.id,
          event.name,
          event.link,
          event.charity,
          event.start_at,
          event.end_at,
          event.event_type === 'in_person' ? 'In Person' : event.event_type,
          event.has_registered ?? false,
          event.indemnity_form_id ?? 0,
        ),
      ),
  meta: {
    currentPage: data?.meta?.current_page ?? 0,
    nextPage: data?.meta?.next_page ?? 0,
    prevPage: data?.meta?.prev_page ?? 0,
    totalCount: data?.meta?.total_count ?? 0,
    totalPages: data?.meta?.total_pages ?? 0,
  },
});
