import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Form } from '@codehesion-za/headless';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { changePartnersPasswordSchema } from './schema';
import { ChangePartnersPasswordValues } from './type';
import { Button, SecureTextField } from '@project/components';
import { partnerService } from '@project/services';

export const ChangePartnersPasswordForm: React.FC = () => {
  const { mutateAsync } = useMutation(
    (data: ChangePartnersPasswordValues) => partnerService.changePartnersPassword(data),
    {
      onSuccess: () => toast.success('You have successfully updated your profile information'),
      onError: () => {
        toast.error('An error has occured while trying to update your profile');
      },
    },
  );

  const handleOnSubmit = (formData: ChangePartnersPasswordValues) => {
    return mutateAsync(formData);
  };

  const FormComponents = (formikProps: FormikProps<ChangePartnersPasswordValues>) => {
    const { isSubmitting, handleSubmit, setFieldValue } = formikProps;

    const handleCancel = () => {
      setFieldValue('password', '');
    };

    return (
      <div className="mt-14">
        <p>Security</p>
        <div className="w-16 border border-gray-300" />
        <p className="mt-2 mb-8 text-sm text-gray-600">Reset your password</p>
        <SecureTextField name="password" label="Password" placeholder="New Password" />
        <div className="flex flex-row space-x-14">
          <Button
            type="button"
            onClick={handleCancel}
            style="w-full bg-gray-500 rounded py-2 px-3 text-white"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            onClick={handleSubmit}
            style="w-full bg-gold rounded py-2 px-3 text-white"
          >
            SAVE
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Form
      initialValues={{ password: '' }}
      onSubmitForm={handleOnSubmit}
      validationSchema={changePartnersPasswordSchema}
      render={FormComponents}
    />
  );
};
