import { PartnersBillingDetailsModelType } from './types';
import { PartnersBillingDetailsApi } from '@project/services';

export const PartnersBillingDetailsModel = (
  data?: PartnersBillingDetailsApi,
): PartnersBillingDetailsModelType => ({
  firstName: data?.first_name ?? '',
  lastName: data?.last_name ?? '',
  email: data?.email ?? '',
  mobile: data?.mobile ?? '',
  countryCode: data?.country_code ?? '',
  region: data?.region ?? '',
  city: data?.city ?? '',
  province: data?.province ?? '',
  street1: data?.street1 ?? '',
  street2: data?.street2 ?? '',
  zip: data?.zip ?? '',
});
