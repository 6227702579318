import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TopImage } from '@project/components';
import { Button } from '@project/components';

export const ThankYouPage: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const indemnityFormId = queryParams.get('indemnity_form_id');
  const eventType = queryParams.get('event_type');

  const goBack = () => {
    if (eventType === 'other') {
      navigate(`/indemnity-page/${indemnityFormId}`);
    } else {
      navigate('/');
    }
  };

  return (
    <div>
      <TopImage />
      <div className="w-full min-w-fit px-3 py-10 lg:pl-36 lg:pr-[564px]">
        <div>
          <div className="mb-10 flex w-full flex-row justify-between">
            <div>
              <h1 className="text-3xl font-semibold">Payment Complete</h1>
              <div className="w-1/3 rounded border-2 border-gold" />
            </div>
          </div>
          <p className="mb-2 text-gold">Thank You For Completing Your Payment!</p>
          {eventType === 'other' && (
            <p className="my-5 leading-loose text-black">
              To Complete The Process Please Click Continue To Fill Out The Indemnity Form.
            </p>
          )}
          <Button
            style={`bg-${'gold'} text-white py-2.5 justify-end p-14 rounded-md mb-20 mt-10`}
            onClick={goBack}
          >
            {eventType === 'other' ? 'CONTINUE' : 'CONTINUE BROWSING'}
          </Button>
        </div>
      </div>
    </div>
  );
};
