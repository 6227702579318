import z from 'zod';
import includes from 'lodash/includes';

const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
];

export const passwordSchema = z
  .string()
  .min(8, 'Password must contain at least 8 characters')
  .regex(/^(?=.*[a-z])/, 'Must have 1 lowercase letter')
  .regex(/^(?=.*[A-Z])/, 'Must have 1 uppercase letter')
  .regex(/^(?=.*\d)/, 'Must have 1 digit')
  .regex(/^(?=.*[^a-zA-Z\d])/, 'Must have 1 symbol');

export const commonSchemas = {
  username: z.string().email('Must be a valid email'),
  password: passwordSchema,
  mobile: z.union([z.string().min(12).startsWith('+27'), z.string().min(10).startsWith('0')]),
  passport: z.string().regex(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/, 'Not a valid passport number'),
  file: z
    .any()
    .refine((file) => file !== null, 'Image is required.')
    .refine(
      (file) => includes(ACCEPTED_FILE_TYPES, file?.type),
      '.jpg, .jpeg, .png and .webp files are accepted.',
    ),
};

export const paginationSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalCount: z.number(),
  nextPage: z.number(),
  prevPage: z.number(),
});

export const paginationApiSchema = z.object({
  current_page: z.number(),
  total_pages: z.number(),
  total_count: z.number(),
  next_page: z.number().or(z.null()),
  prev_page: z.number().or(z.null()),
});
