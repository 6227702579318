import opencage from 'opencage-api-client';
import { GeocodingModel } from './geocoding.model';
import { GeocodingDataType, posisionOptions } from './types';

const OPENCAGE_API_KEY = 'f3e2ed780e554f16a1ac5bcb003bf6fa';

const getCurrentUserGeoPosition = async (): Promise<GeolocationPosition | null> => {
  if (!navigator.geolocation) return null;

  const permisionResult = await navigator.permissions.query({ name: 'geolocation' });
  // only get user position when permitted
  if (!['granted', 'prompt'].includes(permisionResult.state)) return null;

  return new Promise<GeolocationPosition | null>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => resolve(position),
      (error: GeolocationPositionError) => reject(error),
      posisionOptions,
    );
  }).catch((err) => {
    console.error('GeolocationError', err);
    return null;
  });
};

const getUserGeocodingData = async (): Promise<GeocodingDataType | null> => {
  try {
    const position: GeolocationPosition | null = await getCurrentUserGeoPosition();
    if (!position) return null;

    const { status, results } = await opencage.geocode({
      key: OPENCAGE_API_KEY,
      q: `${position.coords.latitude}, ${position.coords.longitude}`,
      language: 'en',
    });

    if (status.code !== 200 && !results.length) return null;

    return GeocodingModel(results[0]);
  } catch (err) {
    console.error('GeolocationError', err);
    return null;
  }
};

export default { getUserGeocodingData };
