import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlatList } from '@codehesion-za/headless';

import { PartnersMeetingCard, LoadingContainer, SearchField } from '@project/components';
import { useGetPartnerEventsQuery } from '@project/queries';
import { EventsItemType } from '@project/services';

const RenderItem = ({ item, index }: { item: EventsItemType; index: number }) => {
  const navigate = useNavigate();

  const navFunction = () =>
    navigate(
      `/partner/meetings/${item.id}?indemnity_form_id=${item.indemnityFormId}&event_type=${item.eventType}`,
    );

  return (
    <PartnersMeetingCard
      key={`${item.id}-${index}`}
      id={item?.id}
      name={item?.name}
      date={item?.startAt}
      type={item?.eventType}
      bg={item?.hasRegistered ? 'gold' : 'black'}
      startTime={item?.startAt}
      endTime={item?.endAt ?? ''}
      hasRegistered={item?.hasRegistered}
      navFunction={navFunction}
    />
  );
};

const EmptyList = <span>No upcoming events</span>;

export const PartnersMeetingsTab: React.FC = () => {
  const [search, setSearch] = useState('');

  const { data: getPartnersEvents, isLoading } = useGetPartnerEventsQuery(search);

  return (
    <div className="grid h-screen lg:grid-cols-6">
      <div className="col-span-2 p-6 xl:p-14">
        <p>All Events</p>
        <div className="w-16 rounded border-b-2 border-gold" />
        <p className="mt-2 text-sm text-gray-400">Register for our events now!</p>
        <SearchField
          className="mt-4 rounded-full border-[#D3D3D3] bg-[#EAEAEA] xl:w-[400px]"
          placeholder="Search"
          onChange={setSearch}
        />
      </div>
      <LoadingContainer isLoading={isLoading}>
        <div className="col-span-4 bg-[#F5F5F5] p-6 md:p-14">
          <FlatList
            items={getPartnersEvents?.events ?? []}
            renderItem={RenderItem}
            ListEmptyComponent={EmptyList}
          />
        </div>
      </LoadingContainer>
    </div>
  );
};
