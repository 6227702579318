import React, { FormEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@codehesion-za/headless';
import { FormikProps } from 'formik';

import { Button, RadioGroup, PaymentAmountField, ActivityLoader } from '@project/components';
import { cardholderSchema } from './schema';
import { DonationValues } from './types';
import { useGetExchangeCurrencyQuery } from '@project/queries';

export const CardholderForm: React.FC = () => {
  const navigate = useNavigate();
  const getMinAmount = (cardType: string) => {
    switch (cardType) {
      case 'Platinum':
        return 2700;
      case 'Gold':
        return 1000;
      case 'Silver':
        return 500;
      case 'Bronze':
        return 270;
      default:
        return 270;
    }
  };

  const onSubmitForm = (formData: DonationValues) => {
    navigate(
      `/payment?type=${formData.donationType} Card Holder&amount=${formData.donationAmount}&currency=${formData.donationCurrency}&payment_type=card_holder`,
    );
    return new Promise(() => null);
  };

  const FormComponents = ({
    isSubmitting,
    handleSubmit,
    values,
    isValid,
    setFieldError,
  }: FormikProps<DonationValues>) => {
    const { data, isLoading } = useGetExchangeCurrencyQuery(
      getMinAmount(values.donationType ?? 'Bronze'),
      'usd',
      values.donationCurrency ?? 'usd',
    );

    const isDonationAmountInvalid = useCallback(
      () => Number(values.donationAmount) < (data?.convertedAmount ?? 200),
      [values.donationAmount, data?.convertedAmount, isLoading],
    );

    const _handleSubmit = useCallback(
      (formData: FormEvent<HTMLFormElement>) => {
        if (isDonationAmountInvalid()) {
          setFieldError('donationAmount', 'Must be greater than or equal to minimum amount.');
          return;
        }

        handleSubmit(formData);
      },
      [data, values],
    );

    return (
      <div className="form-styling justify-center">
        <h1 className="mt-5 mb-4 ml-5 text-base">Partnership Category</h1>
        <div className="mt-5 ml-5 flex flex-col text-sm">
          <RadioGroup
            name="donationType"
            options={[]}
            id="platinum"
            label="Platinum $2700/R50000"
            value="Platinum"
          />
          <RadioGroup
            name="donationType"
            options={[]}
            id="gold"
            label="Gold $1000/R20000"
            value="Gold"
          />
          <RadioGroup
            name="donationType"
            options={[]}
            id="silver"
            label="Silver $500/R10000"
            value="Silver"
          />
          <RadioGroup
            name="donationType"
            options={[]}
            id="bronze"
            label="Bronze $270/R5000"
            value="Bronze"
          />
        </div>

        <div className="w-full">
          <PaymentAmountField
            amountFieldName="donationAmount"
            currencyFieldName="donationCurrency"
            selectedCurrency={values.donationCurrency}
          />
          {!isLoading && isDonationAmountInvalid() && (
            <p className="mt-2 text-right text-red-600">
              Minimum donation amount of {values?.donationCurrency}
              {` ${data?.convertedAmount}`} is Required
            </p>
          )}
          {isLoading && <ActivityLoader isLoading={isLoading} />}
        </div>
        <Button
          isDisabled={isSubmitting || !isValid}
          style="bg-gold text-white font-bold text-sm rounded-md py-3 w-full"
          isLoading={isSubmitting}
          onClick={_handleSubmit}
        >
          Next
        </Button>
      </div>
    );
  };

  return (
    <Form
      initialValues={{
        donationAmount: 1,
        donationType: 'Bronze',
        donationCurrency: 'USD',
      }}
      onSubmitForm={onSubmitForm}
      render={FormComponents}
      validationSchema={cardholderSchema}
      validateOnMount
      validateOnBlur
      validateOnChange
    />
  );
};
