import { getData } from '@project/services/utils';
import meetTheTeamUrls from './meet-the-team.urls';
import { authNetworkService } from '@project/services';

const getAllTeamMembers = () => {
  const url = meetTheTeamUrls.getMeetTheTeamUrl();

  return authNetworkService.get(url).then(getData);
};

const getSingleTeamMember = (id: string) => {
  const url = meetTheTeamUrls.singleTeamMemberUrl(id);

  return authNetworkService.get(url).then(getData);
};

export default { getAllTeamMembers, getSingleTeamMember };
