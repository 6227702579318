// General
export const FAILED_TO_PROCESS_MSG =
  'Something went wrong, please try again or contact us for help';

// Toast
export const TOAST_DURATION = { duration: 3000 };

// Payments
export const PAYMENT_PROCESSING_ERROR = 'Failed to process payment, please try again';
export const PAYMENT_INVALID_AMOUNT = 'Please enter a valid amount';
export const PAYMENT_INTENT_SUCCESS = 'succeeded';
export const REFERENCE_NUMBER_SENT = 'Reference number sent via email';
export const FAILED_TO_LOAD_PAYMENT_ELEMENT = 'Failed to load payment element';
export const INCORRECT_REFERENCE =
  'Couldn\'t find ManualPayment with [WHERE "manual_payments"."reference_number"';

// Inputs
export const FILL_REQUIRED_INPUTS = 'Please fill in all the required fields';
export const EMAIL_ALREADY_EXIST = 'This email is already associated with a partner account';

// Registration
export const REGISTRATION_SUCCESS = 'Registered successfully, please login';
export const REGISTRATION_FAILED = 'Registration failed, please try again';
